import React from 'react'

type Props = {
  loaded: boolean
}

const loaderContainer: JSX.Element = (
  <div className="loading-spinner" style={{ position: 'relative' }}>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
)

class Loader extends React.Component<Props> {
  constructor(props: Props) {
    super(props)
  }
  
  render() {
    const { loaded } = this.props;

    return (
      loaded ?
        this.props.children || <></> :
        loaderContainer
    );
  }
}
export default Loader;