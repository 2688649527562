import React, { PureComponent } from 'react';
import { Store } from 'redux';
import { connect } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { Event, Setting, ObjectClass, CustomField } from 'core';
import { LoggedUser, CustomFields } from './entities';
import * as cache from './cache';
import * as api from './api';
import
  LogIn,
  {
    Action as LogInAction,
    ActionTypes as LogInActionTypes
  }
from './components/LogIn';
import {
  Action as LogOutAction,
  ActionTypes as LogOutActionTypes
} from './components/LogOut';
import Menu from './components/Menu';
import {
  Action as LoggedUserAction,
  ActionTypes as LoggedUserActionTypes
} from './components/LoggedUser';
import withAnalytics, { AnalyticsProps } from './hoc/withAnalytics';
import { notify, NotificationColorTheme } from './components/Notification';
import CustomProperties from 'react-custom-properties';

// Notification constants
const INTERVAL_BETWEEN_ERROR_NOTIFICATIONS_MILLISECONDS: number = 60000;
const NETWORK_ERROR_MESSAGE: string = 'Network error when loading data. Trying again in 5 sec. The displayed data comes from cache and may not be the latest.';

type State = {
  settings: {
    [key: string]: string
  },
  loggedUser?: LoggedUser,
  objectClasses: ObjectClass[],
  customFields: CustomFields,
  lastNetworkErrorTimestamp: number
};

type Events = {
  onComponentDidMount: () => void,
  onComponentWillUnmount: () => void
};

type Props = State & Events & AnalyticsProps;

/**
 * The theme settings that contains the configurations that
 * defines the theme on the application.
 */
type B4aThemeSettingProperties = {
  brandColor: string
};

/**
 * All variables related to the style should be defined here,
 * and used on the SCSS whenever possible.
 *
 * i.e.: color: var(--b4a-brand-color);
 *
 * PS: yep, this works on CSS :)
 */
type B4aThemeProperties = {
  '--b4a-brand-color': string
}

/**
 * This is the default theme for the Admin App.
 */
const DEFAULT_B4A_THEME: B4aThemeSettingProperties = {
  brandColor: '#208aec'
};

class View extends PureComponent<Props> {
  componentDidMount(): void {
    this.props.onComponentDidMount();
  }

  componentDidUpdate(prevProps: State): void {
    const { loggedUser } = prevProps;
    const { loggedUser: updatedUser } = this.props;
    if (!loggedUser && updatedUser) {
      this.props.trackLogin(updatedUser.username);
    }
  }

  componentWillUnmount(): void {
    this.props.onComponentWillUnmount();
  }

  getTheme(): B4aThemeProperties {
    const {
      brandColor = DEFAULT_B4A_THEME.brandColor
    } = this.props.settings;
    return {
      '--b4a-brand-color': brandColor
    };
  }

  render(): JSX.Element {
    return (
      <CustomProperties global={true} properties={this.getTheme()}>
        <Router>
          {this.props.loggedUser
            ? <Menu />
            : <LogIn />
          }
        </Router>
      </CustomProperties>
    );
  }
}

export type RootState = { app: State };

function mapState(rootState: RootState): State {
  return rootState.app;
}

export enum ActionTypes {
  SubscribeSettings = 'APP.SUBSCRIBE_SETTINGS',
  ChangeSettings = 'LOGGED_USER.CHANGE_SETTINGS',
  ChangeSetting = 'LOGGED_USER.CHANGE_SETTING',
  FailSettings = 'LOGGED_USER.FAIL_SETTINGS',
  UnsubscribeSettings = 'LOGGED_USER.UNSUBSCRIBE_SETTINGS',
  LoadObjectClasses = 'APP.LOAD_OBJECT_CLASSES',
  ChangeObjectClasses = 'LOGGED_USER.CHANGE_OBJECT_CLASSES',
  FailObjectClasses = 'LOGGED_USER.FAIL_OBJECT_CLASSES',
  SubscribeCustomFields = 'APP.SUBSCRIBE_CUSTOMFIELDS',
  ChangeCustomFields = 'LOGGED_USER.CHANGE_CUSTOMFIELDS',
  ChangeCustomField = 'LOGGED_USER.CHANGE_CUSTOMFIELD',
  FailCustomFields = 'LOGGED_USER.FAIL_CUSTOMFIELDS',
  UnsubscribeCustomFields = 'LOGGED_USER.UNSUBSCRIBE_CUSTOMFIELDS',
  ShowNetworkErrorNotification = 'APP.SHOW_NETWORK_ERROR_NOTIFICATION'
};

type SubscribeSettingsAction = {
  type: ActionTypes.SubscribeSettings
};

type ChangeSettingsAction = {
  type: ActionTypes.ChangeSettings,
  payload: {
    settings: Setting[]
  }
};

type ChangeSettingAction = {
  type: ActionTypes.ChangeSetting,
  payload: {
    setting: Setting,
    event: Event
  }
};

type FailSettingsAction = {
  type: ActionTypes.FailSettings,
  payload: {
    errorMessage: string
  }
}

type UnsubscribeSettingsAction = {
  type: ActionTypes.UnsubscribeSettings
};

type LoadObjectClassesAction = {
  type: ActionTypes.LoadObjectClasses
};

type ChangeObjectClassesAction = {
  type: ActionTypes.ChangeObjectClasses,
  payload: {
    objectClasses: ObjectClass[]
  }
};

type FailObjectClassesAction = {
  type: ActionTypes.FailObjectClasses,
  payload: {
    errorMessage: string
  }
}

type SubscribeCustomFieldsAction = {
  type: ActionTypes.SubscribeCustomFields
};

type ChangeCustomFieldsAction = {
  type: ActionTypes.ChangeCustomFields,
  payload: {
    customFields: CustomField[]
  }
};

type ChangeCustomFieldAction = {
  type: ActionTypes.ChangeCustomField,
  payload: {
    customField: CustomField,
    event: Event
  }
};

type FailCustomFieldsAction = {
  type: ActionTypes.FailCustomFields,
  payload: {
    errorMessage: string
  }
}

type UnsubscribeCustomFieldsAction = {
  type: ActionTypes.UnsubscribeCustomFields
};

type ShowNetworkErrorNotificationAction = {
  type: ActionTypes.ShowNetworkErrorNotification
};

export type Action =
  SubscribeSettingsAction |
  ChangeSettingsAction |
  ChangeSettingAction |
  FailSettingsAction |
  UnsubscribeSettingsAction |
  LoadObjectClassesAction |
  ChangeObjectClassesAction |
  FailObjectClassesAction|
  SubscribeCustomFieldsAction |
  ChangeCustomFieldsAction |
  ChangeCustomFieldAction |
  FailCustomFieldsAction |
  UnsubscribeCustomFieldsAction |
  ShowNetworkErrorNotificationAction;

export type Dispatch = any;

type GetState = () => RootState;

type Thunk = (dispatch: Dispatch, getState: GetState) => Promise<Action>

let cancelSettingsAPISubscription: (() => void) | undefined = undefined;

function subscribeSettings(): Thunk {
  return async (dispatch: Dispatch): Promise<Action> => {
    dispatch({
      type: ActionTypes.SubscribeSettings
    });

    let settings: Setting[];
    try {
      settings = await api.settings();
    } catch (e) {
      if (e instanceof api.NetworkError) {
        console.error('Network error when subscribing to Settings. Trying again in 5 sec...');
        dispatch(ShowNetworkErrorNotification());
        await (new Promise((resolve: () => void): void => {
          setTimeout(() => {
            resolve();
          }, 5000);
        }));
        return dispatch(subscribeSettings());
      }

      return dispatch(failSettings(e.message));
    }

    if (cancelSettingsAPISubscription) {
      cancelSettingsAPISubscription();
    }

    cancelSettingsAPISubscription = api.settingChanged(
      (settingChanged: { setting: Setting, event: Event }): void => {
        dispatch(changeSetting(settingChanged.setting, settingChanged.event));
      },
      (error: Error): void => {
        dispatch(failSettings(error.message));
      },
      (): void => {
        dispatch(subscribeSettings());
      }
    );

    return dispatch(changeSettings(settings));
  };
}

function changeSettings(settings: Setting[]): ChangeSettingsAction {
  return {
    type: ActionTypes.ChangeSettings,
    payload: {
      settings
    }
  };
}

function changeSetting(setting: Setting, event: Event ): ChangeSettingAction {
  return {
    type: ActionTypes.ChangeSetting,
    payload: {
      setting,
      event
    }
  };
}

function failSettings(errorMessage: string): FailSettingsAction {
  return {
    type: ActionTypes.FailSettings,
    payload: {
      errorMessage
    }
  };
}

function unsubscribeSettings(): UnsubscribeSettingsAction {
  if (cancelSettingsAPISubscription) {
    cancelSettingsAPISubscription();
    cancelSettingsAPISubscription = undefined;
  }
  return {
    type: ActionTypes.UnsubscribeSettings
  };
}

function loadObjectClasses(): Thunk {
  return async (dispatch: Dispatch, getState: GetState): Promise<Action> => {
    dispatch({
      type: ActionTypes.LoadObjectClasses
    });

    const state: State = mapState(getState());

    let objectClasses: ObjectClass[] = [];

    if (state.loggedUser) {
      try {
        objectClasses = await api.objectClasses(state.loggedUser.sessionToken);
      } catch (e) {
        if (e instanceof api.NetworkError) {
          console.error('Network error when loading Object Classes. Trying again in 5 sec...');
          dispatch(ShowNetworkErrorNotification());
          await (new Promise((resolve: () => void): void => {
            setTimeout(() => {
              resolve();
            }, 5000);
          }));
          return dispatch(loadObjectClasses());
        }

        return dispatch(failObjectClasses(e.message));
      }
    }

    return dispatch(changeObjectClasses(objectClasses));
  };
}

function changeObjectClasses(objectClasses: ObjectClass[]): ChangeObjectClassesAction {
  return {
    type: ActionTypes.ChangeObjectClasses,
    payload: {
      objectClasses
    }
  };
}

function failObjectClasses(errorMessage: string): FailObjectClassesAction {
  return {
    type: ActionTypes.FailObjectClasses,
    payload: {
      errorMessage
    }
  };
}

let cancelCustomFieldsAPISubscription: (() => void) | undefined = undefined;

function subscribeCustomFields(): Thunk {
  return async (dispatch: Dispatch, getState: GetState): Promise<Action> => {
    dispatch({
      type: ActionTypes.SubscribeCustomFields
    });

    const state: State = mapState(getState());

    let customFields: CustomField[] = [];

    if (state.loggedUser) {
      try {
        customFields = await api.customFields(state.loggedUser.sessionToken);
      } catch (e) {
        if (e instanceof api.NetworkError) {
          console.error('Network error when subscribing to Custom Fields. Trying again in 5 sec...');
          dispatch(ShowNetworkErrorNotification());
          await (new Promise((resolve: () => void): void => {
            setTimeout(() => {
              resolve();
            }, 5000);
          }));
          return dispatch(subscribeCustomFields());
        }

        return dispatch(failCustomFields(e.message));
      }

      if (cancelCustomFieldsAPISubscription) {
        cancelCustomFieldsAPISubscription();
      }

      cancelCustomFieldsAPISubscription = api.customFieldChanged(
        state.loggedUser.sessionToken,
        (customFieldChanged: { customField: CustomField, event: Event }): void => {
          dispatch(changeCustomField(customFieldChanged.customField, customFieldChanged.event));
        },
        (error: Error): void => {
          dispatch(failCustomFields(error.message));
        },
        (): void => {
          dispatch(subscribeCustomFields());
        }
      );
    }

    return dispatch(changeCustomFields(customFields));
  };
}

function changeCustomFields(customFields: CustomField[]): ChangeCustomFieldsAction {
  return {
    type: ActionTypes.ChangeCustomFields,
    payload: {
      customFields
    }
  };
}

function changeCustomField(customField: CustomField, event: Event ): ChangeCustomFieldAction {
  return {
    type: ActionTypes.ChangeCustomField,
    payload: {
      customField,
      event
    }
  };
}

function failCustomFields(errorMessage: string): FailCustomFieldsAction {
  return {
    type: ActionTypes.FailCustomFields,
    payload: {
      errorMessage
    }
  };
}

function unsubscribeCustomFields(): UnsubscribeCustomFieldsAction {
  if (cancelCustomFieldsAPISubscription) {
    cancelCustomFieldsAPISubscription();
    cancelCustomFieldsAPISubscription = undefined;
  }
  return {
    type: ActionTypes.UnsubscribeCustomFields
  };
}

export function ShowNetworkErrorNotification(): ShowNetworkErrorNotificationAction {
  return {
    type: ActionTypes.ShowNetworkErrorNotification
  }
}

function mapEvents(dispatch: Dispatch): Events {
  return {
    onComponentDidMount(): void {
      dispatch(subscribeSettings());
      dispatch(loadObjectClasses());
      dispatch(subscribeCustomFields());
    },
    onComponentWillUnmount(): void {
      dispatch(unsubscribeSettings());
      dispatch(unsubscribeCustomFields());
    }
  };
}

export default withAnalytics(connect(mapState, mapEvents)(View));

let cachedSettings: { [key: string]: string } | undefined = cache.getSettings();
let cachedLoggedUser: LoggedUser | undefined = cache.getLoggedUser();
let cachedObjectClasses: ObjectClass[] | undefined = cache.getObjectClasses();
let cachedCustomFields: CustomFields | undefined = cache.getCustomFields();

function mapSettingsArray(settingsArray: Setting[]): { [key: string]: string } {
  const settings: { [key: string]: string } = {};
  settingsArray.forEach((setting: Setting): void => {
    settings[setting.key] = setting.value;
  });
  return settings;
}

function mapCustomFieldsArray(customFieldsArray: CustomField[]): CustomFields {
  const customFields: CustomFields = { byObjectClassName: {}, byId: {} };
  customFieldsArray.forEach((customField: CustomField): void => {
    if (!customFields.byObjectClassName[customField.objectClassName]) {
      customFields.byObjectClassName[customField.objectClassName] = {};
    }
    customFields.byObjectClassName[customField.objectClassName][customField.objectClassFieldName] = customField;
    customFields.byId[customField.id] = customField;
  });
  return customFields;
}

export function reducer(
  state: State = {
    settings: cachedSettings || {
      appName: 'Admin App',
      brandColor: DEFAULT_B4A_THEME.brandColor
    },
    loggedUser: cachedLoggedUser,
    objectClasses: cachedObjectClasses || [],
    customFields: cachedCustomFields || { byObjectClassName: {}, byId: {} },
    lastNetworkErrorTimestamp: 0
  },
  action: Action | LogInAction | LogOutAction | LoggedUserAction
): State {
  switch (action.type) {
    case ActionTypes.ChangeSettings:
      return Object.assign(
        {},
        state,
        {
          settings: mapSettingsArray(action.payload.settings)
        }
      );

    case ActionTypes.ChangeSetting:
      switch (action.payload.event) {
        case Event.Created:
        case Event.Updated:
          return Object.assign(
            {},
            state,
            {
              settings: Object.assign(
                {},
                state.settings,
                {
                  [action.payload.setting.key]: action.payload.setting.value
                }
              )
            }
          );

        case Event.Deleted:
          return Object.assign(
            {},
            state,
            {
              settings: Object.assign(
                {},
                state.settings,
                {
                  [action.payload.setting.key]: undefined
                }
              )
            }
          );
      }
      break;

    case LogInActionTypes.Confirm:
      return Object.assign(
        {},
        state,
        {
          loggedUser: action.payload.loggedUser
        }
      );

    case LogOutActionTypes.Confirm:
    case LogOutActionTypes.Fail:
      return Object.assign(
        {},
        state,
        {
          loggedUser: undefined,
          objectClasses: [],
          customFields: { byObjectClassName: {}, byId: {} }
        }
      );

    case LoggedUserActionTypes.Change:
      return Object.assign(
        {},
        state,
        {
          loggedUser: {
            sessionToken: (state.loggedUser as LoggedUser).sessionToken,
            username: action.payload.username
          }
        }
      );

    case LoggedUserActionTypes.Fail:
      return Object.assign(
        {},
        state,
        {
          loggedUser: undefined,
          objectClasses: [],
          customFields: { byObjectClassName: {}, byId: {} }
        }
      );

    case ActionTypes.ChangeObjectClasses:
      return Object.assign(
        {},
        state,
        {
          objectClasses: action.payload.objectClasses
        }
      );

    case ActionTypes.ChangeCustomFields:
      return Object.assign(
        {},
        state,
        {
          customFields: mapCustomFieldsArray(action.payload.customFields)
        }
      );

    case ActionTypes.ChangeCustomField:
      switch (action.payload.event) {
        case Event.Created:
          return Object.assign(
            {},
            state,
            {
              customFields: Object.assign(
                {},
                state.customFields,
                {
                  byObjectClassName: Object.assign(
                    {},
                    state.customFields.byObjectClassName,
                    {
                      [action.payload.customField.objectClassName]: Object.assign(
                        {},
                        state.customFields.byObjectClassName[action.payload.customField.objectClassName] || {},
                        {
                          [action.payload.customField.objectClassFieldName]: action.payload.customField
                        }
                      )
                    }
                  ),
                  byId: Object.assign(
                    {},
                    state.customFields.byId,
                    {
                      [action.payload.customField.id]: action.payload.customField
                    }
                  )
                }
              )
            }
          );

        case Event.Updated:
          const existentCustomField: CustomField | undefined = state.customFields.byId[action.payload.customField.id];
          if (!existentCustomField) {
            return Object.assign(
              {},
              state,
              {
                customFields: Object.assign(
                  {},
                  state.customFields,
                  {
                    byObjectClassName: Object.assign(
                      {},
                      state.customFields.byObjectClassName,
                      {
                        [action.payload.customField.objectClassName]: Object.assign(
                          {},
                          state.customFields.byObjectClassName[action.payload.customField.objectClassName] || {},
                          {
                            [action.payload.customField.objectClassFieldName]: action.payload.customField
                          }
                        )
                      }
                    ),
                    byId: Object.assign(
                      {},
                      state.customFields.byId,
                      {
                        [action.payload.customField.id]: action.payload.customField
                      }
                    )
                  }
                )
              }
            );
          } else {
            return Object.assign(
              {},
              state,
              {
                customFields: Object.assign(
                  {},
                  state.customFields,
                  {
                    byObjectClassName: Object.assign(
                      {},
                      state.customFields.byObjectClassName,
                      existentCustomField.objectClassName === action.payload.customField.objectClassName ?
                        {
                          [existentCustomField.objectClassName]: Object.assign(
                            {},
                            state.customFields.byObjectClassName[existentCustomField.objectClassName] || {},
                            existentCustomField.objectClassFieldName === action.payload.customField.objectClassFieldName ?
                              {
                                [existentCustomField.objectClassFieldName]: action.payload.customField
                              }
                            :
                              {
                                [existentCustomField.objectClassFieldName]: undefined,
                                [action.payload.customField.objectClassFieldName]: action.payload.customField
                              }
                          )
                        }
                      :
                        {
                          [existentCustomField.objectClassName]: Object.assign(
                            {},
                            state.customFields.byObjectClassName[existentCustomField.objectClassName] || {},
                            {
                              [existentCustomField.objectClassFieldName]: undefined
                            }
                          ),
                          [action.payload.customField.objectClassName]: Object.assign(
                            {},
                            state.customFields.byObjectClassName[action.payload.customField.objectClassName] || {},
                            {
                              [action.payload.customField.objectClassFieldName]: action.payload.customField
                            }
                          )
                        }
                    ),
                    byId: Object.assign(
                      {},
                      state.customFields.byId,
                      {
                        [action.payload.customField.id]: action.payload.customField
                      }
                    )
                  }
                )
              }
            );
          }

        case Event.Deleted:
          return Object.assign(
            {},
            state,
            {
              customFields: Object.assign(
                {},
                state.customFields,
                {
                  byObjectClassName: Object.assign(
                    {},
                    state.customFields.byObjectClassName,
                    {
                      [action.payload.customField.objectClassName]: Object.assign(
                        {},
                        state.customFields.byObjectClassName[action.payload.customField.objectClassName] || {},
                        {
                          [action.payload.customField.objectClassFieldName]: undefined
                        }
                      )
                    }
                  ),
                  byId: Object.assign(
                    {},
                    state.customFields.byId,
                    {
                      [action.payload.customField.id]: undefined
                    }
                  )
                }
              )
            }
          );
      }
      break;

      case ActionTypes.ShowNetworkErrorNotification:
        const message = NETWORK_ERROR_MESSAGE;
        const currentTimestamp = new Date().getTime();
        let lastNetworkErrorTimestamp;

        if (state.lastNetworkErrorTimestamp < currentTimestamp) {
          lastNetworkErrorTimestamp = currentTimestamp + INTERVAL_BETWEEN_ERROR_NOTIFICATIONS_MILLISECONDS;
          notify('fa-exclamation-circle', message, 7000, NotificationColorTheme.DANGER);
        } else {
          lastNetworkErrorTimestamp = state.lastNetworkErrorTimestamp;
        }

        return Object.assign(
          {},
          state,
          {
            lastNetworkErrorTimestamp
          }
        );
  }

  return state;
};

export function subscriber(store: Store): void {
  const state: RootState = store.getState() as RootState;
  const settings: { [key: string]: string } = state.app.settings;
  const loggedUser: LoggedUser | undefined = state.app.loggedUser;
  const objectClasses: ObjectClass[] = state.app.objectClasses;
  let dispatchLoadObjectClasses: boolean = false;
  const customFields: CustomFields = state.app.customFields;
  let dispatchSubscribeCustomFields: boolean = false;
  let dispatchUnsubscribeCustomFields: boolean = false;
  if (settings !== cachedSettings) {
    cache.setSettings(settings);
    cachedSettings = settings;
  }
  if (loggedUser !== cachedLoggedUser) {
    if (loggedUser) {
      cache.setLoggedUser(loggedUser);
      dispatchLoadObjectClasses = !cachedLoggedUser;
      dispatchSubscribeCustomFields = !cachedLoggedUser;
    } else {
      cache.clearLoggedUser();
      dispatchUnsubscribeCustomFields = !!cachedLoggedUser;
    }
    cachedLoggedUser = loggedUser;
  }
  if (objectClasses !== cachedObjectClasses) {
    cache.setObjectClasses(objectClasses);
    cachedObjectClasses = objectClasses;
  }
  if (dispatchLoadObjectClasses) {
    (store.dispatch as Dispatch)(loadObjectClasses());
  }
  if (customFields !== cachedCustomFields) {
    cache.setCustomFields(customFields);
    cachedCustomFields = customFields;
  }
  if (dispatchSubscribeCustomFields) {
    (store.dispatch as Dispatch)(subscribeCustomFields());
  }
  if (dispatchUnsubscribeCustomFields) {
    (store.dispatch as Dispatch)(unsubscribeCustomFields());
  }
}
