import React from 'react';
import { CustomField, FieldType, ObjectClassField } from 'core';
import { Fields, ObjectFilter, FilterOperator, ObjectFilterItem } from '../entities';
import Icon from './Icon';
import BooleanInput from './BooleanInput';
import SelectInput from './SelectInput';
import DateInput from './DateInput';
import ObjectsModal from './ObjectsModal';
import * as ObjectFilterUtils from '../utils/objectFilterUtils';
import TextInput from './TextInput';
import MultipleSelectInput from './MultipleSelectInput';

const OPERATORS: { [value: string]: string } = {
  EXISTS: 'exists',
  DOES_NOT_EXIST: 'does not exist',
  EQUALS_TO: 'equals to',
  DOES_NOT_EQUAL_TO: 'does not equal to',
  CONTAINED_IN: 'contained in',
  NOT_CONTAINED_IN: 'not contained in',
  STARTS_WITH: 'starts with',
  ENDS_WITH: 'ends with',
  CONTAINS: 'contains',
  CONTAINS_ALL: 'contains all',
  GREATER_THAN: 'greater than',
  GREATER_THAN_OR_EQUAL_TO: 'greater than or equal to',
  LESS_THAN: 'less than',
  LESS_THAN_OR_EQUAL_TO: 'less than or equal to'
};

type State = {
  fields: Fields,
  dynamicFieldNames: string[],
  objectFilter: ObjectFilter,
  keepFiltered: boolean,
  isModalVisible: boolean,
  customizeDefaultFields: boolean
};

type Events = {
  onFilterButtonClick: () => void,
  onCloseButtonClick: () => void,
  onChange: (objectFilter: ObjectFilter, keepFiltered: boolean) => void
}

type Props = State & Events;

export default function View({ fields, dynamicFieldNames, objectFilter, keepFiltered, isModalVisible, onFilterButtonClick, onCloseButtonClick, onChange, customizeDefaultFields }: Props): JSX.Element {
  function addFilterItem(fieldName: string): void {
    const newObjectFilter: ObjectFilter = objectFilter.concat({
      fieldName,
      operator: FilterOperator.Exists,
      values: []
    });
    const fieldType: FieldType = ObjectFilterUtils.getFieldType(fieldName, fields);
    newObjectFilter[newObjectFilter.length - 1].operator = ObjectFilterUtils.getAvailableOperators(
      newObjectFilter.length - 1,
      fieldName,
      fieldType,
      objectFilter
    )[0];
    newObjectFilter[newObjectFilter.length - 1].values = ObjectFilterUtils.getDefaultOperatorValues(newObjectFilter[newObjectFilter.length - 1].operator, fieldType);
    onChange(
      newObjectFilter,
      keepFiltered
    );
  }

  function removeFilterItem(index: number): void {
    const newObjectFilter: ObjectFilter = objectFilter.concat();
    newObjectFilter.splice(index, 1);
    onChange(newObjectFilter, keepFiltered);
  }

  function changeFilterItem(index: number, fieldName: string, operator: FilterOperator, values: any[]) {
    const newObjectFilter: ObjectFilter = objectFilter.concat();
    newObjectFilter[index] = {
      fieldName,
      operator,
      values
    };
    onChange(newObjectFilter, keepFiltered);
  }

  function clearAll(): void {
    onChange([], keepFiltered);
  }

  function changeKeepFiltered(keepFiltered: boolean): void {
    onChange(objectFilter, keepFiltered);
  }

  let availableFields = [...dynamicFieldNames];

  if (!customizeDefaultFields) {
    availableFields.unshift('id')
    availableFields.push('createdAt', 'updatedAt')
  }

  availableFields = availableFields.filter((fieldName: string): boolean => ObjectFilterUtils.getAvailableOperators(objectFilter.length, fieldName, ObjectFilterUtils.getFieldType(fieldName, fields), objectFilter).length > 0);

  return (
    <>
      <button className="btn btn-primary mr-2 filter-button" onClick={onFilterButtonClick}>
        <Icon icon="fa-filter" isMaterial={false}></Icon>
        <span className='d-none d-sm-inline'> Filter ({keepFiltered ? objectFilter.length : 0})</span>
      </button>
      {isModalVisible && <ObjectsModal title='Filter' className='objects-filter-modal' onCloseButtonClick={onCloseButtonClick} onExternalClick={onCloseButtonClick}>
        <div className="modal-body">
          {objectFilter.map((objectFilterItem: ObjectFilterItem, index: number): JSX.Element => {
            const { fieldName, operator } = objectFilterItem;
            const fieldType: FieldType = ObjectFilterUtils.getFieldType(fieldName, fields);
            const availableOperators: FilterOperator[] = ObjectFilterUtils.getAvailableOperators(index, fieldName, fieldType, objectFilter);
            const customField: CustomField | undefined = fields[fieldName] && fields[fieldName].customField;
            return (
              <React.Fragment key={index}>
                <div className="row mb-3">
                  <div className="col-1 d-flex">
                    <button type="button" className="btn btn-link text-secondary" aria-label="Remove" onClick={(): void => { removeFilterItem(index); }}>
                      <Icon icon='zmdi-close' />
                    </button>
                  </div>
                  <div className="col-11 col-sm-5 mb-3 mb-sm-0">
                    <div className="flex-grow-1">
                      <label htmlFor={`fieldName${index}`} className="sr-only">Field</label>

                      <SelectInput
                        value={fieldName}
                        onChange={(option: { value: string | number, label: any }): void => {
                          const value : string = option.value as string;
                          const fieldType: FieldType = ObjectFilterUtils.getFieldType(value, fields);
                          const defaultOperator: FilterOperator = ObjectFilterUtils.getAvailableOperators(index, value, fieldType, objectFilter)[0];
                          changeFilterItem(index, value, defaultOperator, ObjectFilterUtils.getDefaultOperatorValues(defaultOperator, fieldType));
                        }}
                        options={availableFields}
                        labels={ObjectFilterUtils.getFieldTitle}
                        fields={fields}
                      />
                    </div>
                  </div>
                  <div className="offset-1 col-11 offset-sm-0 col-sm-6 d-flex">
                    <div className="flex-grow-1">
                      <label htmlFor={`operator${index}`} className="sr-only">Operator</label>
                      <SelectInput
                        value={operator}
                        onChange={(option: { value: FilterOperator , label: string }): void => {
                          const filter: FilterOperator = option.value;
                          changeFilterItem(index, fieldName, filter as FilterOperator, ObjectFilterUtils.getDefaultOperatorValues(filter, fieldType));
                        }}
                        options={availableOperators}
                        labels={OPERATORS}
                      />
                    </div>
                  </div>
                </div>
                <div className="row mb-2">
                  {![FilterOperator.Exists, FilterOperator.DoesNotExist, FilterOperator.ContainedIn, FilterOperator.NotContainedIn].includes(operator) &&
                    <div className="col-11 offset-1 d-flex">
                      <div className={`flex-grow-1${[FilterOperator.ContainedIn, FilterOperator.NotContainedIn].includes(operator) ? ' input-group' : ''}`}>
                        {![FieldType.Bool, FieldType.Date].includes(fieldType) &&
                          <TextInput
                            type={fieldType === FieldType.Number ? 'number' : 'text'}
                            id={`value${index}`}
                            required
                            value={objectFilterItem.values.length ? (fieldType === FieldType.Number ? Number.parseFloat(objectFilterItem.values[0]) : objectFilterItem.values[0]) : (fieldType === FieldType.Number ? 0 : '')}
                            onKeyDown={(key: any): void => {
                              if ([FilterOperator.ContainedIn, FilterOperator.NotContainedIn].includes(operator) && key === 'Enter') {
                                changeFilterItem(index, fieldName, operator, [fieldType === FieldType.Number ? 0 : ''].concat(objectFilterItem.values));
                              } else if (fieldType === FieldType.Number && key === '-') {
                                changeFilterItem(index, fieldName, operator, [Number.parseFloat('-' + objectFilterItem.values[0])].concat(objectFilterItem.values.slice(1)));
                              }
                            }}
                            onChange={(value: any): void => {
                              changeFilterItem(index, fieldName, operator, [fieldType === FieldType.Number ? Number.parseFloat(value || 0) : value].concat(objectFilterItem.values.slice(1)));
                            }}
                          />
                        }
                        {fieldType === FieldType.Bool &&
                          <SelectInput
                            fieldTitle={ObjectFilterUtils.getFieldTitle(fieldName, fields)}
                            className='flex-grow-1'
                            options={[true, false]}
                            value={objectFilterItem.values.length ? objectFilterItem.values[0] : false}
                            onChange={(option: { value: boolean, label: string }): void => {
                              const { value } = option;
                              changeFilterItem(index, fieldName, operator, [value].concat(objectFilterItem.values.slice(1)));
                            }}
                            labels={{ true: 'True', false: 'False' }} />
                        }
                        {fieldType === FieldType.Date &&
                          <DateInput
                            fieldTitle={ObjectFilterUtils.getFieldTitle(fieldName, fields)}
                            isRequired={true}
                            value={objectFilterItem.values.length && objectFilterItem.values[0] ? new Date(objectFilterItem.values[0]) : new Date()}
                            onChange={(date: Date) => {
                              changeFilterItem(index, fieldName, operator, [date && !Number.isNaN(date.getTime()) ? date : new Date()].concat(objectFilterItem.values.slice(1)));
                            }}
                            mask={customField && customField.inputMask}
                          />
                        }
                      </div>
                    </div>
                  }
                  {[FilterOperator.ContainedIn, FilterOperator.NotContainedIn].includes(objectFilterItem.operator) &&
                    <div className="col-11 offset-1 d-flex">
                      <MultipleSelectInput
                        fieldTitle={ObjectFilterUtils.getFieldTitle(objectFilterItem.fieldName, fields)}
                        value={[... objectFilterItem.values]}
                        onChange={(values => {
                          changeFilterItem(index, objectFilterItem.fieldName, objectFilterItem.operator, values);
                        })}
                      />
                    </div>
                  }
                  {![FilterOperator.Exists, FilterOperator.DoesNotExist].includes(operator) &&
                    <div className="col-12 mb-3"></div>
                  }
                </div>
              </React.Fragment>
            );
          })}
          <div className="row justify-content-end mt-3">
            <SelectInput
              className='add-field-dropdown'
              placeholder='Add field to filter by'
              options={availableFields}
              onChange={(option: { value: string, label: string }): void => addFilterItem(option.value)}
              controlShouldRenderValue={false}
              renderAsButton={true}
              labels={(label: string) => ObjectFilterUtils.getFieldTitle(label, fields)} />
          </div>
        </div>
        <div className="modal-footer justify-content-start">
          <button type="button" className="btn btn-danger" onClick={clearAll}>
            <Icon className='mr-1' icon='zmdi-delete' />
            Clear All
          </button>
          <div className="form-check ml-auto">
            <BooleanInput
              id='keepFilteredInput'
              className='mr-2'
              value={keepFiltered}
              onChange={(checked: boolean): void => {
                changeKeepFiltered(checked);
              }}
            />
            <label className="form-check-label" htmlFor="keepFilteredInput">
              Keep filtered
            </label>
          </div>
        </div>
      </ObjectsModal>}
    </>
  );
}
