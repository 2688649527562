import React from 'react';
import { ObjectSort, Fields, SortDirection, ObjectSortItem } from '../entities';
import { CustomField, FieldType } from 'core';
import BooleanInput from './BooleanInput';
import Icon from './Icon';
import SelectInput from './SelectInput';
import ObjectsModal from './ObjectsModal';
import * as objectFilterUtils from '../utils/objectFilterUtils'
import { noSortableTypes } from '../utils/objectSortUtils';

type State = {
  fields: Fields,
  dynamicFieldNames: string[],
  objectSort: ObjectSort,
  keepSorted: boolean,
  isModalVisible: boolean,
  customizeDefaultFields: boolean
};

type Events = {
  onSortButtonClick: () => void,
  onCloseButtonClick: () => void,
  onChange: (objectSort: ObjectSort, keepSorted: boolean) => void
}

type Props = State & Events;

export default function View({ fields, dynamicFieldNames, objectSort, keepSorted, isModalVisible, onSortButtonClick, onCloseButtonClick, onChange, customizeDefaultFields }: Props): JSX.Element {
  function addSortItem(field: string): void {
    onChange(
      objectSort.concat([{
        field,
        direction: SortDirection.Asc
      }]),
      keepSorted
    );
  }

  function removeSortItem(index: number): void {
    const newObjectSort: ObjectSort = objectSort.concat();
    newObjectSort.splice(index, 1);
    onChange(newObjectSort, keepSorted);
  }

  function changeSortItem(index: number, field: string, direction: SortDirection) {
    const newObjectSort: ObjectSort = objectSort.concat();
    newObjectSort[index] = {
      field,
      direction
    };
    onChange(newObjectSort, keepSorted);
  }

  function clearAll(): void {
    onChange([], keepSorted);
  }

  function changeKeepSorted(keepSorted: boolean): void {
    onChange(objectSort, keepSorted);
  }

  function getFieldTitle(fieldName: string) {
    switch (fieldName) {
      case 'id':
      case 'objectId':
        return 'Id';

      case 'createdAt':
        return 'Created At (UTC)'

        case 'updatedAt':
        return 'Updated At (UTC)'
    }
    return fields[fieldName].customField ? (fields[fieldName].customField as CustomField).title : fieldName;
  }

  let availableFields = dynamicFieldNames.filter((fieldName: string): boolean => {
    return !noSortableTypes.includes(objectFilterUtils.getFieldType(fieldName, fields));
  })

  if (!customizeDefaultFields) {
    availableFields.unshift('id')
    availableFields.push('createdAt', 'updatedAt')
  }

  availableFields = availableFields.filter((fieldName: string): boolean =>
    !objectSort.find((objectSortItem: ObjectSortItem): boolean => objectSortItem.field === fieldName)
  );

  return (
    <>
      <button className="btn btn-primary mr-2 sort-button" onClick={onSortButtonClick}>
        <Icon icon="fa-exchange-alt" className="fa-rotate-90" isMaterial={false}></Icon>
        <span className='d-none d-sm-inline'> Sort ({keepSorted ? objectSort.length : 0})</span>
      </button>
      {isModalVisible && <ObjectsModal title='Sort'className='objects-sort-modal' onCloseButtonClick={onCloseButtonClick} onExternalClick={onCloseButtonClick}>
        <div className="modal-body">
          {objectSort.map((objectSortItem: ObjectSortItem, index: number): JSX.Element => {
            return (
              <div key={index} className="row mb-3">
                <div className="col-1 d-flex">
                  <button type="button" className="btn btn-link text-secondary" aria-label="Remove" onClick={(): void => { removeSortItem(index); }}>
                    <Icon icon='zmdi-close' />
                  </button>
                </div>
                <div className="col-11 col-sm-5 mb-3 mb-sm-0">
                  <div className="flex-grow-1">
                    <label htmlFor={`fieldName${index}`} className="sr-only">Field</label>
                    <SelectInput
                      value={objectSortItem.field}
                      onChange={(option: { value: string, label: string }): void => {
                        changeSortItem(index, option.value, objectSortItem.direction);
                      }}
                      options={availableFields}
                      labels={getFieldTitle} />
                  </div>
                </div>
                <div className="offset-1 col-11 offset-sm-0 col-sm-6">
                  <div className="btn-group btn-group-toggle w-100">
                    <label className={`btn w-50 d-flex align-items-center justify-content-center ${objectSortItem.direction === SortDirection.Asc ? 'btn-primary active' : 'btn-secondary'}`}>
                      <input type="radio" name={`direction${index}`} id={`ascDirection${index}`} autoComplete="off" checked={objectSortItem.direction === SortDirection.Asc} onChange={(): void => { changeSortItem(index, objectSortItem.field, SortDirection.Asc); }} /> Sort A &rarr; Z
                    </label>
                    <label className={`btn w-50 d-flex align-items-center justify-content-center ${objectSortItem.direction === SortDirection.Desc ? 'btn-primary active' : 'btn-secondary'}`}>
                      <input type="radio" name={`direction${index}`} id={`descDirection${index}`} autoComplete="off" checked={objectSortItem.direction === SortDirection.Desc} onChange={(): void => { changeSortItem(index, objectSortItem.field, SortDirection.Desc); }} /> Sort Z &rarr; A
                    </label>
                  </div>
                </div>
              </div>
            );
          })}
          <div className="row justify-content-end mt-3">
            <SelectInput
              className='add-field-dropdown'
              placeholder='Add field to sort by'
              options={availableFields}
              labels={getFieldTitle}
              onChange={(option: { value: string, label: string }): void => addSortItem(option.value)}
              controlShouldRenderValue={false}
              renderAsButton={true} />
          </div>
        </div>
        <div className="modal-footer justify-content-start">
          <button type="button" className="btn btn-danger" onClick={clearAll}>
            <Icon className='mr-1' icon='zmdi-delete'/>
            Clear All
          </button>
          <div className="form-check ml-auto">
            <BooleanInput
              className="mr-2"
              id="keepSortedInput"
              value={keepSorted}
              onChange={(checked: any): void => {
                changeKeepSorted(checked);
              }}
            />
            <label className="form-check-label" htmlFor="keepSortedInput">
              Keep sorted
            </label>
          </div>
        </div>
      </ObjectsModal>}
    </>
  );
}
