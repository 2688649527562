import React, { ChangeEvent, FormEvent, PureComponent } from 'react';
import { connect } from 'react-redux';
import DocumentTitle from 'react-document-title';
import { LoggedUser } from '../entities';
import * as api from '../api';


type FieldState = {
  value: string,
  isValid: boolean,
  showValidation: boolean
};

type OwnState = {
  username: FieldState,
  password: FieldState,  
  isSubmitting: boolean,
  errorMessage: string
};
 
type State = OwnState & {
  appName: string,
  logo: string
};

type Events = {
  componentWillMount: () => void,
  componentWillUnmount: () => void,
  onInputChange: (event: ChangeEvent<HTMLInputElement>) => void,
  onFormSubmit: (event: FormEvent) => void
};

type ViewProps = State & Events;

class View extends PureComponent<ViewProps> {
  componentWillMount(): void {
    document.body.classList.add('brand-gradient');
  }

  componentWillUnmount(): void {
    document.body.classList.remove('brand-gradient');
  }

  render(): JSX.Element {
    return (
      <>
        <DocumentTitle title={this.props.appName} />
        <div className="login container d-flex flex-column">
          <div className="row mt-2 mt-sm-5 justify-content-center">
            <div className="login-card">
              <div className="container-fluid">
              { this.props.logo ? 
                <div className="container-logo"><img className="image-logo" src={this.props.logo } /> </div> : 
                <h2>Log into {this.props.appName}</h2> }
                <form
                  noValidate
                  className="mt-3 mt-sm-4 needs-validation"
                  onSubmit={this.props.onFormSubmit}>
                  <fieldset>
                    <div className="form-group">
                      <label htmlFor="username">Username</label>
                      <input
                        type="text"
                        id="username"
                        name="username"
                        required
                        placeholder="Enter username"
                        className={`form-control${
                          this.props.username.showValidation &&
                          !this.props.username.isValid ?
                          ' is-invalid' :
                          ''
                        }`}
                        value={this.props.username.value}
                        onChange={this.props.onInputChange}/>
                      <div className="invalid-feedback">
                        Please enter username.
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="password">Password</label>
                      <input
                        type="password"
                        id="password"
                        name="password"
                        required
                        placeholder="Enter password"
                        className={`form-control${
                          this.props.password.showValidation &&
                          !this.props.password.isValid ?
                          ' is-invalid' :
                          ''
                        }`}
                        value={this.props.password.value}
                        onChange={this.props.onInputChange}/>
                      <div className="invalid-feedback">
                        Please enter password.
                      </div>
                    </div>
                    <button
                      type="submit"
                      className="btn btn-block btn-primary mt-4 btn-lg"
                      disabled={this.props.isSubmitting}>
                      {this.props.isSubmitting ? (
                        <span>
                          <i className="fa fa-spinner fa-spin mr-2"></i>
                          Logging In
                        </span>
                      ) : (
                        'Log In'
                      )}
                    </button>
                    {this.props.errorMessage &&
                      <div
                        className="alert alert-danger mt-2"
                        role="alert">
                        {this.props.errorMessage}
                      </div>
                    }
                  </fieldset>
                </form>
              </div>
            </div>
           
          </div>
          <footer className='row footer justify-content-center'>
            <div className='col-12'>
              <span>&copy; Made with a lot of &nbsp;</span>
              <i className='fas fa-heart footer-icon'></i>
              <span> &nbsp; and &nbsp;</span>
              <i className='fas fa-coffee footer-icon'></i>
              <span> &nbsp; by &nbsp; </span>
              <a href="http://www.back4app.com">Back4App.</a>
            </div>
          </footer>
        </div>
      </>
    );
  }
};

type RootState = { app: { settings: { appName: string, logo: string }}, logIn: OwnState };

function mapState(rootState: RootState): State {
  const { appName, logo } = rootState.app.settings;
  return {
    appName,
    logo,
    ...rootState.logIn
  };
}

export enum ActionTypes {
  ChangeField = 'LOG_IN.CHANGE_FIELD',
  Submit = 'LOG_IN.SUBMIT',
  Confirm = 'LOG_IN.CONFIRM',
  Fail = 'LOG_IN.FAIL'
};

type FieldName = 'username' | 'password';

type ChangeFieldAction = {
  type: ActionTypes.ChangeField,
  payload: {
    name: FieldName,
    value: string,
    isValid: boolean,
    showValidation: boolean
  }
};

type SubmitAction = {
  type: ActionTypes.Submit
}

type ConfirmAction = {
  type: ActionTypes.Confirm,
  payload: {
    loggedUser: LoggedUser
  }
}

type FailAction = {
  type: ActionTypes.Fail,
  payload: {
    errorMessage: string
  }
}

export type Action =
  ChangeFieldAction |
  SubmitAction |
  ConfirmAction |
  FailAction;

function changeField(name: FieldName, value: string): ChangeFieldAction {
  return {
    type: ActionTypes.ChangeField,
    payload: {
      name,
      value,
      isValid: value.length > 0,
      showValidation: true
    }
  };
}

type Dispatch = any;

type GetState = () => RootState;

type Thunk = (dispatch: Dispatch, getState: GetState) => Promise<Action>

function submit(): Thunk {
  return async (dispatch: Dispatch, getState: GetState): Promise<Action> => {
    dispatch({
      type: ActionTypes.Submit
    });

    const state: State = mapState(getState());

    if (!state.username.isValid || !state.password.isValid) {
      return dispatch(fail('Please fill all fields.'));
    }

    let sessionToken: string;
    try {
      sessionToken = await api.logIn(state.username.value, state.password.value);
    } catch (e) {
      return dispatch(fail(e.message))
    }

    return dispatch(confirm(state.username.value, sessionToken));
  };
}

function confirm(username: string, sessionToken: string): ConfirmAction {
  return {
    type: ActionTypes.Confirm,
    payload: {
      loggedUser: {
        username,
        sessionToken
      }
    }
  };
}

function fail(errorMessage: string): FailAction {
  return {
    type: ActionTypes.Fail,
    payload: {
      errorMessage
    }
  };
}

function mapEvents(dispatch: Dispatch): Events {
  return {
    componentWillMount: (): void => {},
    componentWillUnmount: (): void => {},
    onInputChange: (event: ChangeEvent<HTMLInputElement>): void => {
      dispatch(changeField(event.target.name as FieldName, event.target.value));
    },
    onFormSubmit: (event: FormEvent): void => {
      event.preventDefault();

      dispatch(submit());
    }
  };
}

export default connect(mapState, mapEvents)(View);

const INITIAL_STATE: OwnState = {
  username: {
    value: '',
    isValid: false,
    showValidation: false
  },
  password: {
    value: '',
    isValid: false,
    showValidation: false
  },  
  isSubmitting: false,
  errorMessage: ''
};

export function reducer(state: OwnState = INITIAL_STATE, action: Action): OwnState {
  function computeState(changes: Partial<OwnState>): OwnState {
    return Object.assign(
      {},
      state,
      changes
    );
  }

  function computeFieldState(name: FieldName, changes: Partial<FieldState>): FieldState {
    return Object.assign(
      {},
      state[name],
      changes
    );
  }

  switch (action.type) {
    case ActionTypes.ChangeField:
      return computeState({
        [action.payload.name]: {
          value: action.payload.value,
          isValid: action.payload.isValid,
          showValidation: action.payload.showValidation
        }
      });
    
    case ActionTypes.Submit:
      return computeState({
        isSubmitting: true,
        errorMessage: ''
      });

    case ActionTypes.Confirm:
      return INITIAL_STATE;

    case ActionTypes.Fail:
      return computeState({
        username: computeFieldState(
          'username',
          {
            showValidation: true
          }
        ),
        password: computeFieldState(
          'password',
          {
            showValidation: true
          }
        ),
        isSubmitting: false,
        errorMessage: action.payload.errorMessage
      });
  }
  
  return state;
};
