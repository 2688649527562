import React from 'react';
import Title from './Title';
import ReactPlayer from 'react-player'
import Icon from './Icon';

export default function Home(): JSX.Element {
  return (
    <>
      <section className='home'>
        <Title title='Home' />
        <div className='container-fluid class-table home-content-container'>
          <h4 className='d-flex align-items-center mb-0'>
            <Icon className='home-play-icon' icon='zmdi-play' />
            <span className='ml-3'>Video Tutorial</span>
          </h4>
          <h5>Learn how to use the Admin App</h5>
          <div className='home-video-container d-flex justify-content-center align-items-center'>
            <ReactPlayer url='https://www.youtube.com/watch?v=7CHdIniAACE' controls />
          </div>
        </div>
      </section>
    </>
  );
}