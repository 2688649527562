import React, { ChangeEvent } from 'react';
import { ObjectEntity, Fields, UIsObjects } from '../entities';
import { connect } from 'react-redux';
import { formatDate } from '../util';
import { FieldType, ObjectClassField, PointerReference, CustomField } from 'core';
import Checkbox from './Checkbox';
import TableCell from './TableCell';
import { Link } from 'react-router-dom';
import { convertArrayToPolygon } from './PolygonInput';
import { checkImagesExtensions } from './FileInput';

type State = {
  objectEntity: ObjectEntity,
  fields: Fields,
  dynamicFieldNames: string[],
  isSelected: boolean,
  referencedBy: ({
    referenceLinkText?: string,
    referenceTitleField?: string
  } & PointerReference)[],
  className: string,
  showImages?: boolean,
  isRelationTable: boolean,
  customizeDefaultFields: boolean
};

type Events = {
  onInputChange: (event: ChangeEvent<HTMLInputElement>) => void
};

type Props = State & Events;

function View({ objectEntity, fields, dynamicFieldNames, isSelected, onInputChange, referencedBy, className, showImages, isRelationTable, customizeDefaultFields }: Props): JSX.Element {
  return (
    <tr className='objects-row'>
      <td style={{maxWidth: '1px', minWidth: '1px'}} className={`${(showImages && 'vertical-centered') || ''}`} >
        { !isRelationTable && <Checkbox id={objectEntity.id} name={objectEntity.id} checked={isSelected} onChange={onInputChange} /> }
      </td>
      { !customizeDefaultFields && <TableCell value={objectEntity.id} className={`${(showImages && 'vertical-centered') || ''}`}/> }
      {dynamicFieldNames.map((dynamicFieldName: string): JSX.Element => {
          let objectValue: any = objectEntity[dynamicFieldName];
          let hoverContent : JSX.Element | string | undefined = undefined;
          let objectElement: JSX.Element | undefined = undefined;
          let copyContent: string | undefined = undefined;
          const objectClassField: ObjectClassField = fields[dynamicFieldName].objectClassField || { name: '', type: FieldType.String};
          const objectType: FieldType = objectClassField.type;
          const customField = fields[dynamicFieldName].customField;
          let tdClassName: string = (customField && (customField as CustomField).cssClassName) || '';
          switch (objectType) {
            case FieldType.Object:
              objectValue = objectValue ? JSON.stringify(objectValue) : '';
              break;
            case FieldType.Date:
              const inputMask = customField && (customField as CustomField).inputMask;
              if (objectValue) {
                // Since this is a date that the user has selected, we need to consider its timezone (it's not always on UTC).
                const date = new Date(objectValue);
                objectValue = formatDate(new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000), inputMask);
              }
              break;
            case FieldType.Bool:
              objectValue = objectValue === true ? 'Yes' : (objectValue === false ? 'No' : '');
              break;
            case FieldType.Number:
              tdClassName = tdClassName || 'text-right';
              copyContent = (objectValue !== undefined && objectValue !== null) && objectValue.toString();
              break;
            case FieldType.File:
              if (objectValue) {
                const name: string = objectValue.name as string;
                const url: string = objectValue.url as string;
                const lowerUrl: string = url.toLowerCase();

                const isImage: boolean = checkImagesExtensions(lowerUrl);
                const shortName = name.slice(name.indexOf('_') + 1);
                return <TableCell
                  key={dynamicFieldName}
                  value={
                    isImage && showImages ?
                    <a href={url} target="_blank">
                      <img src={url} className="img-fluid" alt={url} />
                    </a>
                    :
                    <a href={url} target="_blank">{shortName}</a>
                  }
                  hoverContent={
                    <a href={url} target="_blank">
                      {
                        isImage && !showImages ?
                          <img src={url} className="img-fluid" alt={url} />
                        :
                          ''
                      }
                      <br/>
                      {shortName}
                    </a>
                  }
                  copyContent={url}
                  isImage={isImage}
                />
              }
              break;
            case FieldType.GeoPoint:
              hoverContent = objectValue ?
                <>
                  <p>{`Latitude: ${objectValue.latitude}`}</p>
                  <p>{`Longitude: ${objectValue.longitude}`}</p>
                </> : '';
              objectValue = objectValue ? `(${objectValue.latitude}, ${objectValue.longitude})` : '';
              break;
            case FieldType.Pointer:
              tdClassName = tdClassName || 'pointer-type';
              if (objectValue) {
                const targetClass = objectClassField.targetClass;
                if (!targetClass) {
                  throw new Error(`Target class from field ${objectClassField.name} undefined`);
                }
                objectElement = <Link to={`/objects/${targetClass}/${objectValue}`}>{objectValue}</Link>;
              }
              break;
            case FieldType.Polygon:
              const polygonFormattedString = objectValue && objectValue.coordinates ? JSON.stringify(convertArrayToPolygon(objectValue.coordinates), null, 2) : '';

              hoverContent = polygonFormattedString ? <pre>{polygonFormattedString}</pre> : '';
              copyContent = polygonFormattedString;
              objectValue = objectValue && objectValue.coordinates ?
                (objectValue.coordinates as number[][]).map(coordinate => `(${coordinate[0]}, ${coordinate[1]}) `) :
                '';
              break;
            case FieldType.Array:
              if (objectValue) {
                objectValue = JSON.stringify(objectValue);
              }
              break;
            case FieldType.Relation:
              objectElement = <Link className='relation-link' to={`/objects/${className}/${objectEntity.id}/relations/${objectClassField.name}`}>View relations</Link>;
              objectValue = '';
              break;
          }
          return (
            <TableCell key={dynamicFieldName} className={`${tdClassName} ${(showImages && 'vertical-centered') || ''}`} value={objectValue} hoverContent={hoverContent} copyContent={copyContent} >
              {objectElement}
            </TableCell>
          );
        })
      }
      {
        !customizeDefaultFields &&
          <>
            <TableCell value={formatDate(objectEntity.createdAt)} className={`${(showImages && 'vertical-centered') || ''}`} />
            <TableCell value={formatDate(objectEntity.updatedAt)} className={`${(showImages && 'vertical-centered') || ''}`} />
          </>
      }
      {referencedBy && referencedBy.map(({ className: referenceClassName, fieldName, referenceLinkText, referenceTitleField = ''}) => {
        const refererTitle = referenceTitleField && objectEntity[referenceTitleField] || '';
        return (
          <TableCell key={`${referenceClassName}/${fieldName}}`} className={`'text-center' ${(showImages && 'vertical-centered') || ''}`} value={`${referenceClassName} (${fieldName})`} showHoverContent={false}>
            <Link className='references-link' to={{ pathname: `/objects/${className}/${objectEntity.id}/references/${referenceClassName}/${fieldName}`, state: { refererTitle }}}>
              {referenceLinkText ? referenceLinkText : 'View references'}
            </Link>
          </TableCell>
        )
      })}
    </tr>
  );
}

type OwnProps = {
  objectsUniqueID: string,
  objectId: string,
  fields: Fields,
  dynamicFieldNames: string[],
  isSelected: boolean,
  onSelectChange: (objectId: string, isSelected: boolean) => void,
  referencedBy: PointerReference[],
  className: string,
  showImages?: boolean,
  isRelationTable: boolean,
  customizeDefaultFields: boolean
};

type RootState = {
  objects: {
    uisObjects: UIsObjects
  }
};

function mapState(rootState: RootState, ownProps: OwnProps): State {
  const { className, dynamicFieldNames, fields, isSelected, objectId, objectsUniqueID, referencedBy, isRelationTable, customizeDefaultFields } = ownProps;
  return {
    objectEntity: rootState.objects.uisObjects[objectsUniqueID].objectsById[objectId],
    fields,
    dynamicFieldNames,
    isSelected,
    referencedBy,
    className,
    isRelationTable,
    customizeDefaultFields
  };
}

function mapEvents(_dispatch: any, ownProps: OwnProps): Events {
  return {
    onInputChange: (event: ChangeEvent<HTMLInputElement>): void => {
      ownProps.onSelectChange(event.target.name, event.target.checked);
    }
  };
}

export default connect(mapState, mapEvents)(View);
