import React from 'react';
import InputValidationText from './InputValidationText';

type BooleanInputProps = {
  id?: string,
  value: boolean | undefined,
  name?: string
  onChange: Function,
  isInvalid?: boolean,
  fieldTitle?: string,
  tristate?: boolean,
  className?: string
};

const BooleanInput = (props:BooleanInputProps) => {
  const { id, name, onChange, value, isInvalid, fieldTitle, tristate, className } = props;

  if (tristate) {
    return (
      <>
        <div className={`boolean-input-validation ${isInvalid ?' is-invalid' : ''} ${className ? ` ${className}` : ''}`}>
          <div className={`boolean-input-container input-group${isInvalid ?' is-invalid' : ''}`}>
            <div className="custom-control custom-radio custom-control-inline">
              <input type="radio" className="custom-control-input" id={`${name}-true`} name={name} onChange={() => onChange(true)} checked={value === true} />
              <label className="custom-control-label" htmlFor={`${name}-true`}>Yes</label>
            </div>
            <div className="custom-control custom-radio custom-control-inline mr-2 mr-sm-3">
              <input type="radio" className="custom-control-input" id={`${name}-false`} name={name} onChange={() => onChange(false)} checked={value === false} />
              <label className="custom-control-label" htmlFor={`${name}-false`}>No</label>
            </div>
            <div className="vertical-row mr-2 mr-sm-3"></div>
            <div className="custom-control custom-radio custom-control-inline">
              <input type="radio" className="custom-control-input" id={`${name}-undefined`} name={name} onChange={() => onChange(undefined)} checked={value === undefined} />
              <label className="custom-control-label" htmlFor={`${name}-undefined`}>Undefined</label>
            </div>
          </div>
        </div>
        {isInvalid ? <InputValidationText fieldName={fieldTitle || name || ''} /> : null}
      </>
    );
  }
  return (
    <button
      id={id}
      type="button"
      className={`btn btn-lg btn-toggle${className ? ` ${className}` : ''}${value ? ' active' : ''}`}
      data-toggle="button"
      aria-pressed={value === true}
      onClick={() => onChange(!value)}>
      <div className="handle"></div>
    </button>
  );
};

export default BooleanInput;
