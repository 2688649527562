import { FieldType, ObjectClassField, CustomField } from "core";
import { FilterOperator, ObjectFilterItem, Fields, ObjectFilter } from "../entities";

export function getFieldType(fieldName: string, fields: Fields): FieldType {
  let fieldType: FieldType = FieldType.String;
  if (fields[fieldName].objectClassField) {
    fieldType = (fields[fieldName].objectClassField as ObjectClassField).type;
  }
  return fieldType;
}

export function getDefaultOperatorValues(operator: FilterOperator, fieldType: FieldType): any[] {
  switch(operator) {
    case FilterOperator.Exists:
    case FilterOperator.DoesNotExist:
    case FilterOperator.ContainedIn:
    case FilterOperator.NotContainedIn:
      return [];

    default:
      if (fieldType === FieldType.Number) {
        return [0];
      } else if (fieldType === FieldType.Bool) {
        return [false];
      } else if (fieldType === FieldType.Date) {
        return [new Date()];
      } else {
        return [''];
      }
  }
}

export function getFieldTitle(fieldName: string, fields: Fields) {
  switch (fieldName) {
    case 'id':
    case 'objectId':
      return 'Id';

    case 'createdAt':
      return 'Created At (UTC)'

      case 'updatedAt':
      return 'Updated At (UTC)'
  }
  return fields[fieldName] && fields[fieldName].customField ? (fields[fieldName].customField as CustomField).title : fieldName;
}

export function getAvailableOperators(index: number, fieldName: string, fieldType: FieldType, objectFilter: ObjectFilter): FilterOperator[] {
  const availableOperators: FilterOperator[] = [];
  const extraObjectFilter = objectFilter.slice(0, index).concat(objectFilter.slice(index + 1)).filter((objectFilterItem: ObjectFilterItem): boolean => { return objectFilterItem.fieldName === fieldName; });

  if (
    !['id', 'objectId'].includes(fieldName) &&
    [FieldType.String, FieldType.Number, FieldType.Bool, FieldType.Date, FieldType.Object, FieldType.File, FieldType.GeoPoint, FieldType.Array, FieldType.Polygon ].includes(fieldType) &&
    !extraObjectFilter.find((objectFilterItem: ObjectFilterItem): boolean => { return [FilterOperator.Exists, FilterOperator.DoesNotExist, FilterOperator.EqualsTo].includes(objectFilterItem.operator); })
  ) {
    availableOperators.push(FilterOperator.Exists);
    availableOperators.push(FilterOperator.DoesNotExist);
  }

  if (
    [FieldType.String, FieldType.Number, FieldType.Bool, FieldType.Date, FieldType.Pointer].includes(fieldType) &&
    extraObjectFilter.length <= 0
  ) {
    availableOperators.push(FilterOperator.EqualsTo);
  }

  if (
    [FieldType.String, FieldType.Number, FieldType.Bool, FieldType.Date, FieldType.Pointer].includes(fieldType) &&
    !extraObjectFilter.find((objectFilterItem: ObjectFilterItem): boolean => { return [FilterOperator.EqualsTo, FilterOperator.DoesNotEqualTo].includes(objectFilterItem.operator); })
  ) {
    availableOperators.push(FilterOperator.DoesNotEqualTo);
  }

  if (
    [FieldType.String, FieldType.Number].includes(fieldType) &&
    !extraObjectFilter.find((objectFilterItem: ObjectFilterItem): boolean => { return [FilterOperator.ContainedIn, FilterOperator.EqualsTo].includes(objectFilterItem.operator); })
  ) {
    availableOperators.push(FilterOperator.ContainedIn);
  }

  if (
    [FieldType.String, FieldType.Number].includes(fieldType) &&
    !extraObjectFilter.find((objectFilterItem: ObjectFilterItem): boolean => { return [FilterOperator.EqualsTo, FilterOperator.NotContainedIn].includes(objectFilterItem.operator); })
  ) {
    availableOperators.push(FilterOperator.NotContainedIn);
  }

  if (
    [FieldType.String].includes(fieldType) &&
    !extraObjectFilter.find((objectFilterItem: ObjectFilterItem): boolean => { return [FilterOperator.EqualsTo, FilterOperator.StartsWith, FilterOperator.EndsWith, FilterOperator.Contains].includes(objectFilterItem.operator); })
  ) {
    availableOperators.push(FilterOperator.StartsWith);
    availableOperators.push(FilterOperator.EndsWith);
  }

  if (
    [FieldType.String, FieldType.File].includes(fieldType) &&
    !extraObjectFilter.find((objectFilterItem: ObjectFilterItem): boolean => { return [FilterOperator.EqualsTo, FilterOperator.StartsWith, FilterOperator.EndsWith, FilterOperator.Contains].includes(objectFilterItem.operator); })
  ) {
    availableOperators.push(FilterOperator.Contains);
  }

  if (
    [FieldType.String, FieldType.Number, FieldType.Date].includes(fieldType) &&
    !extraObjectFilter.find((objectFilterItem: ObjectFilterItem): boolean => { return [FilterOperator.EqualsTo, FilterOperator.GreaterThan].includes(objectFilterItem.operator); })
  ) {
    availableOperators.push(FilterOperator.GreaterThan);
  }

  if (
    [FieldType.String, FieldType.Number, FieldType.Date].includes(fieldType) &&
    !extraObjectFilter.find((objectFilterItem: ObjectFilterItem): boolean => { return [FilterOperator.EqualsTo, FilterOperator.GreaterThanOrEqualTo].includes(objectFilterItem.operator); })
  ) {
    availableOperators.push(FilterOperator.GreaterThanOrEqualTo);
  }

  if (
    [FieldType.String, FieldType.Number, FieldType.Date].includes(fieldType) &&
    !extraObjectFilter.find((objectFilterItem: ObjectFilterItem): boolean => { return [FilterOperator.EqualsTo, FilterOperator.LessThan].includes(objectFilterItem.operator); })
  ) {
    availableOperators.push(FilterOperator.LessThan);
  }

  if (
    [FieldType.String, FieldType.Number, FieldType.Date].includes(fieldType) &&
    !extraObjectFilter.find((objectFilterItem: ObjectFilterItem): boolean => { return [FilterOperator.EqualsTo, FilterOperator.LessThanOrEqualTo].includes(objectFilterItem.operator); })
  ) {
    availableOperators.push(FilterOperator.LessThanOrEqualTo);
  }

  return availableOperators;
}
