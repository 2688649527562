import React from 'react';
import Icon, { IconSize } from './Icon';
import CopyToClipboard from 'react-copy-to-clipboard'
import { notify } from './Notification'

type HoverProps = {
  format?: string,
  content: string | JSX.Element,
  refHover?: any,
  x?: number,
  y?: number,
  show: boolean,
  showCopyIcon?: boolean,
  copyContent?: string,
  isImage?: boolean
}

const Hover = (props: HoverProps): JSX.Element => {
  const { content, x, y, show = false, showCopyIcon = true, isImage = false } = props;

  const copyContent = props.copyContent || (typeof content === 'string' ? content as string : '');

  const copyIcon = (
    <div className="hover-title">
      <CopyToClipboard text={copyContent} onCopy={() => notify('zmdi-copy', `Copied to clipboard`)}>
        <Icon icon="zmdi-copy" size={IconSize.LARGE} />
      </CopyToClipboard>
    </div>
  )

  return (
    <div className={`hover ${show && content ? 'show' : ''}`} style={{left: x, top: y}}>
      {showCopyIcon && copyIcon}
      <div className={`hover-content ${isImage ? 'content-centered' : ''}`}>{content}</div>
    </div>
  )
};

export default Hover;
