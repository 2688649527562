import React, { ChangeEvent, KeyboardEvent } from 'react';

type Props = {
  id?: string,
  required?: boolean,
  className?: string,
  type?: string,
  value?: string,
  onChange?(value: any): void,
  onKeyDown?(key: string): void,
  placeholder?: string
};

export default ({ id, required = false, className, type = 'text', value, onChange, onKeyDown, placeholder }: Props) => {
  return (
    <input
      type={type}
      id={id}
      name={name}
      required={required}
      className={`form-control${className ? ` ${className}`: ''}`}
      value={value}
      onKeyDown={onKeyDown && ((e: KeyboardEvent<HTMLInputElement>): void => {
        const { key } = e;
        if (key === '-' || key === 'Enter') {
          e.preventDefault();
        }
        onKeyDown(key);
      })}
      onChange={onChange && ((e: ChangeEvent<HTMLInputElement>): void => {
        onChange(e.target.value);
      })}
      placeholder={placeholder}
    />
  );
};
