import React from 'react';
import JSONEditor from './JSONEditor';
import { GeoPoint } from './GeoPointInput';

// A function used to convert the array of coordinates to an array of geopoints
// to present the data in a human-friendly way
export function convertArrayToPolygon(array: number[][]) {
  return array && array.map && array.map((coordinate: number[]) => (
    {
      'latitude': coordinate[0],
      'longitude': coordinate[1]
    }
  ))
}

export function convertPolygonToArray(json: GeoPoint[] | '') {
  if (json === '') return '';
  return json && json.map && json.map((coordinate: GeoPoint) => (
    [ coordinate.latitude, coordinate.longitude ]
  ))
}

type Props = {
  value: any | undefined,
  onChange(value: JSON | null | ''): void,
  readOnly: boolean,
  isInvalid: boolean,
  fieldTitle: string
};

class PolygonInput extends React.Component<Props> {
  constructor(props: Props) {
    super(props)

    this.handleOnChange = this.handleOnChange.bind(this);
  }

  handleOnChange(value: string) {
    try {
      const polygonArray = convertPolygonToArray(JSON.parse(value))
      return this.props.onChange(polygonArray as any)
    } catch (e) {
      return this.props.onChange(value as any)
    }
  }

  render() {
    return <JSONEditor
      value={this.props.value && convertArrayToPolygon(this.props.value)}
      onChange={this.props.onChange}
      handleOnChange={this.handleOnChange}
      readOnly={this.props.readOnly}
      isInvalid={this.props.isInvalid}
      fieldTitle={this.props.fieldTitle} />
  }

};

export default PolygonInput;
