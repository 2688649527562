import { MenuItem, ObjectClass, ParseApp } from 'core';
import { LoggedUser, CustomFields, UIsObjects } from './entities';

enum CacheKeys {
  Settings = 'SETTINGS',
  LoggedUser = 'LOGGED_USER',
  MenuItems = 'MENU_ITEMS',
  ObjectClasses = 'OBJECT_CLASSES',
  CustomFields = 'CUSTOM_FIELDS',
  UIsObjects = 'UIS_OBJECTS',
  ParseApp = 'PARSE_APP'
}

function getItem<TItem>(key: CacheKeys): TItem | undefined {
  const itemString: string | null = localStorage.getItem(key);

  if (!itemString) {
    return undefined;
  }

  return JSON.parse(itemString) as TItem;
}

function setItem(key: CacheKeys, item: any): void {
  localStorage.setItem(key, JSON.stringify(item));
}

export function getSettings(): { [key: string]: string } | undefined {
  return getItem<{ [key: string]: string }>(CacheKeys.Settings);
};

export function setSettings(settings: { [key: string]: string }): void {
  setItem(CacheKeys.Settings, settings);
};

export function getLoggedUser(): LoggedUser | undefined {
  return getItem<LoggedUser>(CacheKeys.LoggedUser);
};

export function setLoggedUser(loggedUser: LoggedUser): void {
  setItem(CacheKeys.LoggedUser, loggedUser);
};

export function clearLoggedUser(): void {
  localStorage.removeItem(CacheKeys.LoggedUser);
};

export function getMenuItems(): MenuItem[] | undefined {
  return getItem<MenuItem[]>(CacheKeys.MenuItems);
};

export function setMenuItems(menuItems: MenuItem[]): void {
  setItem(CacheKeys.MenuItems, menuItems);
};

export function clearMenuItems(): void {
  localStorage.removeItem(CacheKeys.MenuItems);
};

export function getObjectClasses(): ObjectClass[] | undefined {
  return getItem<ObjectClass[]>(CacheKeys.ObjectClasses);
};

export function setObjectClasses(objectClasses: ObjectClass[]): void {
  setItem(CacheKeys.ObjectClasses, objectClasses);
};

export function clearObjectClasses(): void {
  localStorage.removeItem(CacheKeys.ObjectClasses);
};

export function getCustomFields(): CustomFields | undefined {
  return getItem<CustomFields>(CacheKeys.CustomFields);
};

export function setCustomFields(customFields: CustomFields): void {
  setItem(CacheKeys.CustomFields, customFields);
};

export function clearCustomFields(): void {
  localStorage.removeItem(CacheKeys.CustomFields);
};

export function getUIsObjects(): UIsObjects | undefined {
  return getItem<UIsObjects>(CacheKeys.UIsObjects);
};

export function setUIsObjects(uisObjects: UIsObjects): void {
  setItem(CacheKeys.UIsObjects, uisObjects);
};

export function clearUIsObjects(): void {
  localStorage.removeItem(CacheKeys.UIsObjects);
};

export function getParseApp(): ParseApp | undefined {
  return getItem<ParseApp>(CacheKeys.ParseApp);
}

export function setParseApp(parseApp: ParseApp): void {
  return setItem(CacheKeys.ParseApp, parseApp);
}
