import { GraphQLError, DocumentNode, OperationDefinitionNode } from 'graphql';
import ApolloClient, { ApolloQueryResult } from 'apollo-client';
import { InMemoryCache, NormalizedCacheObject } from 'apollo-cache-inmemory';
import { split, FetchResult, ApolloLink } from 'apollo-link';
import { createUploadLink } from 'apollo-upload-client';
import { HttpLink } from 'apollo-link-http';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';
import gql from "graphql-tag";
import { Event, Setting, MenuItem, ObjectClass, CustomField, ObjectClassField, FieldType, FieldSubType, ParseApp, Relation } from 'core';
import { ObjectEntity, Fields, ObjectSort } from './entities';
import { Map, fromJS } from 'immutable';

export interface CustomWindow extends Window {
  SERVER_URL: string;
};
declare let window: CustomWindow;

const isLocal: boolean = window.SERVER_URL === '%REACT_APP_SERVER_URL%';

const graphQLAddress: string = isLocal ? 'http://localhost:7000/graphql' : `https://${window.SERVER_URL}/graphql`;
const subscriptionsAddress: string = isLocal ? 'ws://localhost:7000/subscriptions' : `wss://${window.SERVER_URL}/subscriptions`;

const subscriptionClient: SubscriptionClient = new SubscriptionClient(
  subscriptionsAddress,
  {
    reconnect: true,
    connectionParams: {
      requestOrigin: document.location.host
    }
  }
);

const wsLink: WebSocketLink = new WebSocketLink(subscriptionClient);

let onReconnects: (() => void)[] = [];

subscriptionClient.onReconnected((): void => {
  onReconnects.forEach((onReconnect: () => void): void => {
    onReconnect();
  });
});

const httpLink: ApolloLink = createUploadLink({
  uri: graphQLAddress
});

const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  link: split(
    ({ query }: { query: DocumentNode }): boolean => {
      const { kind, operation } = getMainDefinition(query) as OperationDefinitionNode;
      return kind === 'OperationDefinition' && operation === 'subscription';
    },
    wsLink,
    httpLink
  ),
  cache: new InMemoryCache()
});

const formatError = (e: any): Error => {
  if (e.networkError) {
    return new NetworkError(e.networkError.message);
  }
  if (e.graphQLErrors && e.graphQLErrors.length) {
    return new Error(
      (e.graphQLErrors as GraphQLError[])
        .map((error: GraphQLError): string => error.message)
        .join('\n')
    );
  } else {
    return e;
  }
}

export class NetworkError extends Error {};

export async function settings(): Promise<Setting[]> {
  let result: ApolloQueryResult<{}>;

  try {
    result = await client.query({
      query: gql`
        query Settings {
          settings {
            key,
            value
          }
        }
      `,
      fetchPolicy: 'no-cache'
    });
  } catch (e) {
    throw formatError(e);
  }

  return (result.data as { settings: Setting[] }).settings;
};

export async function loggedUser(sessionToken: string): Promise<string> {
  let result: ApolloQueryResult<{}>;

  try {
    result = await client.query({
      query: gql`
        query LoggedUser($sessionToken: ID!) {
          loggedUser(sessionToken: $sessionToken)
        }
      `,
      variables: {
        sessionToken
      },
      fetchPolicy: 'no-cache'
    });
  } catch (e) {
    throw formatError(e);
  }

  return (result.data as { loggedUser: string }).loggedUser;
};

export async function menuItems(sessionToken: string): Promise<MenuItem[]> {
  let result: ApolloQueryResult<{}>;

  try {
    result = await client.query({
      query: gql`
        query MenuItems($sessionToken: ID!) {
          menuItems(sessionToken: $sessionToken) {
            id,
            title,
            objectClassName,
            relevance,
            isHidden
            addFormTitle
            editFormTitle
            isReadOnly
          }
        }
      `,
      variables: {
        sessionToken
      },
      fetchPolicy: 'no-cache'
    });
  } catch (e) {
    throw formatError(e);
  }

  return (result.data as { menuItems: MenuItem[] }).menuItems;
};

export async function customFields(sessionToken: string): Promise<CustomField[]> {
  let result: ApolloQueryResult<{}>;

  try {
    result = await client.query({
      query: gql`
        query CustomFields($sessionToken: ID!) {
          customFields(sessionToken: $sessionToken) {
            id
            objectClassName
            objectClassFieldName
            title
            subType
            isRequired
            isTableHidden
            isFormHidden
            options
            defaultValue
            relevance
            referencesLinkTitle
            referencesLinkText
            inputMask
            cssClassName
            referenceTitleField
          }
        }
      `,
      variables: {
        sessionToken
      },
      fetchPolicy: 'no-cache'
    });
  } catch (e) {
    throw formatError(e);
  }

  return (result.data as { customFields: CustomField[] }).customFields;
};

export async function objectClasses(sessionToken: string): Promise<ObjectClass[]> {
  let result: ApolloQueryResult<{}>;

  try {
    result = await client.query({
      query: gql`
        query ObjectClasses($sessionToken: ID!) {
          objectClasses(sessionToken: $sessionToken) {
            name
            fields {
              name
              type
              targetClass
            }
            referencedBy {
              className
              fieldName
            }
            textIndexed
          }
        }
      `,
      variables: {
        sessionToken
      },
      fetchPolicy: 'no-cache'
    });
  } catch (e) {
    throw formatError(e);
  }

  return (result.data as { objectClasses: ObjectClass[] }).objectClasses;
};

function mapObject(objectEntity: ObjectEntity): ObjectEntity {
  return Object.keys(objectEntity).reduce((out:ObjectEntity, fieldName: string): ObjectEntity => {
    out[fieldName] = objectEntity[fieldName];
    return out;
  }, {});
}

function mapFindResult(findResult: { list: ObjectEntity[], totalPages: number }, fields: Fields): { list: ObjectEntity[], totalPages: number } {
  return {
    list: findResult.list.map(objectEntity => mapObject(objectEntity)),
    totalPages: findResult.totalPages
  };
}

export async function findObjects(sessionToken: string, className: string, fields: Fields, filter: { [fieldName: string]: { operator: string, values: any[] }[] } | undefined, sort: ObjectSort | undefined, pageLimit: number, page: number, relations: Relation[], fullTextSearchValue: string): Promise<{ list: ObjectEntity[], totalPages: number  }> {
  let result: ApolloQueryResult<{}>;

  const queryName: string = `find${className}`;

  try {
    result = await client.query({
      query: gql`
        query FindObjects($sessionToken: ID! $filter: ${className}B4aAdminFilter $sort: [${className}B4aAdminSortItem!] $pageLimit: Int! $page: Int! $relations: [Relation!] $fullTextSearchValue: String!) {
          ${queryName}(sessionToken: $sessionToken filter: $filter sort: $sort pageLimit: $pageLimit page: $page relations: $relations fullTextSearchValue: $fullTextSearchValue) {
            list {
              id
              ${Object.keys(fields)
                .filter((fieldName: string): boolean => { return fieldName.length > 0 && fieldName !== 'objectId' && fieldName !== 'id'; })
                .map((fieldName: string): string => {
                  const fieldType = fields[fieldName].objectClassField && (fields[fieldName].objectClassField as ObjectClassField).type;
                  if (fieldType === FieldType.File) {
                    return `
                      ${fieldName} {
                        name
                        url
                      }
                    `;
                  } else if (fieldType === FieldType.Relation) {
                    // We don't want to fetch all the Relation data when executing a find query
                    return '';
                  } else {
                    return fieldName;
                  }
                })
                .join('\n')}
            }
            totalPages
          }
        }
      `,
      variables: {
        sessionToken,
        filter,
        sort,
        pageLimit,
        page,
        relations,
        fullTextSearchValue
      },
      fetchPolicy: 'no-cache'
    });
  } catch (e) {
    throw formatError(e);
  }
  return mapFindResult((result.data as { [queryName: string]: { list: ObjectEntity[], totalPages: number } })[queryName] || { list: [], totalPages: 0 }, fields);
};

export async function getObject(sessionToken: string, className: string, fields: Fields, id: string): Promise<ObjectEntity> {
  let result: ApolloQueryResult<{}>;

  const queryName: string = `get${className}`;

  try {
    result = await client.query({
      query: gql`
        query GetObjects($sessionToken: ID!, $id: ID!) {
          ${queryName}(sessionToken: $sessionToken, id: $id) {
            id
            ${Object.keys(fields)
              .filter((fieldName: string): boolean => { return fieldName.length > 0 && fieldName !== 'objectId' && fieldName !== 'id'; })
              .map((fieldName: string): string => {
                const fieldType = fields[fieldName].objectClassField && (fields[fieldName].objectClassField as ObjectClassField).type;
                if (fieldType === FieldType.File) {
                  return `
                    ${fieldName} {
                      name
                      url
                    }
                  `;
                } else if (fieldType === FieldType.Relation) {
                  // Fetches an array of IDs of the objects that are in this relation
                  return `
                    ${fieldName} {
                      values
                    }
                  `;
                } else {
                  return fieldName;
                }
              })
              .join('\n')}
          }
        }
      `,
      variables: {
        sessionToken,
        id
      },
      fetchPolicy: 'no-cache'
    });
  } catch (e) {
    throw formatError(e);
  }

  const objectEntity: ObjectEntity = (result.data as { [queryName: string]: ObjectEntity })[queryName] || {};
  Object.keys(objectEntity).forEach((fieldName: string): void => {
    if (objectEntity[fieldName] === null) {
      delete objectEntity[fieldName];
    }
  });

  return mapObject(objectEntity);
};

export async function logIn(username: string, password: string): Promise<string> {
  let result: FetchResult;

  try {
    result = await client.mutate({
      mutation: gql`
        mutation LogIn($username: ID!, $password: String!) {
          logIn(username: $username, password: $password)
        }
      `,
      variables: {
        username,
        password
      }
    });
  } catch (e) {
    throw formatError(e);
  }

  return (result.data as { logIn: string }).logIn;
};

export async function logOut(sessionToken: string): Promise<void> {
  let result: FetchResult;

  try {
    result = await client.mutate({
      mutation: gql`
        mutation LogOut($sessionToken: ID!) {
          logOut(sessionToken: $sessionToken)
        }
      `,
      variables: {
        sessionToken
      }
    });
  } catch (e) {
    throw formatError(e);
  }

  if ((result.data as { logOut: string[] }).logOut.length <= 0) {
    throw new Error('Session token not found');
  }
};

export async function addObject(sessionToken: string, className: string, objectEntity: ObjectEntity, fields: Fields): Promise<ObjectEntity> {
  let result: FetchResult;

  const mutationName: string = `add${className}`;

  const inputFields: { fieldName: string, fieldType: string }[] = (Object.keys(objectEntity)
    .filter((fieldName: string): boolean => { return fieldName.length > 0 && fieldName !== 'objectId' && fieldName !== 'id' && fields[fieldName] !== undefined && fields[fieldName].objectClassField !== undefined; })
    .map((fieldName: string): ({ fieldName: string, fieldType: string } | undefined) => {
      let fieldType: string | undefined = undefined;
      switch((fields[fieldName].objectClassField as ObjectClassField).type) {
        case FieldType.String:
          fieldType = 'String';
          break;
        case FieldType.Number:
          fieldType = 'Float';
          break;
        case FieldType.Bool:
          fieldType = 'Boolean';
          break;
        case FieldType.Date:
          fieldType = 'Date';
          break;
        case FieldType.Object:
          fieldType = 'Object';
          break;
        case FieldType.File:
          fieldType = 'Upload';
          break;
        case FieldType.GeoPoint:
          fieldType = 'GeoPoint';
          break;
        case FieldType.Pointer:
          fieldType = 'Pointer';
          break;
        case FieldType.Polygon:
          fieldType = 'Polygon';
          break;
        case FieldType.Array:
          fieldType = '[Any]';
          break;
        case FieldType.Relation:
          fieldType = 'RelationInput';
          break;
      }
      if (fieldType) {
        return { fieldName, fieldType };
      } else {
        return undefined;
      }
    })
    .filter((field: {} | undefined): boolean => { return field !== undefined; }) as { fieldName: string, fieldType: string }[]);
  try {
    let objectParams = fromJS(objectEntity);

    result = await client.mutate({
      mutation: gql`
        mutation AddObject(
          $sessionToken: ID!
          ${inputFields.map((inputField: { fieldName: string, fieldType: string }): string => {
            return `$${inputField.fieldName}: ${inputField.fieldType}`;
          }).join('\n')}
        ) {
          ${mutationName}(
            sessionToken: $sessionToken
            ${inputFields.map((inputField: { fieldName: string }): string => {
              return `${inputField.fieldName}: $${inputField.fieldName}`;
            }).join('\n')}
          ) {
            id
            ${Object.keys(fields)
              .filter((fieldName: string): boolean => { return fieldName.length > 0 && fieldName !== 'objectId' && fieldName !== 'id'; })
              .map((fieldName: string): string => {
                const fieldType = fields[fieldName].objectClassField && (fields[fieldName].objectClassField as ObjectClassField).type;
                if (fieldType === FieldType.File) {
                  return `
                    ${fieldName} {
                      name
                      url
                    }
                  `;
                } else if (fieldType === FieldType.Relation) {
                  // Deletes any keys that aren't part of the schema
                  objectParams = objectParams
                    .deleteIn([fieldName, 'values'])
                    .deleteIn([fieldName, '__typename']);

                  // We don't want to fetch any data back from the relation
                  // when this mutation finishes
                  return '';
                } else {
                  return fieldName;
                }
              })
              .join('\n')}
          }
        }
      `,
      variables: {
        sessionToken,
        ...objectParams.toJSON()
      }
    });
  } catch (e) {
    throw formatError(e);
  }

  return mapObject((result.data as { [mutationName: string]: ObjectEntity })[mutationName] || {});
};

export async function saveObject(sessionToken: string, className: string, id: string, objectEntity: ObjectEntity, fields: Fields): Promise<ObjectEntity> {
  let result: FetchResult;

  const mutationName: string = `save${className}`;

  const inputFields: { fieldName: string, fieldType: string }[] = (Object.keys(objectEntity)
    .filter((fieldName: string): boolean => {
      return fieldName.length > 0 && fieldName !== 'objectId' && fieldName !== 'id' && fields[fieldName] !== undefined && fields[fieldName].objectClassField !== undefined;
    })
    .map((fieldName: string): ({ fieldName: string, fieldType: string } | undefined) => {
      let fieldType: string | undefined = undefined;
      switch((fields[fieldName].objectClassField as ObjectClassField).type) {
        case FieldType.String:
          fieldType = 'String';
          break;
        case FieldType.Number:
          fieldType = 'Float';
          break;
        case FieldType.Bool:
          fieldType = 'Boolean';
          break;
        case FieldType.Date:
          fieldType = 'Date';
          break;
        case FieldType.Object:
          fieldType = 'Object';
          break;
        case FieldType.File:
          if (objectEntity[fieldName] instanceof File || objectEntity[fieldName] === null) {
            fieldType = 'Upload';
          }
          break;
        case FieldType.GeoPoint:
          fieldType = 'GeoPoint';
          break;
        case FieldType.Pointer:
          fieldType = 'Pointer';
          break;
        case FieldType.Polygon:
          fieldType = 'Polygon';
          break;
        case FieldType.Array:
          fieldType = '[Any]';
          break;
        case FieldType.Relation:
          fieldType = 'RelationInput';
          break;
      }
      if (fieldType) {
        return { fieldName, fieldType };
      } else {
        return undefined;
      }
    })
    .filter((field: {} | undefined): boolean => { return field !== undefined; }) as { fieldName: string, fieldType: string }[]);

  try {
    let objectParams = fromJS(objectEntity);

    result = await client.mutate({
      mutation: gql`
        mutation SaveObject(
          $sessionToken: ID!,
          $id: ID!,
          ${inputFields.map((inputField: { fieldName: string, fieldType: string }): string => {
            return `$${inputField.fieldName}: ${inputField.fieldType}`;
          }).join('\n')}
        ) {
          ${mutationName}(
            sessionToken: $sessionToken
            id: $id
            ${inputFields.map((inputField: { fieldName: string }): string => {
              return `${inputField.fieldName}: $${inputField.fieldName}`;
            }).join('\n')}
          ) {
            id
            ${Object.keys(fields)
              .filter((fieldName: string): boolean => { return fieldName.length > 0 && fieldName !== 'objectId' && fieldName !== 'id'; })
              .map((fieldName: string): string => {
                const fieldType = fields[fieldName].objectClassField && (fields[fieldName].objectClassField as ObjectClassField).type;
                if (fieldType === FieldType.File) {
                  return `
                    ${fieldName} {
                      name
                      url
                    }
                  `;
                } else if (fieldType === FieldType.Relation) {
                  // Deletes any keys that aren't part of the schema
                  objectParams = objectParams
                    .deleteIn([fieldName, 'values'])
                    .deleteIn([fieldName, '__typename']);

                  // We don't want to fetch any data back from the relation
                  // when this mutation finishes
                  return '';
                } else {
                  return fieldName;
                }
              })
              .join('\n')}
          }
        }
      `,
      variables: {
        sessionToken,
        id,
        ...objectParams.toJSON()
      }
    });
  } catch (e) {
    throw formatError(e);
  }
  return mapObject((result.data as { [mutationName: string]: ObjectEntity })[mutationName] || {});
};

export async function deleteObjects(sessionToken: string, className: string, ids: string[]): Promise<string[]> {
  let result: FetchResult;

  const mutationName: string = `delete${className}`;

  try {
    result = await client.mutate({
      mutation: gql`
        mutation DeleteObject(
          $sessionToken: ID!,
          $ids: [ID]!
        ) {
          ${mutationName}(
            sessionToken: $sessionToken
            ids: $ids
          )
        }
      `,
      variables: {
        sessionToken,
        ids
      }
    });
  } catch (e) {
    throw formatError(e);
  }

  return (result.data as { [mutationName: string]: string[] })[mutationName] || [];
};

export function settingChanged(onChange: (settingChanged: { setting: Setting, event: Event }) => void, onFail: (error: Error) => void, onReconnect: () => void): () => void {
  onReconnects.push(onReconnect);
  const subscription: ZenObservable.Subscription = client.subscribe({
    query: gql`
      subscription SettingChanged {
        settingChanged {
          setting {
            key
            value
          }
          event
        }
      }
    `
  }).subscribe(
    (value: any): void => {
      const settingChanged: { setting: Setting, event: Event } = (value as { data: { settingChanged: { setting: Setting, event: Event }}}).data.settingChanged;
      onChange(settingChanged);
    },
    (error: any): void => {
      if (error.graphQLErrors) {
        error = new Error(
          (error.graphQLErrors as GraphQLError[])
            .map((error: GraphQLError): string => error.message)
            .join('\n')
        );
      }
      onReconnects = onReconnects.filter((item: () => void): boolean => {
        return item !== onReconnect;
      });
      onFail(error as Error);
    },
    (): void => {
      onReconnects = onReconnects.filter((item: () => void): boolean => {
        return item !== onReconnect;
      });
      onFail(new Error('Setting Changed subscription got completed.'));
    }
  );
  return (): void => {
    onReconnects = onReconnects.filter((item: () => void): boolean => {
      return item !== onReconnect;
    });
    subscription.unsubscribe();
  };
}

export function loggedUserChanged(sessionToken: string, onChange: (username: string) => void, onFail: (error: Error) => void, onReconnect: () => void): () => void {
  onReconnects.push(onReconnect);
  const subscription: ZenObservable.Subscription = client.subscribe({
    query: gql`
      subscription LoggedUserChanged($sessionToken: ID!) {
        loggedUserChanged(sessionToken: $sessionToken)
      }
    `,
    variables: {
      sessionToken
    }
  }).subscribe(
    (value: any): void => {
      const username: string = (value as { data: { loggedUserChanged: string  }}).data.loggedUserChanged;
      onChange(username);
    },
    (error: any): void => {
      if (error.graphQLErrors && error.graphQLErrors.length) {
        error = new Error(
          (error.graphQLErrors as GraphQLError[])
            .map((error: GraphQLError): string => error.message)
            .join('\n')
        );
      }
      onReconnects = onReconnects.filter((item: () => void): boolean => {
        return item !== onReconnect;
      });
      onFail(error as Error);
    },
    (): void => {
      onReconnects = onReconnects.filter((item: () => void): boolean => {
        return item !== onReconnect;
      });
      onFail(new Error('Logged User Changed subscription got completed.'));
    }
  );
  return (): void => {
    onReconnects = onReconnects.filter((item: () => void): boolean => {
      return item !== onReconnect;
    });
    subscription.unsubscribe();
  };
}

export function menuItemChanged(sessionToken: string, onChange: (menuItemChanged: { menuItem: MenuItem, event: Event }) => void, onFail: (error: Error) => void, onReconnect: () => void): () => void {
  onReconnects.push(onReconnect);
  const subscription: ZenObservable.Subscription = client.subscribe({
    query: gql`
      subscription MenuItemChanged($sessionToken: ID!) {
        menuItemChanged(sessionToken: $sessionToken) {
          menuItem {
            id
            title
            objectClassName
            relevance
            isHidden
            addFormTitle
            editFormTitle
            isReadOnly
          }
          event
        }
      }
    `,
    variables: {
      sessionToken
    }
  }).subscribe(
    (value: any): void => {
      const menuItemChanged: { menuItem: MenuItem, event: Event } = (value as { data: { menuItemChanged: { menuItem: MenuItem, event: Event }}}).data.menuItemChanged;
      onChange(menuItemChanged);
    },
    (error: any): void => {
      if (error.graphQLErrors && error.graphQLErrors.length) {
        error = new Error(
          (error.graphQLErrors as GraphQLError[])
            .map((error: GraphQLError): string => error.message)
            .join('\n')
        );
      }
      onReconnects = onReconnects.filter((item: () => void): boolean => {
        return item !== onReconnect;
      });
      onFail(error as Error);
    },
    (): void => {
      onReconnects = onReconnects.filter((item: () => void): boolean => {
        return item !== onReconnect;
      });
      onFail(new Error('Menu Item Changed subscription got completed.'));
    }
  );
  return (): void => {
    onReconnects = onReconnects.filter((item: () => void): boolean => {
      return item !== onReconnect;
    });
    subscription.unsubscribe();
  };
}

export function customFieldChanged(sessionToken: string, onChange: (customFieldChanged: { customField: CustomField, event: Event }) => void, onFail: (error: Error) => void, onReconnect: () => void): () => void {
  onReconnects.push(onReconnect);
  const subscription: ZenObservable.Subscription = client.subscribe({
    query: gql`
      subscription CustomFieldChanged($sessionToken: ID!) {
        customFieldChanged(sessionToken: $sessionToken) {
          customField {
            id
            objectClassName
            objectClassFieldName
            title
            subType
            isRequired
            isTableHidden
            isFormHidden
            options
            defaultValue
            relevance
            referencesLinkTitle
            referencesLinkText
            inputMask
            cssClassName
            referenceTitleField
          }
          event
        }
      }
    `,
    variables: {
      sessionToken
    }
  }).subscribe(
    (value: any): void => {
      const customFieldChanged: { customField: CustomField, event: Event } = (value as { data: { customFieldChanged: { customField: CustomField, event: Event }}}).data.customFieldChanged;
      onChange(customFieldChanged);
    },
    (error: any): void => {
      if (error.graphQLErrors && error.graphQLErrors.length) {
        error = new Error(
          (error.graphQLErrors as GraphQLError[])
            .map((error: GraphQLError): string => error.message)
            .join('\n')
        );
      }
      onReconnects = onReconnects.filter((item: () => void): boolean => {
        return item !== onReconnect;
      });
      onFail(error as Error);
    },
    (): void => {
      onReconnects = onReconnects.filter((item: () => void): boolean => {
        return item !== onReconnect;
      });
      onFail(new Error('Custom Field Changed subscription got completed.'));
    }
  );
  return (): void => {
    onReconnects = onReconnects.filter((item: () => void): boolean => {
      return item !== onReconnect;
    });
    subscription.unsubscribe();
  };
}

export function observeObjects(sessionToken: string, className: string, fields: Fields, filter: { [fieldName: string]: { operator: string, values: any[] }[] } | undefined, onChange: (objectChanged: { last: ObjectEntity, event: Event }) => void, onFail: (error: Error) => void, onReconnect: () => void): () => void {
  onReconnects.push(onReconnect);

  const subscriptionName: string = `observe${className}`;

  const subscription: ZenObservable.Subscription = client.subscribe({
    query: gql`
      subscription ObserveObjects($sessionToken: ID! $filter: ${className}B4aAdminFilter) {
        ${subscriptionName}(sessionToken: $sessionToken filter: $filter) {
          last {
            id
            ${Object.keys(fields)
              .filter((fieldName: string): boolean => { return fieldName.length > 0 && fieldName !== 'objectId' && fieldName !== 'id'; })
              .map((fieldName: string): string => {
                const fieldType = fields[fieldName].objectClassField && (fields[fieldName].objectClassField as ObjectClassField).type;
                if (fieldType === FieldType.File) {
                  return `
                    ${fieldName} {
                      name
                      url
                    }
                  `;
                } else if (fieldType === FieldType.Relation) {
                  // We don't want to fetch all the Relation data when executing a find query
                  return '';
                } else {
                  return fieldName;
                }
              })
              .join('\n')}
          }
          event
        }
      }
    `,
    variables: {
      sessionToken,
      filter
    }
  }).subscribe(
    (value: any): void => {
      const objectChanged: { last: ObjectEntity, event: Event } = (value as { data: { [subscriptionName: string]: { last: ObjectEntity, event: Event }}}).data[subscriptionName];
      onChange({ last: mapObject(objectChanged.last), event: objectChanged.event });
    },
    (error: any): void => {
      if (error.graphQLErrors && error.graphQLErrors.length) {
        error = new Error(
          (error.graphQLErrors as GraphQLError[])
            .map((error: GraphQLError): string => error.message)
            .join('\n')
        );
      }
      onReconnects = onReconnects.filter((item: () => void): boolean => {
        return item !== onReconnect;
      });
      onFail(error as Error);
    },
    (): void => {
      onReconnects = onReconnects.filter((item: () => void): boolean => {
        return item !== onReconnect;
      });
      onFail(new Error(`Observe ${className} Objects subscription got completed.`));
    }
  );
  return (): void => {
    onReconnects = onReconnects.filter((item: () => void): boolean => {
      return item !== onReconnect;
    });
    subscription.unsubscribe();
  };
}

export async function parseApp(): Promise<ParseApp> {
  let result: ApolloQueryResult<{}>;

  try {
    result = await client.query({
      query: gql`
        query ParseApp {
          parseApp {
            appId
            ownerId
          }
        }
      `,
      fetchPolicy: 'no-cache'
    });
  } catch (e) {
    throw formatError(e);
  }

  return (result.data as { parseApp: ParseApp }).parseApp as ParseApp;
};
