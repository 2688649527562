import React, { RefObject } from 'react';
import Hover from './Hover';
import debounce from 'lodash.debounce'

type State = {
  x: number,
  y: number,
  showHover: boolean
}

type Props = {
  value: string | JSX.Element,
  hoverContent?: string | JSX.Element,
  copyContent?: string,
  className?: string,
  children?: JSX.Element,
  showHoverContent?: boolean,
  isImage?: boolean
}

class TableCell extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      x: 0,
      y: 0,
      showHover: false
    }
  }

  setHoverPosition(e: any, ref: RefObject<HTMLTableCellElement>) {
    const position = ref.current && ref.current.getBoundingClientRect() as DOMRect;
    if (position) return this.setState({ x: position.x, y: position.y + 30, showHover: true });
  }

  handleMouseOver = debounce(this.setHoverPosition, 500);

  render() {
    const {
      value,
      hoverContent = this.props.value,
      className = '',
      copyContent = this.props.value as string,
      children,
      showHoverContent = true,
      isImage = false
    } = this.props
    const ref = React.createRef<HTMLTableCellElement>()

    return <td ref={ref} className={className} onMouseOver={e => this.handleMouseOver(e, ref)} onMouseLeave={() => this.setState({ showHover: false })}>
      {children || value}
      { showHoverContent &&
        <div>
          <Hover content={hoverContent} show={this.state.showHover} x={this.state.x} y={this.state.y} copyContent={copyContent} isImage={isImage} />
        </div>
      }
    </td>
  }
};

export default TableCell;
