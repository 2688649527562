import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { LoggedUser } from '../entities';
import * as api from '../api';
import Icon from './Icon';

type State = {
  username: string,
  isFeedbackHidden: boolean
};

type Events = {
  onLogOutClick: () => void
};

function View(props: State & Events): JSX.Element {
  return (
    <div className="logout dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
      <span className="dropdown-item overflow-ellipsis" >{props.username}</span>
      {
        !props.isFeedbackHidden ?
          <>
            <hr />
            <a className='dropdown-item' href="mailto:community@back4app.com?subject=Admin App Feedback&body=Hello! I was using the Admin App and I would like to provide this feedback:">
              <Icon className='mr-2' icon='zmdi-email' isMaterial={true} />
              <span>Give your feedback</span>
            </a>
          </> :
          <></>
      }
      <hr />
      <Link to="/" className="dropdown-item logout-text" onClick={props.onLogOutClick}>Log Out</Link>
    </div>
  );
}

type RootState = {
  app: {
    settings: { isFeedbackHidden: string },
    loggedUser?: LoggedUser
  }
};

export enum ActionTypes {
  Submit = 'LOG_OUT.SUBMIT',
  Confirm = 'LOG_OUT.CONFIRM',
  Fail = 'LOG_OUT.FAIL'
};

type SubmitAction = {
  type: ActionTypes.Submit
}

type ConfirmAction = {
  type: ActionTypes.Confirm
}

type FailAction = {
  type: ActionTypes.Fail,
  payload: {
    errorMessage: string
  }
}

export type Action =
  SubmitAction |
  ConfirmAction |
  FailAction;

  type Dispatch = any;

  type GetState = () => RootState;

  type Thunk = (dispatch: Dispatch, getState: GetState) => Promise<Action>

  function submit(): Thunk {
    return async (dispatch: Dispatch, getState: GetState): Promise<Action> => {
    dispatch({
      type: ActionTypes.Submit
    });

    const loggedUser: LoggedUser | undefined = getState().app.loggedUser;
    if (!loggedUser) {
      return dispatch(fail('The user is not logged.'));
    }

    const sessionToken: string = loggedUser.sessionToken;

    try {
      await api.logOut(sessionToken);
    } catch (e) {
      return dispatch(fail(e.message))
    }

    return dispatch(confirm());
  };
}

function confirm(): ConfirmAction {
  return {
    type: ActionTypes.Confirm
  };
}

function fail(errorMessage: string): FailAction {
  return {
    type: ActionTypes.Fail,
    payload: {
      errorMessage
    }
  };
}

function mapEvents(dispatch: Dispatch): Events {
  return {
    onLogOutClick: (): void => {
      dispatch(submit());
    }
  };
}

export default connect(undefined, mapEvents)(View);
