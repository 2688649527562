import React, { useState } from 'react';
import TextInput from './TextInput';
import Icon, { IconSize } from './Icon';
import InputValidationText from './InputValidationText';

type Props = {
  value?: any[],
  placeholder?: string,
  onChange?(value: any[]): void,
  onAdd?(value: any): void,
  onRemove?(value: any): void,
  isInvalid?: boolean,
  fieldTitle: string
};

export default ({ value = [], placeholder = 'Enter a value', onChange, onAdd, onRemove, isInvalid, fieldTitle }: Props) => {
  const [ inputValue, setInputValue ] = useState('');

  return (
    <div className="multiple-select flex-grow-1">
      <div className={`flex-grow-1 input-group${isInvalid ? ' invalid' : ''}`}>
        <TextInput
          required
          value={inputValue}
          onKeyDown={(key: any): void => {
            if (key === 'Enter') {
              if (!value.includes(inputValue)) {
                onAdd && onAdd(inputValue);
                onChange && onChange([inputValue, ...value]);
                setInputValue('');
              }
            }
          }}
          onChange={(value: any): void => {
            setInputValue(value);
          }}
          placeholder={placeholder} />
        <div className="input-group-append">
          <button
            className="btn multiple-select-add-btn"
            type="button"
            onClick={() => {
              if (!value.includes(inputValue)) {
                onAdd && onAdd(inputValue);
                onChange && onChange([inputValue, ...value]);
                setInputValue('');
              }
            }}>
            <Icon className='multiple-select-plus-icon mr-2' icon='zmdi-plus' size={IconSize.LARGE} />
            <span>Add</span>
          </button>
        </div>
      </div>
      {isInvalid && <InputValidationText fieldName={fieldTitle} />}
      {value && value.length > 0 && (
        <div className="d-flex mt-3">
          <div className="selected-items-box flex-grow-1 alert alert-primary pl-2 pb-2 pr-2 pt-0 mb-0">
            {[...value].map((item: any, valueIndex: number): JSX.Element =>
              <button
                type="button"
                key={valueIndex}
                className="btn btn-light btn-sm shadow mr-2 mt-2"
                onClick={() => {
                  onRemove && onRemove(item);

                  const newValues = [...value];
                  newValues.splice(valueIndex, 1);
                  onChange && onChange(newValues);
                }}>
                {item}
                <span aria-hidden="true" className="ml-3">
                  <Icon icon='zmdi-close' />
                </span>
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
