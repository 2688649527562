import React, { CSSProperties } from 'react';
import Icon, { IconSize } from "./Icon";
import {ObjectSort, SortDirection, ObjectFilter, FilterOperator, ObjectFilterItem, Fields} from "../entities";
import { FieldType } from 'core';
import * as ObjectFilterUtils from '../utils/objectFilterUtils';
import Hover from './Hover';
import debounce from 'lodash.debounce'
import { noSortableTypes } from '../utils/objectSortUtils';

type State = {
  content: string
  objectFilterIndex?: number,
  objectSortIndex?: number,
  showIcon?: boolean
  additionalStyles?: CSSProperties,
  keepSorted?: boolean,
  keepFiltered?: boolean,
  objectSort?: ObjectSort,
  field?: string,
  objectFilter?: ObjectFilter,
  fields?: Fields
}

type UiState = {
  x: number,
  y: number,
  showHover: boolean
}

type Events = {
  onFilterButtonClick?: () => void,
  onSortChange?: (objectSort: ObjectSort, keepSorted: boolean) => void,
  onFilterChange?: (objectFilter: ObjectFilterItem[], keepFiltered: boolean) => void
}

type Props = State & Events

class TableHeader extends React.Component<Props, UiState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      x: 0,
      y: 0,
      showHover: false
    }
  }


  handleChangeSortItem(index: number, field: string, direction?: SortDirection) {
    const { objectSort, objectSortIndex = -1, onSortChange, keepSorted = false } = this.props;
    if (!objectSort || !onSortChange) {
      return;
    }
    if (objectSortIndex > -1) {
      const newObjectSort: ObjectSort = objectSort.concat();
      if (direction) {
        newObjectSort[index] = {
          field,
          direction
        };
      } else {
        newObjectSort.splice(index, 1);
      }
      onSortChange(newObjectSort, keepSorted);
    } else {
      onSortChange(
        objectSort.concat([{
            field,
            direction: SortDirection.Asc
          }]),
          keepSorted
      );
    }
  }

  handleFilterClick(fieldName: string): void {
    const { fields, objectFilter, onFilterButtonClick, onFilterChange, keepFiltered = false } = this.props;
    if (!fields || !objectFilter || !onFilterButtonClick || !onFilterChange) {
      return;
    }
    const newObjectFilter: ObjectFilter = objectFilter.concat({
      fieldName,
      operator: FilterOperator.Exists,
      values: []
    });
    const fieldType: FieldType = ObjectFilterUtils.getFieldType(fieldName, fields);
    const operator = ObjectFilterUtils.getAvailableOperators(
      newObjectFilter.length - 1,
      fieldName,
      fieldType,
      objectFilter
    )[0];
    if (operator) {
      newObjectFilter[newObjectFilter.length - 1].operator = operator
      newObjectFilter[newObjectFilter.length - 1].values = ObjectFilterUtils.getDefaultOperatorValues(newObjectFilter[newObjectFilter.length - 1].operator, fieldType);
      onFilterChange(
        newObjectFilter,
        keepFiltered
      );
    }
    onFilterButtonClick();
  }

  setHoverPosition(e: any, ref: any) {
    const position = ref.current && ref.current.getBoundingClientRect() as DOMRect;
    if (position) return this.setState({ x: position.x, y: position.y + 30, showHover: true });
  }

  handleMouseOver = debounce(this.setHoverPosition, 500);

  render () {
    const {
      content,
      additionalStyles = {},
      objectFilterIndex = -1,
      objectSortIndex = -1,
      showIcon = true,
      objectSort,
      keepSorted,
      field,
      fields,
      keepFiltered
    } = this.props;
    const isIconEnabled = keepFiltered && objectFilterIndex > -1 || keepSorted && objectSortIndex > -1;
    const isSortAsc = objectSortIndex > -1 && objectSort && objectSort[objectSortIndex].direction === SortDirection.Asc;
    const isSortDesc = objectSortIndex > -1 && objectSort && objectSort[objectSortIndex].direction === SortDirection.Desc;
    const ref = React.createRef<HTMLTableCellElement>()
    const fieldType: FieldType | undefined = fields && field ? ObjectFilterUtils.getFieldType(field, fields) : undefined;

    // Set cursor to default when a header doesn't have filter or sort options
    if (!showIcon) additionalStyles.cursor = 'default'

    return (
      <th ref={ref} className='table-header' scope="col" style={additionalStyles} onMouseLeave={() => this.setState({ showHover: false })}>
        <div className={"header-content"} data-toggle={showIcon ? "dropdown" : ''} role="button" onClick={() => this.setState({ showHover: false })}>
          <span onMouseOver={e => this.handleMouseOver(e, ref)}>{content}</span>
          {showIcon && <Icon icon="zmdi-filter-list" className={isIconEnabled ? "icon-enabled" : "icon-disabled"} />}
        </div>
        <Hover content={content} show={this.state.showHover} x={this.state.x} y={this.state.y} showCopyIcon={false} />
        <ul className="dropdown-menu dropdown-menu" >
          {fieldType && !noSortableTypes.includes(fieldType) &&
            <>
              <li className="dropdown-item" onClick={field && (() => this.handleChangeSortItem(objectSortIndex, field, SortDirection.Asc)) || undefined}>
              {<Icon icon={`zmdi-sort-amount-asc ${keepSorted && objectSortIndex > -1 && isSortAsc ? "icon-enabled" : "icon-disabled"}`}/>}{<span> {"Sort A "}{<Icon icon={"zmdi-arrow-right"}/>}{ " Z"}</span>}
              </li>
              <li className="dropdown-item" onClick={field && (() => this.handleChangeSortItem(objectSortIndex, field, SortDirection.Desc)) || undefined}>
                {<Icon icon={`zmdi-sort-amount-desc ${keepSorted && objectSortIndex > -1 && isSortDesc ? "icon-enabled" : "icon-disabled"}`}/>}{<span> {"Sort Z "}{<Icon icon={"zmdi-arrow-right"}/>}{ " A"}</span>}
              </li>
              <li className="dropdown-item" onClick={field && (() => this.handleChangeSortItem(objectSortIndex, field)) || undefined}>
                <Icon icon={`zmdi-menu ${keepSorted && objectSortIndex === -1 ? "icon-enabled" : "icon-disabled"}`}/>
                <span> None</span>
              </li>
              <li className="dropdown-divider"></li>
            </>
          }
          <li className={`dropdown-item ${keepFiltered && objectFilterIndex > -1 ? "icon-enabled" : "icon-disabled"}`} onClick={() => field && this.handleFilterClick(field)}>
            {<Icon icon="fas fa-filter" size={IconSize.XSMALL} isMaterial={false}/>}{<span> Add filter</span>}
          </li>
        </ul>
      </th>
    );
  }
};

export default TableHeader
