import React from 'react';
import Title from './Title';

export default function NotFound(): JSX.Element {
  return (
    <>
      <Title />
      <div className="container">
        <div className="row mt-5">
          <div className="col-md-3"></div>
          <div className="col-12 col-md-6">
            <div className="container-fluid text-center">
              <h1>Oops!</h1>
              <h2 className="mt-4">Not found in menu</h2>
            </div>
          </div>
          <div className="col-md-3"></div>
        </div>
      </div>
    </>    
  );
}
