import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import * as api from '../api';
import Title from './Title';
import { Link } from 'react-router-dom';
import withAnalytics, { AnalyticsProps } from '../hoc/withAnalytics';
import { notify, NotificationColorTheme } from './Notification';

type OwnState = {
  isSubmitting: boolean,
  errorMessage: string
};

type State = OwnState & {
  sessionToken?: string,
  className: string,
  objectIds: string[],
  originalTitle: string,
  title: string
};

type Events = {
  onComponentDidMount: () => void,
  onComponentDidUpdate: (prevProps: Props, props: Props) => void,
  onComponentWillUnmount: () => void
  onButtonClick: () => void
};

type Props = State & Events & AnalyticsProps;

class View extends PureComponent<Props> {
  componentDidMount(): void {
    this.props.onComponentDidMount();
  }

  componentDidUpdate(prevProps: Props) {
    this.props.onComponentDidUpdate(prevProps, this.props);
  }

  componentWillUnmount(): void {
    this.props.onComponentWillUnmount();
  }

  handleDeleteButtonClick(): void {
    this.props.trackEvent('Object Deleted', { class: this.props.className, count: this.props.objectIds.length })
    this.props.onButtonClick();
  }

  render(): JSX.Element {
    return (
      <>
        <Title title={this.props.title} />
        <div className="delete-objects-container container-fluid class-table">
          <h5>{this.props.title}</h5>
          <hr />
          <div className="default-padding-left message-container">
            Are you sure to delete the following objects? You will not be able to revert this action.
          </div>
          <div className="row default-padding-left mt-3">
            <div className="col-12">
              <div className="objects-container pt-2 pl-2 pr-2 pb-2">
                {
                  this.props.objectIds.map((id: string): string => {
                    return this.props.originalTitle + ' ' + id;
                  }).join(' - ')
                }
              </div>
              <div className="mt-2">
                {this.props.errorMessage &&
                  <div
                    className="alert alert-danger"
                    role="alert">
                    {this.props.errorMessage}
                  </div>
                }
              </div>
            </div>
          </div>
          <hr />
          <div className="row mt-4 mb-3">
            <div className="col-12">
              <button
                type="button"
                className="btn btn-small btn-secondary btn-danger float-right"
                disabled={this.props.isSubmitting}
                onClick={this.handleDeleteButtonClick.bind(this)}>
                <div>
                  <i className={`mr-2 ${this.props.isSubmitting ? 'fas fa-spinner fa-spin' : 'mr-2 zmdi zmdi-delete zmdi-hc-lg'}`}></i>
                  {this.props.isSubmitting ? 'Deleting' : 'Delete'}
                </div>
              </button>
              {!this.props.isSubmitting &&
                <Link to={`/objects/${this.props.className}`} className="btn btn-small btn-primary float-right mr-2">
                  <i className="zmdi zmdi-close zmdi-hc-lg mr-2"></i>
                  Cancel
                </Link>
              }
            </div>
          </div>
        </div>
      </>
    );
  }
}

type OwnProps = {
  className: string,
  title: string,
  objectIds: string[],
  redirect: (to: string) => void
};

type RootState = {
  app: {
    loggedUser?: { sessionToken: string }
  },
  deleteObjects: OwnState
};

function mapState(rootState: RootState, ownProps: OwnProps): State {
  return {
    sessionToken: rootState.app.loggedUser ? rootState.app.loggedUser.sessionToken : undefined,
    className: ownProps.className,
    objectIds: ownProps.objectIds,
    originalTitle: ownProps.title,
    title: `Delete ${ownProps.title} Objects`,
    isSubmitting: rootState.deleteObjects.isSubmitting,
    errorMessage: rootState.deleteObjects.errorMessage
  };
}

export enum ActionTypes {
  Submit = 'DELETE_OBJECTS.SUBMIT',
  Confirm = 'DELETE_OBJECTS.CONFIRM',
  Fail = 'DELETE_OBJECTS.FAIL',
  Reset = 'DELETE_OBJECTS.RESET'
};

type SubmitAction = {
  type: ActionTypes.Submit,
  payload: {
    className: string,
    objectIds: string[]
  }
}

type ConfirmAction = {
  type: ActionTypes.Confirm,
  payload: {
    className: string,
    objectIds: string[]
  }
}

type FailAction = {
  type: ActionTypes.Fail,
  payload: {
    errorMessage: string
  }
}

type ResetAction = {
  type: ActionTypes.Reset
}

export type Action =
  SubmitAction |
  ConfirmAction |
  FailAction |
  ResetAction;

type Dispatch = any;

type GetState = () => RootState;

type Thunk = (dispatch: Dispatch, getState: GetState) => Promise<Action>;

function submit(className: string, objectIds: string[], redirect: (to: string) => void): Thunk {
  return async (dispatch: Dispatch, getState: GetState): Promise<Action> => {
    dispatch({
      type: ActionTypes.Submit,
      payload: {
        className,
        objectIds
      }
    });

    const state: State = mapState(getState(), { className, title: className, objectIds, redirect });

    if (!state.sessionToken) {
      return dispatch(fail('User is not logged in.'));
    }

    let deletedObjectIds: string[];
    try {
      deletedObjectIds = await api.deleteObjects(state.sessionToken, className, objectIds);
    } catch (e) {
      return dispatch(fail(e.message))
    }

    redirect(`/objects/${className}`);

    const deletedObjectLength = deletedObjectIds && deletedObjectIds.length;

    if (deletedObjectLength)
      notify('fa-trash', `${deletedObjectLength} ${deletedObjectLength > 1 ? 'objects' : 'object' } deleted with success`, undefined, NotificationColorTheme.DANGER);
    return dispatch(confirm(className, deletedObjectIds));
  };
}

function confirm(className: string, objectIds: string[]): ConfirmAction {
  return {
    type: ActionTypes.Confirm,
    payload: {
      className,
      objectIds
    }
  };
}

function fail(errorMessage: string): FailAction {
  return {
    type: ActionTypes.Fail,
    payload: {
      errorMessage
    }
  };
}

function reset(): ResetAction {
  return {
    type: ActionTypes.Reset
  };
}

function mapEvents(dispatch: Dispatch, ownProps: OwnProps): Events {
  return {
    onComponentDidMount(): void {
      dispatch(reset());
    },
    onComponentDidUpdate(prevProps: Props): void {
      if (prevProps.className !== ownProps.className || prevProps.objectIds !== ownProps.objectIds ) {
        dispatch(reset());
      }
    },
    onComponentWillUnmount(): void {
      dispatch(reset());
    },
    onButtonClick: (): void => {
      dispatch(submit(ownProps.className, ownProps.objectIds, ownProps.redirect));
    }
  };
}

export default withAnalytics(connect(mapState, mapEvents)(View));

const INITIAL_STATE: OwnState = {
  isSubmitting: false,
  errorMessage: ''
};

export function reducer(state: OwnState = INITIAL_STATE, action: Action): OwnState {
  switch (action.type) {
    case ActionTypes.Submit:
      return Object.assign(
        {},
        state,
        {
          isSubmitting: true,
          errorMessage: ''
        }
      );

    case ActionTypes.Confirm:
      return INITIAL_STATE;

    case ActionTypes.Fail:
      return Object.assign(
        {},
        state,
        {
          isSubmitting: false,
          errorMessage: action.payload.errorMessage
        }
      );

    case ActionTypes.Reset:
      return INITIAL_STATE;
  }

  return state;
};
