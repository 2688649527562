import React from 'react';
import { connect } from 'react-redux';
import DocumentTitle from 'react-document-title';

type State = {
  appName: string
};

type Props = State & {
  title?: string
};

function View(props: Props): JSX.Element {
  const { title, appName } = props;
  return (
    <>
      <DocumentTitle title={title ? `${title} | ${appName}` : appName} />
      <div className="col text-center header">
        <h3 className='table-main-title'>{title}</h3>
      </div>
    </>
  );
}

type RootState = { app: { settings: { appName: string } } };

function mapState(rootState: RootState): State {
  return {
    appName: rootState.app.settings.appName
  };
}

export default connect(mapState)(View);
