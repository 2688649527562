import React from 'react';
import Icon from './Icon';
import { toast, ToastContainer } from 'react-toastify'

export enum NotificationColorTheme {
  DEFAULT = 'primary',
  DANGER = 'danger',
  SUCCESS = 'success'
}

const Notification = () => <ToastContainer />

export function notify(icon: string, message: string, duration?: number, colorTheme?: NotificationColorTheme ): void {
  const notificationContent = <span><Icon icon={icon} isMaterial={icon.indexOf('zmdi') > -1} /> {message}</span>

  toast(notificationContent, {
    className: `admin-notification ${colorTheme || ""}`,
    position: "top-right",
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    autoClose: duration || 2500
  });
}

export default Notification;
