import React, { FunctionComponent, ComponentClass } from 'react';
import { ConnectedComponentClass } from 'react-redux';
import PropTypes from 'prop-types';

export type AnalyticsProps = {
  trackEvent(eventType: string, eventProperties?: object, b4aEvent?: boolean) : void,
  trackLogin(adminUsername: string): void
};

const withAnalytics = (Component: ConnectedComponentClass<any, any> | FunctionComponent | ComponentClass) => {
  const analyticsProvider = (props: any, context: any) => {
    const { trackEvent, trackLogin } = context;
    const propsWithTracking = Object.assign({}, props, { trackEvent, trackLogin });
    return React.createElement(Component, propsWithTracking);
  };
  analyticsProvider.displayName = 'Analytics HOC';
  analyticsProvider.contextTypes = {
    trackEvent: PropTypes.func.isRequired,
    trackLogin: PropTypes.func.isRequired
  };

  return analyticsProvider;
}
export default withAnalytics;
