import amplitude, { AmplitudeClient, Config } from 'amplitude-js';
import mixpanel, { Mixpanel } from 'mixpanel-browser';
import packageJson from '../package.json';

// Fixes the abstence of unsetParamsReferrerOnNewSession on the imported type
type ExtendedConfig = Config & {
  unsetParamsReferrerOnNewSession: boolean
}

type ExtendedAmplitude = AmplitudeClient & {
  init(apiKey: string, userId?: string, config?: ExtendedConfig, callback?: (client: AmplitudeClient) => void): void;
}

const amplitudeAdminClient : ExtendedAmplitude = amplitude.getInstance('admin');
amplitudeAdminClient.init('99e7268778e2bd947bce9e6acbdeb2ec', undefined, { includeUtm: true, includeReferrer: true, includeGclid: true, unsetParamsReferrerOnNewSession: true, saveParamsReferrerOncePerSession: false });
amplitudeAdminClient.setVersionName(packageJson.version);
amplitudeAdminClient.regenerateDeviceId();

const amplitudeBack4appClient : ExtendedAmplitude = amplitude.getInstance('back4app');
amplitudeBack4appClient.init("bf3379918c21ddbc0c99a9a8c992d1b1", undefined, { includeUtm: true, includeReferrer: true, includeGclid: true, unsetParamsReferrerOnNewSession: true, saveParamsReferrerOncePerSession: false });
amplitudeBack4appClient.setVersionName(packageJson.version);

mixpanel.init('77b4db719341d042a729a1ffcfff1e83', {}, 'admin');
mixpanel.init('c6a824c901de2d494f8f060d6753e1ae', {}, 'back4app');

const mixpanelAdminClient : Mixpanel = (mixpanel as any).admin;
const mixpanelBack4appClient : Mixpanel = (mixpanel as any).back4app;

type Event = {
  eventType: string,
  eventProperties?: object,
  b4aEvent?: boolean
}

export interface CustomWindow extends Window {
  SERVER_URL: string;
};
declare let window: CustomWindow;
const isLocal: boolean = window.SERVER_URL === '%REACT_APP_SERVER_URL%';

export default class Analytics {
  initialized: boolean;
  adminInitialized: boolean;
  appId: string | undefined;
  eventQueue: Array<Event>;
  ownerId: string | undefined;
  adminUserId: string | undefined;

  constructor() {
    this.initialized = false;
    this.adminInitialized = false;
    this.eventQueue = [];

    this.initialize = this.initialize.bind(this);
    this.trackLogin = this.trackLogin.bind(this);
    this.trackEvent = this.trackEvent.bind(this);
  }

  initialize(appId: string, userId: string): void {
    this.appId = appId;
    mixpanelBack4appClient.identify(userId);
    amplitudeBack4appClient.setUserId(userId);
    this.initialized = true;
    this.ownerId = userId;

    this.trackEvent('Admin App Started', {}, true);

    // Consume only b4a events
    this.eventQueue = this.eventQueue.filter(({ eventType, eventProperties, b4aEvent }) => {
      if (b4aEvent) {
        this.trackEvent(eventType, eventProperties, b4aEvent);
      }
      return !b4aEvent;
    });
  }

  trackLogin(adminUsername: string): void {
    // Resets all configurations (if any)
    mixpanelAdminClient.reset();
    amplitudeAdminClient.clearUserProperties();
    amplitudeAdminClient.setUserId('');
    amplitudeAdminClient.regenerateDeviceId();

    this.adminUserId = `${this.appId}_${adminUsername}`;
    mixpanelAdminClient.identify(this.adminUserId);
    amplitudeAdminClient.setUserId(this.adminUserId);
    this.adminInitialized = true;

    this.trackEvent('Admin User Logged In');

    // At this moment both clients must be initialized, we can consume all stashed events
    this.eventQueue = this.eventQueue.filter(({ eventType, eventProperties, b4aEvent }) => {
      this.trackEvent(eventType, eventProperties, b4aEvent);
      return false;
    });
  }

  trackEvent(eventType: string, eventProperties?: object, b4aEvent?: boolean) : void {
    const defaultProps: any = {
      appId: this.appId,
      ownerId: this.ownerId
    };
    if (!b4aEvent) {
      defaultProps.adminUserId = this.adminUserId;
    }
    const trackingProps = Object.assign({}, eventProperties, defaultProps);
    if (isLocal) {
      console.log("LOGGING EVENT", eventType, JSON.stringify(trackingProps), `isB4aEvent? ${b4aEvent}`);
      return;
    }
    if (b4aEvent && this.initialized) {
      amplitudeBack4appClient.logEvent(eventType, trackingProps);
      mixpanelBack4appClient.track(eventType, trackingProps);
    } else if (!b4aEvent && this.adminInitialized) {
      amplitudeAdminClient.logEvent(eventType, trackingProps);
      mixpanelAdminClient.track(eventType, trackingProps);
    } else {
      this.eventQueue.push({
        eventType,
        eventProperties,
        b4aEvent
      });
    }
  }
};
