import Analytics from '../analytics';
import * as api from '../api';
import { Dispatch } from '../App';
import * as cache from '../cache';
import { ParseApp } from 'core';
import PropTypes from 'prop-types';
import React, { Children, PureComponent, Props } from 'react';
import { connect } from 'react-redux';
import { Store } from 'redux';
import { LoggedUser } from '../entities';

enum ActionTypes {
  LoadParseApp = 'APP.LOAD_PARSE_APP'
};

type RootState = {
  analytics: AnalyticsProviderState
}

type GetRootState = () => RootState;

type AnalyticsProviderState = {
  parseApp: ParseApp | undefined
}

type AnalyticsProviderProps = {
  onComponentDidMount: Function,
  parseApp: ParseApp | undefined
}

type Thunk = (dispatch: Dispatch, getState: GetRootState) => Promise<LoadParseAppAction>

type LoadParseAppAction = {
  type: ActionTypes.LoadParseApp,
  payload: ParseApp
}

const cachedParseApp: ParseApp | undefined = cache.getParseApp();
const cachedLoggedUser: LoggedUser | undefined = cache.getLoggedUser();

class AnalyticsProvider extends PureComponent<AnalyticsProviderProps> {
  state: AnalyticsProviderState;
  tracker: Analytics;

  static childContextTypes = {
    trackEvent: PropTypes.func.isRequired,
    trackLogin: PropTypes.func.isRequired
  }

  constructor(props: AnalyticsProviderProps, context: any) {
    super(props, context);
    this.state = { parseApp: cachedParseApp };
    this.tracker = new Analytics();
  }

  componentDidMount() {
    this.props.onComponentDidMount();
  }

  componentWillReceiveProps(nextProps: AnalyticsProviderProps) {
    const { parseApp } = this.state;
    if (!parseApp && nextProps.parseApp && nextProps.parseApp.appId && nextProps.parseApp !== parseApp) {
      const { appId, ownerId } = nextProps.parseApp;
      this.tracker.initialize(appId, ownerId);
      if (cachedLoggedUser) {
        this.tracker.trackLogin(cachedLoggedUser.username);
      }
      this.setState({ parseApp: nextProps.parseApp })
    }
  }

  getChildContext() {
    return {
      trackEvent: this.tracker.trackEvent,
      trackLogin: this.tracker.trackLogin
    };
  }

  render() {
    return Children.only(this.props.children);
  }
};

function loadParseApp(): Thunk {
  return async (dispatch: Dispatch, getState: GetRootState): Promise<LoadParseAppAction> => {
    const state: AnalyticsProviderState = getState().analytics;
    let payload: ParseApp | Error, error: boolean = false;
    if (state.parseApp && state.parseApp.appId) {
      payload = state.parseApp;
    } else {
      try {
        payload = await api.parseApp();
      } catch (e) {
        await (new Promise((resolve: () => void): void => {
          setTimeout(() => {
            resolve();
          }, 5000);
        }));
        return dispatch(loadParseApp());
      }
    }
    return dispatch({
      type: ActionTypes.LoadParseApp,
      payload
    });
  };
};

function mapState(rootState: RootState): AnalyticsProviderState {
  return rootState.analytics;
}

function mapEvents(dispatch: Dispatch) {
  return {
    onComponentDidMount(): void {
      dispatch(loadParseApp());
    }
  };
}

export default connect(mapState, mapEvents)(AnalyticsProvider);

export function reducer(
  state: AnalyticsProviderState = {
    parseApp: cachedParseApp || { appId: '', ownerId: '' }
  },
  action: LoadParseAppAction
): AnalyticsProviderState {
  switch (action.type) {
    case ActionTypes.LoadParseApp:
      return Object.assign(
        {},
        state,
        { parseApp: action.payload }
      );
  }
  return state;
};


export function subscriber(store: Store): void {
  const state: RootState = store.getState() as RootState;
  const parseApp: ParseApp | undefined = state.analytics.parseApp;
  if (parseApp && parseApp.appId && parseApp.ownerId) {
    cache.setParseApp(parseApp);
  }
}
