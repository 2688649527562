import React, { PureComponent, RefObject } from 'react';
import ReactDOM from 'react-dom';
import editor, { JSONEditorOptions } from 'jsoneditor';
import 'jsoneditor/dist/jsoneditor.min.css';
import InputValidationText from './InputValidationText';
import { GeoPoint } from './GeoPointInput';

type JSONError = {
  path: string[],
  message: string
}

type Props = {
  value: JSON | GeoPoint[] | undefined,
  onChange(value: JSON | null): void,
  readOnly: boolean,
  isInvalid: boolean,
  fieldTitle: string,
  handleOnChange?(value: string): void,
};

export default class JSONEditor extends PureComponent<Props> {
  containerRef: RefObject<HTMLDivElement>=React.createRef();

  componentDidMount(): void {
    const options = {
      mode: this.props.readOnly ? 'view' : 'code',
      onChangeText: (content: string) => {
        if (this.props.handleOnChange) return this.props.handleOnChange(content);
        if (content === '') {
          this.props.onChange(null);
        }
      },
      onValidate: (json: JSON) => {
        if (!this.props.handleOnChange) return this.props.onChange(json);
      }
    };
    const { value } = this.props;
    const jsonEditor = new editor(
      this.containerRef.current as HTMLDivElement,
      options as JSONEditorOptions,
      value
    );
    if (value === undefined || value === null) {
      // Forces the editor to set an empty value
      jsonEditor.setText('');
    }
  }

  componentWillUnmount(): void {
    ReactDOM.unmountComponentAtNode(this.containerRef.current as Element);
    if (this.containerRef && this.containerRef.current) {
      const parentNode = (this.containerRef.current).parentNode;
      if (parentNode) {
        parentNode.removeChild(this.containerRef.current);
      }
    }
  }

  render(): JSX.Element {
    const { isInvalid, fieldTitle } = this.props;
    return (
      <>
        <div ref={this.containerRef} className={isInvalid ? 'json-editor is-invalid' : ''}></div>
        { isInvalid ? <InputValidationText fieldName={fieldTitle} /> : null }
      </>
    );
  }
}
