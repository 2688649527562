import { CustomField, ObjectClassField } from "core";

export type User = {
  username: string
};

export type LoggedUser = User & {
  sessionToken: string
};

export type CustomFields = {
  byObjectClassName: {
    [objectClassName: string]: {
      [objectClassFieldName: string]: CustomField
    }
  },
  byId: {
    [id: string]: CustomField
  }
};

export type Fields = {
  [objectClassFieldName: string]: {
    objectClassField?: ObjectClassField,
    customField?: CustomField
  }
};

export type ObjectEntity = {
  [fieldName: string]: any
};

export enum FilterOperator {
  Exists = 'EXISTS',
  DoesNotExist = 'DOES_NOT_EXIST',
  EqualsTo = 'EQUALS_TO',
  DoesNotEqualTo = 'DOES_NOT_EQUAL_TO',
  ContainedIn = 'CONTAINED_IN',
  NotContainedIn = 'NOT_CONTAINED_IN',
  StartsWith = 'STARTS_WITH',
  EndsWith = 'ENDS_WITH',
  Contains = 'CONTAINS',
  ContainsAll = 'CONTAINS_ALL',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO'
};

export type ObjectFilterItem = {
  fieldName: string,
  operator: FilterOperator,
  values: any[]
};

export type ObjectFilter = ObjectFilterItem[];

export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
};

export type ObjectSortItem = {
  field: string,
  direction: SortDirection
};

export type ObjectSort = ObjectSortItem[];

export type UIObjects = {
  className?: string,
  objectFilter: ObjectFilter,
  keepFiltered: boolean,
  objectSort: ObjectSort,
  keepSorted: boolean,
  pageLimit: number,
  pagesIds: string[][],
  objectsById: {
    [id: string]: ObjectEntity
  },
  fullTextSearchQueryValue: string
};

export type UIsObjects = { [uniqueId: string]: UIObjects };

export type ParseApp = {
  appId: string,
  ownerId: string
}
