import React, { PureComponent, ChangeEvent } from 'react';
import { connect } from 'react-redux';
import Title from './Title';
import {
  Action as LogOutAction,
  ActionTypes as LogOutActionTypes
} from './LogOut';
import {
  Action as ObjectFormAction,
  ActionTypes as ObjectFormActionTypes
} from './ObjectForm';
import {
  Action as DeleteObjectsAction,
  ActionTypes as DeleteObjectsActionTypes
} from './DeleteObjects';
import { CustomField, ObjectClass, ObjectClassField, Event, FieldType, FieldSubType, MenuItem, Relation, PointerReference } from 'core';
import { CustomFields, ObjectEntity, Fields, ObjectFilter, ObjectSort, ObjectFilterItem, UIObjects, UIsObjects, SortDirection, ObjectSortItem, FilterOperator } from '../entities';
import * as cache from '../cache';
import * as api from '../api';
import { Store } from 'redux';
import { Link } from 'react-router-dom';
import { generateSortFieldsFunction, checkFilter } from '../util';
import ObjectsRow from './ObjectsRow';
import ObjectsSortModal from './ObjectsSortModal';
import ObjectsFilterModal from './ObjectsFilterModal';
import Icon, { IconSize } from './Icon';
import Checkbox from './Checkbox';
import TableHeader from './TableHeader'
import { ShowNetworkErrorNotification } from '../App'
import Loader from './Loader';
import DocumentTitle from 'react-document-title';
import { getFieldTitle } from '../utils/objectFilterUtils';
import FullTextSearchInput from './FullTextSearchInput';

const DEFAULT_FIELDS: string[] = [ 'id', 'objectId', 'createdAt', 'updatedAt' ];
const MAX_SHOWING_PAGES: number = 10;
const DEFAULT_SORT: ObjectSortItem[] = [{ field: 'updatedAt', direction: SortDirection.Desc }]

type OwnState = {
  objectFilter: ObjectFilter,
  keepFiltered: boolean,
  isFilterModalVisible: boolean,
  objectSort: ObjectSort,
  keepSorted: boolean,
  isSortModalVisible: boolean,
  pageLimit: number,
  pageIndex: number,
  objectIds: string[],
  totalPages: number,
  selectedIds: {
    [id: string]: boolean
  },
  isAllSelected?: boolean,
  relations: Relation[],
  showImages: boolean,
  fullTextSearchInputValue: string,
  fullTextSearchQueryValue: string
};

type State = OwnState & {
  uniqueID: string,
  sessionToken?: string,
  className: string,
  title: string,
  objectClass: ObjectClass,
  customFields: CustomFields,
  contexts: ObjectsContext[],
  contextFilter: ObjectFilter,
  menuItems: MenuItem[],
  objectsFetched: boolean,
  customizeDefaultFields: boolean
};

type Events = {
  onComponentDidMount: () => void,
  onComponentDidUpdate: (prevProps: Props, props: Props) => void,
  onComponentWillUnmount: (uniqueID: string) => void,
  onRowSelectChange: (uniqueID: string, objectId: string, isSelected: boolean) => void,
  onPageIndexChange: (props: Props, pageIndex: number) => void,
  onSortButtonClick: (uniqueID: string) => void,
  onSortCloseButtonClick: (uniqueID: string) => void,
  onSortChange: (props: Props, objectSort: ObjectSort, keepSorted: boolean) => void,
  onFilterButtonClick: (uniqueID: string) => void,
  onFilterCloseButtonClick: (uniqueID: string) => void,
  onFilterChange: (props: Props, objectFilter: ObjectFilter, keepFiltered: boolean) => void,
  onInputSelectAllChange: (uniqueID: string, event: ChangeEvent<HTMLInputElement>) => void,
  onChangeImagesExhibitionMode: (uniqueID: string) => void,
  onChangeFullTextSearchInputValue: (uniqueID: string, fullTextSearchInputValue: string) => void,
  onSubmitFullTextSearch: (props: Props) => void
};

type Props = State & Events;

function getFields(objectClass: ObjectClass, customFields: { [objectClassFieldName: string]: CustomField }): Fields {
  const fields: Fields = {};
  for (const objectClassFieldName in customFields) {
    if (objectClass.fields.filter(({ name }) => name === objectClassFieldName).length > 0) {
      fields[objectClassFieldName] = {
        customField: customFields[objectClassFieldName]
      };
    }
  }
  objectClass.fields.forEach((objectClassField: ObjectClassField): void => {
    fields[objectClassField.name] = Object.assign(
      {},
      fields[objectClassField.name],
      {
        objectClassField
      }
    );
  });
  return fields;
}

class View extends PureComponent<Props> {
  componentDidMount(): void {
    this.props.onComponentDidMount();
  }

  componentDidUpdate(prevProps: Props) {
    this.props.onComponentDidUpdate(prevProps, this.props);
  }

  componentWillUnmount(): void {
    this.props.onComponentWillUnmount(this.props.uniqueID);
  }

  getObjectFilterIndex(field: string): number {
    return this.props.objectFilter.findIndex(e => e.fieldName === field);
  }

  getObjectSortIndex(field: string): number {
    return this.props.objectSort.findIndex(e => e.field === field);
  }

  renderEditButton(isEditDisabled: boolean, selectedId: string): JSX.Element {
    if (isEditDisabled) {
      return <></>;
    }
    return (
      <Link to={isEditDisabled && null || `/objects/${this.props.className}/${selectedId}`} className={`btn btn-primary mr-2 ${isEditDisabled && 'disabled'}`}>
        <Icon icon="fa-pen" isMaterial={false}></Icon>
        <span className='d-none d-sm-inline'> Edit</span>
      </Link>
    );
  }

  renderDeleteButton(isDeleteDisabled: boolean, selectedIds: Array<string>): JSX.Element {
    if (isDeleteDisabled) {
      return <></>;
    }
    return (
      <Link to={isDeleteDisabled && null || { pathname: `/objects/${this.props.className}/Delete`, state: { objectIds: selectedIds } }} className={`btn btn-danger mr-2 ${isDeleteDisabled && 'disabled'}`}>
        <Icon icon="fa-trash" isMaterial={false}></Icon>
        <span className='d-none d-sm-inline'> Delete</span>
      </Link>
    );
  }

  renderTitle(): JSX.Element | null {
    const { contexts, title } = this.props;
    if (contexts && contexts.length) {
      const { className, fieldName, objectIds, relations, parentClassName, refererTitle } = contexts[contexts.length -1];
      if (refererTitle) return <Title title={refererTitle} />;

      const titleItems = [];
      const breadcrumbs = contexts.map((context) => {
        const { uniqueID, objectId, parentClassName } = context;

        const menuItem = this.props.menuItems.find(menuItem => menuItem.objectClassName === parentClassName);
        const classTitle = menuItem ? menuItem.title : parentClassName.startsWith('_') ? parentClassName.slice(1) : parentClassName;
        titleItems.push(classTitle);
        return <li key={uniqueID} className='mr-2'>
          <Link className='relation-link' to={`/objects/${parentClassName}/${objectId}`}>
            <span>{classTitle}</span>
            <span className='object-id mr-2'> ({objectId})</span>
          </Link>
          <span>►</span>
        </li>
      });
      let classTitle;
      if (objectIds && objectIds.length || relations && relations.length) {
        const fields = this.props.customFields.byObjectClassName[parentClassName];
        classTitle = (fields && fields[fieldName] && fields[fieldName].title) || fieldName;
      } else {
        const menuItem = this.props.menuItems.find(menuItem => menuItem.objectClassName === className);
        classTitle = menuItem ? menuItem.title : className.startsWith('_') ? className.slice(1) : className;
        classTitle += ' References';
      }
      breadcrumbs.push(
        <li key={fieldName} className='current-context'>{classTitle}</li>
      )
      titleItems.push(fieldName);
      return <>
        <DocumentTitle title={titleItems.join(' ► ')} />
        <ul className='breadcrumbs d-flex justify-content-center header'>{breadcrumbs}</ul>
      </>;
    }
    return <Title title={title} />;
  }

  renderEllipsisButton(): JSX.Element {
    const isFilterIconEnabled = this.props.keepFiltered && this.props.objectFilter.length > 0
    const isSortIconEnabled = this.props.keepSorted && this.props.objectSort.length > 0

    return (
      <>
        <i className="ellipsis-button" data-toggle="dropdown" role="button" ><Icon icon="fa-ellipsis-v" isMaterial={false} /></i>
        <ul className="dropdown-menu dropdown-menu ellipsis-dropdown" >
          <li className={`ellipsis-sort-filter ${isFilterIconEnabled ? "icon-enabled" : "icon-disabled"}`} onClick={() => this.props.onFilterButtonClick(this.props.uniqueID)}>
            <Icon icon="fa-filter" isMaterial={false}></Icon>
            <span> Filter ({this.props.keepFiltered ? this.props.objectFilter.length : 0})</span>
          </li>
          <li className={`ellipsis-sort-filter ${isSortIconEnabled ? "icon-enabled" : "icon-disabled"}`} onClick={() => this.props.onSortButtonClick(this.props.uniqueID)}>
            <Icon icon="fa-exchange-alt" className="fa-rotate-90"  isMaterial={false}></Icon>
            <span> Sort ({this.props.keepSorted ? this.props.objectSort.length : 0})</span>
          </li>
          <li onClick={() => this.props.onChangeImagesExhibitionMode(this.props.uniqueID)}>
            <Checkbox id='objects-select-all' onChange={(event: ChangeEvent<HTMLInputElement>) => this.props.onChangeImagesExhibitionMode(this.props.uniqueID)} checked={this.props.showImages || false} />
            <span>Show Images</span>
          </li>
        </ul>
      </>
    )
  }

  onFilterButtonClick = () => this.props.onFilterButtonClick(this.props.uniqueID);
  onFilterCloseButtonClick = () => this.props.onFilterCloseButtonClick(this.props.uniqueID);
  onFilterChange = (objectFilter: ObjectFilterItem[], keepFiltered: boolean) => this.props.onFilterChange(this.props, objectFilter, keepFiltered);

  onSortButtonClick = () => this.props.onSortButtonClick(this.props.uniqueID);
  onSortCloseButtonClick = () => this.props.onSortCloseButtonClick(this.props.uniqueID);
  onSortChange = (objectSort: ObjectSort, keepSorted: boolean) => this.props.onSortChange(this.props, objectSort, keepSorted);

  render(): JSX.Element {
    const fields: Fields = getFields(this.props.objectClass, this.props.customFields.byObjectClassName[this.props.className]);
    const selectedIds: string[] = Object.keys(this.props.selectedIds).filter((selectedId: string): boolean => { return this.props.selectedIds[selectedId]; });
    const isDeleteDisabled: boolean = !(selectedIds.length > 0);
    const isEditDisabled: boolean = !(selectedIds.length === 1);
    const menuItem: MenuItem | undefined = this.props.menuItems.find(menuItem => menuItem.objectClassName === this.props.className);
    const isReadOnly : boolean = menuItem && menuItem.isReadOnly || false;


    const dynamicFieldNames: string[] = Object.keys(fields)
      .filter((objectClassFieldName: string): boolean => {
        return (!DEFAULT_FIELDS.includes(objectClassFieldName) || this.props.customizeDefaultFields) && (
          !fields[objectClassFieldName].customField || (
            (!(fields[objectClassFieldName].customField as CustomField).subType || ((fields[objectClassFieldName].customField as CustomField).subType as FieldSubType) !== FieldSubType.Password) &&
            (fields[objectClassFieldName].customField as CustomField).isTableHidden === false
          )
        );
      })
      .sort(generateSortFieldsFunction(fields));

    let firstShowingPageIndex: number = 0;
    let lastShowingPageIndex: number = 0;

    if (this.props.pageIndex >= this.props.totalPages) {
      lastShowingPageIndex = this.props.pageIndex;
      firstShowingPageIndex = lastShowingPageIndex - MAX_SHOWING_PAGES + 1;
      if (firstShowingPageIndex < 0) {
        firstShowingPageIndex = 0;
      }
    } else if (this.props.totalPages < MAX_SHOWING_PAGES) {
      firstShowingPageIndex = 0;
      lastShowingPageIndex = this.props.totalPages - 1;
    } else {
      firstShowingPageIndex = Math.trunc((this.props.pageIndex) / (MAX_SHOWING_PAGES - 1)) * (MAX_SHOWING_PAGES - 1);
      lastShowingPageIndex = firstShowingPageIndex + MAX_SHOWING_PAGES - 1;
      if (lastShowingPageIndex >= this.props.totalPages) {
        lastShowingPageIndex = this.props.totalPages - 1;
        firstShowingPageIndex = lastShowingPageIndex - MAX_SHOWING_PAGES + 1;
        if (firstShowingPageIndex < 0) {
          firstShowingPageIndex = 0;
        }
      }
    }

    const pageIndexes: number[] = [];
    for (let i: number = firstShowingPageIndex; i <= lastShowingPageIndex; i++) {
      pageIndexes.push(i);
    }

    return (
      <>
        {this.renderTitle()}
        <div className="objects container-fluid class-table">
          <div className="row ml-1 mt-1 mr-1 mb-2">
            <div className="header-toolbar col">
              {
                this.props.objectClass.textIndexed ?
                  <FullTextSearchInput
                    value={this.props.fullTextSearchInputValue}
                    onSearch={() => this.props.onSubmitFullTextSearch(this.props)}
                    onChange={(value: string) => this.props.onChangeFullTextSearchInputValue(this.props.uniqueID, value)}
                  /> :
                  null
              }
              <div className="buttons-wrapper">
                <ObjectsFilterModal
                  fields={fields}
                  dynamicFieldNames={dynamicFieldNames}
                  objectFilter={this.props.objectFilter}
                  keepFiltered={this.props.keepFiltered}
                  isModalVisible={this.props.isFilterModalVisible}
                  onFilterButtonClick={this.onFilterButtonClick}
                  onCloseButtonClick={this.onFilterCloseButtonClick}
                  onChange={this.onFilterChange}
                  customizeDefaultFields={this.props.customizeDefaultFields}
                />
                <ObjectsSortModal
                  fields={fields}
                  dynamicFieldNames={dynamicFieldNames}
                  objectSort={this.props.objectSort}
                  keepSorted={this.props.keepSorted}
                  isModalVisible={this.props.isSortModalVisible}
                  onSortButtonClick={this.onSortButtonClick}
                  onCloseButtonClick={this.onSortCloseButtonClick}
                  onChange={this.onSortChange}
                  customizeDefaultFields={this.props.customizeDefaultFields}
                />
                { !isReadOnly && this.props.contexts.length === 0 && (
                  <>
                    <div className="vertical-row ml-2 mr-3"></div>
                    {this.renderEditButton(isEditDisabled, selectedIds[0])}
                    {this.renderDeleteButton(isDeleteDisabled, selectedIds)}
                    <Link to={`/objects/${this.props.className}/Add`} className="btn btn-success">
                      <i className="fas fa-plus"></i>
                      <span className='d-none d-sm-inline'> Add</span>
                    </Link>
                  </>
                )}
                {this.renderEllipsisButton()}
              </div>
            </div>
          </div>
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col" style={{ width: '1px', minWidth: '1px', verticalAlign: 'baseline' }}>
                    { (!this.props.contexts || !this.props.contexts.length) && <Checkbox id='objects-select-all' onChange={(event: ChangeEvent<HTMLInputElement>) => this.props.onInputSelectAllChange(this.props.uniqueID, event)} checked={this.props.isAllSelected || false} /> }
                  </th>
                  {
                    !this.props.customizeDefaultFields && <TableHeader
                        content={fields.id && fields.id.customField ? fields.id.customField.title : 'Id'}
                        objectFilterIndex={this.getObjectFilterIndex((fields.id.objectClassField as ObjectClassField).name)}
                        objectSortIndex={this.getObjectSortIndex((fields.id.objectClassField as ObjectClassField).name)}
                        onFilterButtonClick={this.onFilterButtonClick}
                        onSortChange={this.onSortChange}
                        keepSorted={this.props.keepSorted}
                        objectSort={this.props.objectSort}
                        field={(fields.id.objectClassField as ObjectClassField).name}
                        keepFiltered={this.props.keepFiltered}
                        onFilterChange={this.onFilterChange}
                        objectFilter={this.props.objectFilter}
                        fields={fields}
                    />
                  }
                  { dynamicFieldNames.map((objectClassFieldName: string): JSX.Element => {
                      const field = fields[objectClassFieldName];
                      let content = field.customField
                          ? (field.customField as CustomField).title
                          : (field.objectClassField as ObjectClassField).name;
                      const fieldType = field.objectClassField && (field.objectClassField as ObjectClassField).type;
                      content += fieldType === FieldType.Date
                          ? ' (UTC)'
                          : '';
                      if (fieldType === FieldType.Relation) {
                        return (
                          <TableHeader key={objectClassFieldName}
                            content={content}
                            showIcon={false}
                          />
                        );
                      }
                      return (
                        <TableHeader
                            content={content}
                            key={objectClassFieldName}
                            objectFilterIndex={this.getObjectFilterIndex((field.objectClassField as ObjectClassField).name)}
                            objectSortIndex={this.getObjectSortIndex((field.objectClassField as ObjectClassField).name)}
                            onFilterButtonClick={this.onFilterButtonClick}
                            onSortChange={this.onSortChange}
                            keepSorted={this.props.keepSorted}
                            objectSort={this.props.objectSort}
                            field={(field.objectClassField as ObjectClassField).name}
                            keepFiltered={this.props.keepFiltered}
                            onFilterChange={this.onFilterChange}
                            objectFilter={this.props.objectFilter}
                            fields={fields}
                        />
                      );
                    })
                  }
                  {
                    !this.props.customizeDefaultFields &&
                    <>
                      <TableHeader
                          content={fields.createdAt && fields.createdAt.customField ? fields.createdAt.customField.title : 'Created At (UTC)'}
                          objectFilterIndex={this.getObjectFilterIndex((fields.createdAt.objectClassField as ObjectClassField).name)}
                          objectSortIndex={this.getObjectSortIndex((fields.createdAt.objectClassField as ObjectClassField).name)}
                          onFilterButtonClick={this.onFilterButtonClick}
                          onSortChange={this.onSortChange}
                          keepSorted={this.props.keepSorted}
                          objectSort={this.props.objectSort}
                          field={(fields.createdAt.objectClassField as ObjectClassField).name}
                          keepFiltered={this.props.keepFiltered}
                          onFilterChange={this.onFilterChange}
                          objectFilter={this.props.objectFilter}
                          fields={fields}
                      />
                      <TableHeader
                          content={fields.updatedAt && fields.updatedAt.customField ? fields.updatedAt.customField.title : 'Updated At (UTC)'}
                          objectFilterIndex={this.getObjectFilterIndex((fields.updatedAt.objectClassField as ObjectClassField).name)}
                          objectSortIndex={this.getObjectSortIndex((fields.updatedAt.objectClassField as ObjectClassField).name)}
                          onFilterButtonClick={this.onFilterButtonClick}
                          onSortChange={this.onSortChange}
                          keepSorted={this.props.keepSorted}
                          objectSort={this.props.objectSort}
                          field={(fields.updatedAt.objectClassField as ObjectClassField).name}
                          keepFiltered={this.props.keepFiltered}
                          onFilterChange={this.onFilterChange}
                          objectFilter={this.props.objectFilter}
                          fields={fields}
                      />
                    </>
                  }
                  {this.props.objectClass.referencedBy.map(({ className, fieldName }) => {
                    const classes = this.props.objectClass.referencedBy.map(({ className }) => className);
                    const isSingleClassReference = classes.indexOf(className) === classes.lastIndexOf(className);

                    // If a class contains multiple columns referencing the same class, then we should
                    // also display the name of the field that holds the pointer
                    const menuItem = this.props.menuItems.find(menuItem => menuItem.objectClassName === className);
                    const classTitle = menuItem ? menuItem.title : className;

                    const customFields = this.props.customFields.byObjectClassName[className];
                    const customField: CustomField | undefined = customFields && customFields[fieldName];
                    const referencesLinkTitle = customField && customField.referencesLinkTitle;
                    let content;
                    if (referencesLinkTitle) {
                      content = referencesLinkTitle;
                    } else {
                      const fields: Fields = getFields(this.props.objectClass, this.props.customFields.byObjectClassName[className]);
                      content = isSingleClassReference
                        ? `${classTitle} Refs`
                        : `${classTitle} Refs (${getFieldTitle(fieldName, fields)})`;
                    }
                    // Remove the reference to a hidden class
                    return (menuItem && menuItem.isHidden ? null : <TableHeader key={`${className}/${fieldName}`} content={content} showIcon={false} />);
                  })}
                </tr>
              </thead>
              {
                // Use this condition because the Loader component shouldn't render inside a table
                this.props.objectsFetched ?
                  <tbody>
                    {this.props.objectIds.map((objectId: string): JSX.Element => {
                      const customizedReferencedBy = this.props.objectClass.referencedBy.map((referencer) => {
                        const { className, fieldName } = referencer;
                        const customFields = this.props.customFields.byObjectClassName[className];
                        const customField: CustomField | undefined = customFields && customFields[fieldName];
                        // Remove the reference to a hidden class
                        const menuItem = this.props.menuItems.find(menuItem => menuItem.objectClassName === className);
                        return menuItem && menuItem.isHidden ? undefined : {
                            referenceLinkText: customField && customField.referencesLinkText,
                            referenceTitleField: customField && customField.referenceTitleField || '',
                            ...referencer
                          }
                      }).filter(referencer => referencer !== undefined) as PointerReference[];
                      return (
                        <ObjectsRow
                          key={objectId}
                          objectsUniqueID={this.props.uniqueID}
                          objectId={objectId}
                          fields={fields}
                          dynamicFieldNames={dynamicFieldNames}
                          isSelected={this.props.selectedIds[objectId] === true}
                          onSelectChange={(objectId: string, isSelected: boolean) => this.props.onRowSelectChange(this.props.uniqueID, objectId, isSelected)}
                          referencedBy={customizedReferencedBy}
                          className={this.props.className}
                          showImages={this.props.showImages}
                          isRelationTable={this.props.contexts && this.props.contexts.length > 0}
                          customizeDefaultFields={this.props.customizeDefaultFields} />
                      );
                    })}
                  </tbody> :
                  <></>
              }
            </table>
            <Loader loaded={this.props.objectsFetched} />
          </div>
          <div className="row justify-content-center mt-4">
            <nav aria-label="Table navigation">
              <ul className="pagination">
                <li className={`page-item${ this.props.pageIndex <= 0 ? ' disabled' : ''} mr-3`}>
                  <button className="page-link rounded" aria-label="Previous" onClick={(): void => { this.props.onPageIndexChange(this.props, this.props.pageIndex - 1); }}>
                    <Icon className="mr-2" icon="zmdi-arrow-left" size={IconSize.LARGE} />
                    <span className="font-weight-bold">Prev</span>
                  </button>
                </li>
                {pageIndexes.map((pageIndex: number): JSX.Element => {
                  return (
                    <li key={pageIndex} className={`page-item${pageIndex === this.props.pageIndex ? ' active font-weight-bold' : ''}${pageIndex !== pageIndexes[pageIndexes.length -1] ? ' mr-1' : ''}`}>
                      <button className="page-link rounded" onClick={(): void => { this.props.onPageIndexChange(this.props, pageIndex); }}>{pageIndex + 1}</button>
                    </li>
                  );
                })}
                <li className={`page-item${ this.props.pageIndex >= this.props.totalPages - 1 ? ' disabled' : ''} ml-3`}>
                  <button className="page-link rounded" aria-label="Next" onClick={(): void => { this.props.onPageIndexChange(this.props, this.props.pageIndex + 1); }}>
                    <span className="font-weight-bold">Next</span>
                    <Icon icon="ml-2 zmdi-arrow-right" size={IconSize.LARGE} />
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </>
    );
  }
}

type OwnProps = {
  uniqueID: string,
  className: string,
  title: string,
  contexts: ObjectsContext[]
};

type UIState = {
  pageIndex: number,
  selectedIds: {
    [id: string]: boolean
  },
  isSortModalVisible: boolean,
  isFilterModalVisible: boolean,
  objectsFetched: boolean,
  showImages: boolean,
  fullTextSearchInputValue: string
};

type UIsState = { [uniqueID: string]: UIState };

export type ObjectsContext = {
  uniqueID: string,
  parentClassName: string,
  objectId: string,
  fieldName: string,
  objectIds: string[],
  className: string,
  relations: Relation[],
  refererTitle: string
}

type ObjectsState = {
  uisObjects: UIsObjects,
  uisState: UIsState,
  contexts: ObjectsContext[]
};

type RootState = {
  app: {
    loggedUser?: { sessionToken: string },
    objectClasses: ObjectClass[],
    customFields: CustomFields,
    settings: { customizeDefaultFields: string },
  },
  objects: ObjectsState,
  menu: {
    items: MenuItem[]
  }
};

const INITIAL_UI_OBJECTS: UIObjects = {
  objectFilter: [],
  keepFiltered: true,
  objectSort: [],
  keepSorted: true,
  pageLimit: 10,
  pagesIds: [[]],
  objectsById: {},
  fullTextSearchQueryValue: ''
};

const INITIAL_UI_STATE: UIState = {
  pageIndex: 0,
  selectedIds: {},
  isSortModalVisible: false,
  isFilterModalVisible: false,
  objectsFetched: false,
  showImages: false,
  fullTextSearchInputValue: ''
};

function mapState(rootState: RootState, ownProps: OwnProps): State {
  const contexts: ObjectsContext[] = ownProps.contexts;
  const context = contexts && contexts.length && contexts[contexts.length - 1];
  const { uniqueID = ownProps.uniqueID, className = ownProps.className, objectIds = null, objectId = null, fieldName = null, relations = [] } = context || {};
  const uiObjects: UIObjects = rootState.objects.uisObjects[uniqueID] || INITIAL_UI_OBJECTS
  const uiState: UIState = rootState.objects.uisState[uniqueID] || INITIAL_UI_STATE;
  const currentPageObjectIds = uiObjects.pagesIds[uiState.pageIndex];
  let contextFilter: ObjectFilter;
  if (objectIds && objectIds.length) {
    contextFilter = [{
      fieldName: 'id',
      operator: FilterOperator.ContainedIn,
      values: objectIds
    }];
  } else if (!relations.length && className && objectId && fieldName) {
    contextFilter = [{
      fieldName,
      operator: FilterOperator.EqualsTo,
      values: [objectId]
    }]
  } else {
    contextFilter = [];
  }

  return {
    uniqueID,
    sessionToken: rootState.app.loggedUser ? rootState.app.loggedUser.sessionToken : undefined,
    className,
    title: ownProps.title,
    contexts,
    objectClass: rootState.app.objectClasses.find((objectClass: ObjectClass): boolean => { return objectClass.name === className; }) || { name: className, fields: [], referencedBy: [] },
    customFields: rootState.app.customFields,
    objectFilter: uiObjects.objectFilter,
    contextFilter,
    keepFiltered: uiObjects.keepFiltered,
    isFilterModalVisible: uiState.isFilterModalVisible,
    objectSort: uiObjects.objectSort,
    keepSorted: uiObjects.keepSorted,
    isSortModalVisible: uiState.isSortModalVisible,
    pageLimit: uiObjects.pageLimit,
    pageIndex: uiState.pageIndex,
    objectIds: uiState.pageIndex < uiObjects.pagesIds.length ? currentPageObjectIds : [],
    totalPages: uiObjects.pagesIds.length,
    selectedIds: uiState.selectedIds,
    isAllSelected: currentPageObjectIds !== undefined && currentPageObjectIds.length > 0 && currentPageObjectIds.every(objectId => uiState.selectedIds[objectId] === true),
    objectsFetched: currentPageObjectIds !== undefined &&  currentPageObjectIds.length > 0 || uiState.objectsFetched,
    menuItems: rootState.menu.items,
    relations,
    showImages: uiState.showImages,
    fullTextSearchInputValue: uiState.fullTextSearchInputValue,
    fullTextSearchQueryValue: uiObjects.fullTextSearchQueryValue,
    customizeDefaultFields: rootState.app.settings.customizeDefaultFields === "true"
  };
}

export enum ActionTypes {
  SubscribeObjects = 'OBJECTS.SUBSCRIBE_OBJECTS',
  ChangeObjects = 'OBJECTS.CHANGE_OBJECTS',
  ChangeObject = 'OBJECTS.CHANGE_OBJECT',
  FailObjects = 'OBJECTS.FAIL_OBJECTS',
  UnsubscribeObjects = 'OBJECTS.UNSUBSCRIBE_OBJECTS',
  ChangeSelectedId = 'OBJECTS.CHANGE_SELECTED_ID',
  ResetSelectedIds = 'OBJECTS.RESET_SELECTED_IDS',
  ChangePageIndex = 'OBJECTS.CHANGE_PAGE_INDEX',
  LoadPageObjects = 'OBJECTS.LOAD_PAGE_OBJECTS',
  ChangePageObjects = 'OBJECTS.CHANGE_PAGE_OBJECTS',
  ChangeSortModalVisibility = 'OBJECTS.CHANGE_SORT_MODAL_VISIBILITY',
  ChangeObjectSort = 'OBJECTS.CHANGE_OBJECT_SORT',
  ChangeFilterModalVisibility = 'OBJECTS.CHANGE_FILTER_MODAL_VISIBILITY',
  ChangeObjectFilter = 'OBJECTS.CHANGE_OBJECT_FILTER',
  ChangeInputSelectAll = 'OBJECTS.CHANGE_INPUT_SELECT_ALL',
  PushRelationContext = 'OBJECTS.PUSH_RELATION_CONTEXT',
  ChangeRelationContext = 'OBJECTS.CHANGE_RELATION_CONTEXT',
  ChangeImagesExhibitionMode = 'OBJECTS.CHANGE_IMAGES_EXHIBITION_MODE',
  ChangeFullTextInputValue = 'OBJECTS.CHANGE_FULL_TEXT_INPUT_VALUE',
  SubmitFullTextSearch = 'OBJECTS.SUBMIT_FULL_TEXT_SEARCH'
};

type SubscribeObjectsAction = {
  type: ActionTypes.SubscribeObjects,
  payload: {
    ownProps: OwnProps
  }
};

type ChangeObjectsAction = {
  type: ActionTypes.ChangeObjects,
  payload: {
    uniqueID: string,
    objects: ObjectEntity[],
    totalPages: number
  }
};

type ChangeObjectAction = {
  type: ActionTypes.ChangeObject,
  payload: {
    uniqueID: string,
    last: ObjectEntity,
    event: Event
  }
};

type FailObjectsAction = {
  type: ActionTypes.FailObjects,
  payload: {
    uniqueID: string,
    errorMessage: string
  }
};

type UnsubscribeObjectsAction = {
  type: ActionTypes.UnsubscribeObjects,
  payload: {
    uniqueID: string
  }
};

type ChangeSelectedIdAction = {
  type: ActionTypes.ChangeSelectedId,
  payload: {
    uniqueID: string,
    objectId: string,
    isSelected: boolean
  }
};

type ResetSelectedIdsAction = {
  type: ActionTypes.ResetSelectedIds,
  payload: {
    uniqueID: string
  }
};

type ChangePageIndexAction = {
  type: ActionTypes.ChangePageIndex,
  payload: {
    uniqueID: string,
    pageIndex: number
  }
};

type LoadPageObjectsAction = {
  type: ActionTypes.LoadPageObjects,
  payload: {
    ownProps: OwnProps,
    pageIndex: number
  }
};

type ChangePageObjectsAction = {
  type: ActionTypes.ChangePageObjects,
  payload: {
    uniqueID: string,
    pageIndex: number,
    objects: ObjectEntity[],
    totalPages: number
  }
}

type ChangeSortModalVisibilityAction = {
  type: ActionTypes.ChangeSortModalVisibility,
  payload: {
    uniqueID: string,
    isSortModalVisible: boolean
  }
}

type ChangeObjectSortAction = {
  type: ActionTypes.ChangeObjectSort,
  payload: {
    uniqueID: string,
    objectSort: ObjectSort,
    keepSorted: boolean
  }
}

type ChangeFilterModalVisibilityAction = {
  type: ActionTypes.ChangeFilterModalVisibility,
  payload: {
    uniqueID: string,
    isFilterModalVisible: boolean
  }
}

type ChangeObjectFilterAction = {
  type: ActionTypes.ChangeObjectFilter,
  payload: {
    uniqueID: string,
    objectFilter: ObjectFilter,
    keepFiltered: boolean
  }
}

type ChangeInputSelectAllAction = {
  type: ActionTypes.ChangeInputSelectAll,
  payload: {
    uniqueID: string,
    isAllSelected: boolean
  }
}

type PushRelationContextAction = {
  type: ActionTypes.PushRelationContext,
  payload: {
    uniqueID: string,
    className: string,
    objectId: string,
    fieldName: string,
    parentClassName: string,
    objectIds: string[],
    relations: Relation[]
  }
}

type ChangeRelationContextAction = {
  type: ActionTypes.ChangeRelationContext,
  payload: {
    index: number
  }
}

type ChangeImagesExhibitionModeAction = {
  type: ActionTypes.ChangeImagesExhibitionMode,
  payload: {
    uniqueID: string
  }
}

type ChangeFullTextInputValueAction = {
  type: ActionTypes.ChangeFullTextInputValue,
  payload: {
    uniqueID: string,
    fullTextSearchInputValue: string
  }
}

type SubmitFullTextSearchAction = {
  type: ActionTypes.SubmitFullTextSearch,
  payload: {
    uniqueID: string,
    fullTextSearchQueryValue: string
  }
}

export type Action =
  SubscribeObjectsAction |
  ChangeObjectsAction |
  ChangeObjectAction |
  FailObjectsAction |
  UnsubscribeObjectsAction |
  ChangeSelectedIdAction |
  ResetSelectedIdsAction |
  ChangePageIndexAction |
  LoadPageObjectsAction |
  ChangePageObjectsAction |
  ChangeSortModalVisibilityAction |
  ChangeObjectSortAction |
  ChangeFilterModalVisibilityAction |
  ChangeObjectFilterAction |
  ChangeInputSelectAllAction |
  PushRelationContextAction |
  ChangeImagesExhibitionModeAction |
  ChangeFullTextInputValueAction |
  SubmitFullTextSearchAction;

type Dispatch = any;

type GetState = () => RootState;

type Thunk = (dispatch: Dispatch, getState: GetState) => Promise<Action>;

let cancelObjectsAPISubscriptions: { [uniqueID: string]: { subscribersCounter: number, cancelFunction: () => void } } = {};

function cancelObjectsAPISubscription(uniqueID: string) {
  if (cancelObjectsAPISubscriptions[uniqueID]) {
    cancelObjectsAPISubscriptions[uniqueID].subscribersCounter = cancelObjectsAPISubscriptions[uniqueID].subscribersCounter - 1;
    if (cancelObjectsAPISubscriptions[uniqueID].subscribersCounter <= 0) {
      cancelObjectsAPISubscriptions[uniqueID].cancelFunction();
      delete cancelObjectsAPISubscriptions[uniqueID];
    }
  }
}

function getFilter(objectFilter: ObjectFilter, keepFiltered: boolean): { [fieldName: string]: { operator: string, values: any[] }[] } {
  const filter: { [fieldName: string]: { operator: string, values: any[] }[] } = {};
  if (keepFiltered) {
    objectFilter.forEach((objectFilterItem: ObjectFilterItem): void => {
      if (!filter[objectFilterItem.fieldName]) {
        filter[objectFilterItem.fieldName] = [];
      }
      if (objectFilterItem.values.length) {
        filter[objectFilterItem.fieldName].push({ operator: objectFilterItem.operator, values: objectFilterItem.values });
      } else if (objectFilterItem.operator === FilterOperator.Exists || objectFilterItem.operator === FilterOperator.DoesNotExist) {
        filter[objectFilterItem.fieldName].push({ operator: objectFilterItem.operator, values: [] });
      }
    });
  }
  return filter;
}

function getSort(objectSort: ObjectSortItem[], keepSorted: boolean): ObjectSortItem[] {
  if (!objectSort.length || !keepSorted) {
    objectSort = DEFAULT_SORT;
  }
  const sort: ObjectSortItem[] = objectSort.map((sortItem: ObjectSortItem): ObjectSortItem => {
    return {
      field: sortItem.field.toUpperCase(),
      direction: sortItem.direction
    };
  });
  return sort;
}

function sameState(ownProps: OwnProps, getState: GetState, lastState: State): boolean {
  const state: State = mapState(getState(), ownProps);
  return state.className === lastState.className &&
    state.objectFilter === lastState.objectFilter &&
    state.objectSort === lastState.objectSort &&
    state.keepSorted === lastState.keepSorted &&
    state.pageLimit === lastState.pageLimit &&
    state.showImages === lastState.showImages;
}

function subscribeObjects(ownProps: OwnProps): Thunk {
  return async (dispatch: Dispatch, getState: GetState): Promise<Action> => {
    const action: SubscribeObjectsAction = dispatch({
      type: ActionTypes.SubscribeObjects,
      payload: {
        ownProps
      }
    });

    const state: State = mapState(getState(), ownProps);
    const fields: Fields = getFields(state.objectClass, state.customFields.byObjectClassName[state.className]);

    let objects: ObjectEntity[] = [];
    let totalPages: number = 1;

    if (!state.sessionToken) {
      return dispatch(changeObjects(state.uniqueID, objects, totalPages));
    }

    const filter: { [fieldName: string]: { operator: string, values: any[] }[] } = getFilter([...state.objectFilter, ...state.contextFilter], state.keepFiltered);
    const sort: ObjectSortItem[] = getSort(state.objectSort, state.keepSorted);
    const fullTextSearchQueryValue: string = state.fullTextSearchQueryValue || "";
    try {
      const result: { list: ObjectEntity[], totalPages: number } = (await api.findObjects(state.sessionToken, state.className, fields, filter, sort, state.pageLimit, 0, state.relations, fullTextSearchQueryValue));
      objects = result.list;
      totalPages = result.totalPages;
    } catch (e) {
      if (e instanceof api.NetworkError) {
        console.error(`Network error when subscribing to ${state.className} Objects. Trying again in 5 sec...`);
        dispatch(ShowNetworkErrorNotification());

        await (new Promise((resolve: () => void): void => {
          setTimeout(() => {
            resolve();
          }, 5000);
        }));
        if (!sameState(ownProps, getState, state)) {
          return action;
        }
        return dispatch(subscribeObjects(ownProps));
      }

      return dispatch(failObjects(state.uniqueID, e.message));
    }

    cancelObjectsAPISubscription(state.uniqueID);

    if (!sameState(ownProps, getState, state)) {
      return action;
    }

    if (cancelObjectsAPISubscriptions[state.uniqueID] && cancelObjectsAPISubscriptions[state.uniqueID].subscribersCounter > 0) {
      cancelObjectsAPISubscriptions[state.uniqueID].subscribersCounter = cancelObjectsAPISubscriptions[state.uniqueID].subscribersCounter + 1;
    } else {
      const cancelFunction: () => void = api.observeObjects(
        state.sessionToken,
        state.className,
        fields,
        filter,
        (objectChanged: { last: ObjectEntity, event: Event }): void => {
          if (!sameState(ownProps, getState, state)) {
            cancelObjectsAPISubscription(state.uniqueID);
            return;
          }
          dispatch(changeObject(state.uniqueID, objectChanged.last, objectChanged.event));
        },
        (error: Error): void => {
          if (!sameState(ownProps, getState, state)) {
            cancelObjectsAPISubscription(state.uniqueID);
            return;
          }
          dispatch(failObjects(state.uniqueID, error.message));
        },
        (): void => {
          if (!sameState(ownProps, getState, state)) {
            cancelObjectsAPISubscription(state.uniqueID);
            return;
          }
          dispatch(subscribeObjects(ownProps));
        }
      );
      cancelObjectsAPISubscriptions[state.uniqueID] = {
        subscribersCounter: 1,
        cancelFunction
      };
    }

    return dispatch(changeObjects(state.uniqueID, objects, totalPages));
  };
}

function changeObjects(uniqueID: string, objects: ObjectEntity[], totalPages: number): ChangeObjectsAction {
  return {
    type: ActionTypes.ChangeObjects,
    payload: {
      uniqueID,
      objects,
      totalPages
    }
  };
}

function changeObject(uniqueID: string, last: ObjectEntity, event: Event ): ChangeObjectAction {
  return {
    type: ActionTypes.ChangeObject,
    payload: {
      uniqueID,
      last,
      event
    }
  };
}

function failObjects(uniqueID: string, errorMessage: string): FailObjectsAction {
  return {
    type: ActionTypes.FailObjects,
    payload: {
      uniqueID,
      errorMessage
    }
  };
}

function unsubscribeObjects(uniqueID: string): UnsubscribeObjectsAction {
  cancelObjectsAPISubscription(uniqueID);
  return {
    type: ActionTypes.UnsubscribeObjects,
    payload: {
      uniqueID
    }
  };
}

function changeSelectedId(uniqueID: string, objectId: string, isSelected: boolean): ChangeSelectedIdAction {
  return {
    type: ActionTypes.ChangeSelectedId,
    payload: {
      uniqueID,
      objectId,
      isSelected
    }
  };
}

function resetSelectedIds(uniqueID: string): ResetSelectedIdsAction {
  return {
    type: ActionTypes.ResetSelectedIds,
    payload: {
      uniqueID
    }
  };
}

function changePageIndex(uniqueID: string, pageIndex: number): ChangePageIndexAction {
  return {
    type: ActionTypes.ChangePageIndex,
    payload: {
      uniqueID,
      pageIndex
    }
  }
}

function loadPageObjects(ownProps: OwnProps, pageIndex: number): Thunk {
  return async (dispatch: Dispatch, getState: GetState): Promise<Action> => {
    const action: LoadPageObjectsAction = dispatch({
      type: ActionTypes.LoadPageObjects,
      payload: {
        ownProps,
        pageIndex
      }
    });

    const state: State = mapState(getState(), ownProps);
    const fields: Fields = getFields(state.objectClass, state.customFields.byObjectClassName[state.className]);

    let objects: ObjectEntity[] = [];
    let totalPages: number = 1;

    if (!state.sessionToken) {
      return dispatch(changePageObjects(state.uniqueID, pageIndex, objects, totalPages));
    }

    const filter: { [fieldName: string]: { operator: string, values: any[] }[] } = getFilter([...state.objectFilter, ...state.contextFilter], state.keepFiltered);
    const sort: ObjectSortItem[] = getSort(state.objectSort, state.keepSorted);
    const fullTextSearchQueryValue: string = state.fullTextSearchQueryValue || "";
    try {
      const result: { list: ObjectEntity[], totalPages: number } = (await api.findObjects(state.sessionToken, state.className, fields, filter, sort, state.pageLimit, pageIndex, state.relations, fullTextSearchQueryValue));
      objects = result.list;
      totalPages = result.totalPages;
    } catch (e) {
      if (e instanceof api.NetworkError) {
        console.error(`Network error when loading ${state.className} page ${pageIndex + 1} Objects. Trying again in 5 sec...`);
        dispatch(ShowNetworkErrorNotification());

        await (new Promise((resolve: () => void): void => {
          setTimeout(() => {
            resolve();
          }, 5000);
        }));
        if (!sameState(ownProps, getState, state)) {
          return action;
        }
        return dispatch(loadPageObjects(ownProps, pageIndex));
      }

      return dispatch(failObjects(state.uniqueID, e.message));
    }

    if (!sameState(ownProps, getState, state)) {
      return action;
    }

    return dispatch(changePageObjects(state.uniqueID, pageIndex, objects, totalPages));
  }
}

function changePageObjects(uniqueID: string, pageIndex: number, objects: ObjectEntity[], totalPages: number): ChangePageObjectsAction {
  return {
    type: ActionTypes.ChangePageObjects,
    payload: {
      uniqueID,
      pageIndex,
      objects,
      totalPages
    }
  }
}

function changeSortModalVisibility(uniqueID: string, isSortModalVisible: boolean): ChangeSortModalVisibilityAction {
  return {
    type: ActionTypes.ChangeSortModalVisibility,
    payload: {
      uniqueID,
      isSortModalVisible
    }
  };
}

function changeObjectSort(uniqueID: string, objectSort: ObjectSort, keepSorted: boolean): ChangeObjectSortAction {
  return {
    type: ActionTypes.ChangeObjectSort,
    payload: {
      uniqueID,
      objectSort,
      keepSorted
    }
  };
}

function changeFilterModalVisibility(uniqueID: string, isFilterModalVisible: boolean): ChangeFilterModalVisibilityAction {
  return {
    type: ActionTypes.ChangeFilterModalVisibility,
    payload: {
      uniqueID,
      isFilterModalVisible
    }
  };
}

function changeObjectFilter(uniqueID: string, objectFilter: ObjectFilter, keepFiltered: boolean): ChangeObjectFilterAction {
  return {
    type: ActionTypes.ChangeObjectFilter,
    payload: {
      uniqueID,
      objectFilter,
      keepFiltered
    }
  };
}

function ChangeInputSelectAll(uniqueID: string, isAllSelected: boolean): ChangeInputSelectAllAction {
  return {
    type: ActionTypes.ChangeInputSelectAll,
    payload: {
      uniqueID,
      isAllSelected
    }
  }
}

function changeImagesExhibitionMode(uniqueID: string): ChangeImagesExhibitionModeAction {
  return {
    type: ActionTypes.ChangeImagesExhibitionMode,
    payload: {
      uniqueID
    }
  }
}

function changeFullTextInputValue(uniqueID: string, fullTextSearchInputValue: string): ChangeFullTextInputValueAction {
  return {
    type: ActionTypes.ChangeFullTextInputValue,
    payload: {
      uniqueID,
      fullTextSearchInputValue
    }
  }
}

function submitFullTextSearch(uniqueID: string, fullTextSearchQueryValue: string): SubmitFullTextSearchAction {
  return {
    type: ActionTypes.SubmitFullTextSearch,
    payload: {
      uniqueID,
      fullTextSearchQueryValue
    }
  }
}

function mapEvents(dispatch: Dispatch, ownProps: OwnProps): Events {
  return {
    onComponentDidMount(): void {
      dispatch(changeSortModalVisibility(ownProps.uniqueID, false));
      dispatch(changePageIndex(ownProps.uniqueID, 0));
      dispatch(resetSelectedIds(ownProps.uniqueID));
      dispatch(subscribeObjects(ownProps));
    },
    onComponentDidUpdate(prevProps: Props, props: Props): void {
      if (prevProps.uniqueID !== props.uniqueID || prevProps.className !== props.className) {
        dispatch(changeSortModalVisibility(props.uniqueID, false));
        dispatch(changePageIndex(props.uniqueID, 0));
        dispatch(resetSelectedIds(props.uniqueID));
        dispatch(unsubscribeObjects(props.uniqueID));
        dispatch(subscribeObjects(props));
      }
      if (prevProps.fullTextSearchInputValue === "" && props.fullTextSearchInputValue === "" && props.fullTextSearchQueryValue)
        dispatch(changeFullTextInputValue(props.uniqueID, props.fullTextSearchQueryValue))
    },
    onComponentWillUnmount(uniqueID: string): void {
      dispatch(changeSortModalVisibility(uniqueID, false));
      dispatch(changePageIndex(uniqueID, 0));
      dispatch(resetSelectedIds(uniqueID));
      dispatch(unsubscribeObjects(uniqueID));
    },
    onRowSelectChange(uniqueID: string, objectId: string, isSelected: boolean): void {
      dispatch(changeSelectedId(uniqueID, objectId, isSelected));
    },
    onPageIndexChange(props: Props, pageIndex: number): void {
      dispatch(resetSelectedIds(props.uniqueID));
      dispatch(changePageIndex(props.uniqueID, pageIndex));
      dispatch(loadPageObjects(props, pageIndex));
    },
    onSortButtonClick(uniqueID: string): void {
      dispatch(changeSortModalVisibility(uniqueID, true));
    },
    onSortCloseButtonClick(uniqueID: string): void {
      dispatch(changeSortModalVisibility(uniqueID, false));
    },
    onSortChange(props: Props, objectSort: ObjectSort, keepSorted: boolean): void {
      dispatch(changeObjectSort(props.uniqueID, objectSort, keepSorted));
      dispatch(unsubscribeObjects(props.uniqueID));
      dispatch(subscribeObjects(props));
    },
    onFilterButtonClick(uniqueID: string): void {
      dispatch(changeFilterModalVisibility(uniqueID, true));
    },
    onFilterCloseButtonClick(uniqueID: string): void {
      dispatch(changeFilterModalVisibility(uniqueID, false));
    },
    onFilterChange(props: Props, objectFilter: ObjectFilter, keepfiltered: boolean): void {
      dispatch(changeObjectFilter(props.uniqueID, objectFilter, keepfiltered));
      dispatch(unsubscribeObjects(props.uniqueID));
      dispatch(subscribeObjects(props));
    },
    onInputSelectAllChange(uniqueID: string, event: ChangeEvent<HTMLInputElement>): void {
      dispatch(ChangeInputSelectAll(uniqueID, event.target.checked || false));
    },
    onChangeImagesExhibitionMode(uniqueID: string): void {
      dispatch(changeImagesExhibitionMode(uniqueID));
    },
    onChangeFullTextSearchInputValue(uniqueID: string, fullTextSearchInputValue: string): void {
      dispatch(changeFullTextInputValue(uniqueID, fullTextSearchInputValue));
    },
    onSubmitFullTextSearch(props: Props): void {
      dispatch(submitFullTextSearch(props.uniqueID, props.fullTextSearchInputValue))
      dispatch(unsubscribeObjects(props.uniqueID));
      dispatch(subscribeObjects(props));
    }
  };
}

export default connect(mapState, mapEvents)(View);

function compareObjects(uiObjects: UIObjects, a: ObjectEntity, b: ObjectEntity): number {
  let objectSort: ObjectSort = uiObjects.objectSort;
  if (!objectSort.length || !uiObjects.keepSorted) {
    objectSort = DEFAULT_SORT;
  }
  for (let i: number = 0; i < objectSort.length; i++) {
    let aValue: any = a[objectSort[i].field];
    if (aValue instanceof Date) {
      aValue = aValue.getTime();
    }
    let bValue: any = b[objectSort[i].field];
    if (bValue instanceof Date) {
      bValue = bValue.getTime();
    }
    if (aValue !== bValue) {
      if (objectSort[i].direction === SortDirection.Asc) {
        if (aValue < bValue) {
          return -1;
        } else {
          return 1;
        }
      } else {
        if (aValue < bValue) {
          return 1;
        } else {
          return -1;
        }
      }
    }
  }
  return 0;
}

function generateCompareIds(uiObjects: UIObjects, objectEntity: ObjectEntity): (aId: string, bId: string) => number {
  return (aId: string, bId: string): number => {
    return compareObjects(
      uiObjects,
      aId === objectEntity.id ? objectEntity : uiObjects.objectsById[aId],
      bId === objectEntity.id ? objectEntity : uiObjects.objectsById[bId]
    );
  }
}

function findCurrentPageIndex(uiObjects: UIObjects, objectEntity: ObjectEntity): number | undefined {
  for (let i: number = 0; i < uiObjects.pagesIds.length; i++) {
    if (uiObjects.pagesIds[i].includes(objectEntity.id)) {
      return i;
    }
  }
}

function findCorrectPageIndex(uiObjects: UIObjects, pagesIds: string[][], objectEntity: ObjectEntity): number {
  let firstAvailablePageIndex: number | undefined = undefined;
  for (let i: number = 0; i < pagesIds.length; i++) {
    const pageIds: string[] = pagesIds[i];
    if (pageIds.length) {
      const firstObject: ObjectEntity = uiObjects.objectsById[pageIds[0]];
      const lastObject: ObjectEntity = uiObjects.objectsById[pageIds[pageIds.length - 1]];
      if (
        compareObjects(uiObjects, firstObject, objectEntity) <= 0 &&
        compareObjects(uiObjects, objectEntity, lastObject) <= 0
      ) {
        return i;
      } else if (compareObjects(uiObjects, firstObject, objectEntity) > 0) {
        return firstAvailablePageIndex || i;
      } else {
        if (pageIds.length < uiObjects.pageLimit) {
          firstAvailablePageIndex = i;
        } else {
          firstAvailablePageIndex = undefined;
        }
      }
    } else if (!firstAvailablePageIndex) {
      firstAvailablePageIndex = i;
    }
  }
  return firstAvailablePageIndex !== undefined ? firstAvailablePageIndex : pagesIds.length;
}

function reducerHelper(uiObjects: UIObjects, uiState: UIState, objectEntity: ObjectEntity, onlyRemove: boolean): { pagesIds: string[][], selectedIds: { [id: string]: boolean }} {
  let pagesIds: string[][] = [];

  const currentPageIndex: number | undefined = findCurrentPageIndex(uiObjects, objectEntity);
  let correctPageIndex: number | undefined = onlyRemove ? undefined : findCorrectPageIndex(uiObjects, uiObjects.pagesIds, objectEntity);

  if (currentPageIndex === undefined && correctPageIndex === undefined) {
    return { pagesIds: uiObjects.pagesIds, selectedIds: uiState.selectedIds };
  }

  uiObjects.pagesIds.forEach((pageIds: string[]): void => {
    pagesIds.push(pageIds);
  });

  if (currentPageIndex === correctPageIndex) {
    pagesIds[currentPageIndex as number] = pagesIds[currentPageIndex as number].sort(generateCompareIds(uiObjects, objectEntity));

    return { pagesIds, selectedIds: uiState.selectedIds };
  }

  if (currentPageIndex !== undefined) {
    let lastPoppedPageIndex: number = -1;
    for (let i: number = currentPageIndex; i < uiObjects.pagesIds.length; i++) {
      let pageIds: string[] = [];
      if (i === currentPageIndex) {
        pageIds = uiObjects.pagesIds[i].filter((id: string): boolean => { return id !== objectEntity.id; });
      } else {
        pageIds = uiObjects.pagesIds[i].slice(1);
      }
      pagesIds[i] = pageIds;
      lastPoppedPageIndex = i;
      if (
        uiObjects.pagesIds[i].length === uiObjects.pageLimit &&
        i + 1 < uiObjects.pagesIds.length &&
        uiObjects.pagesIds[i + 1].length
      ) {
        pageIds.push(uiObjects.pagesIds[i + 1][0]);
      } else {
        break;
      }
    }

    if (
      lastPoppedPageIndex === uiObjects.pagesIds.length - 1 &&
      !pagesIds[pagesIds.length - 1].length
    ) {
      pagesIds.pop();
    }

    if (correctPageIndex !== undefined) {
      correctPageIndex = findCorrectPageIndex(uiObjects, pagesIds, objectEntity);
    }
  }

  if (correctPageIndex !== undefined) {
    while (correctPageIndex >= pagesIds.length) {
      pagesIds.push([]);
    }
    pagesIds[correctPageIndex].push(objectEntity.id);
    pagesIds[correctPageIndex] = pagesIds[correctPageIndex].sort(generateCompareIds(uiObjects, objectEntity));
    if (pagesIds[correctPageIndex].length > uiObjects.pageLimit) {
      let nextId: string | undefined = pagesIds[correctPageIndex].pop() as string;
      const lastIndexToPush: number = currentPageIndex !== undefined ? currentPageIndex : pagesIds.length - 1
      let lastPushedPageIndex = correctPageIndex;
      for (let i: number = correctPageIndex + 1; i <= lastIndexToPush; i++) {
        pagesIds[i] = [nextId].concat(pagesIds[i]);
        lastPushedPageIndex = i;
        if (pagesIds[i].length > uiObjects.pageLimit) {
          nextId = pagesIds[i].pop() as string;
        } else {
          nextId = undefined;
          break;
        }
      }

      if (lastPushedPageIndex === pagesIds.length - 1 && nextId !== undefined) {
        pagesIds.push([nextId]);
      }

      for (let i: number = lastIndexToPush + 1; i < uiObjects.pagesIds.length; i++) {
        if (i < pagesIds.length) {
          pagesIds[i] = uiObjects.pagesIds[i];
        } else {
          pagesIds.push(uiObjects.pagesIds[i]);
        }
      }
    }
  }

  let selectedIds: { [id: string]: boolean } = uiState.selectedIds;
  const hiddenSelectedIds: string[] = Object.keys(selectedIds).filter((id: string): boolean => { return !pagesIds[uiState.pageIndex].includes(id); });
  if (hiddenSelectedIds.length) {
    const removeSelectedIds: { [id: string]: undefined } = {};
    hiddenSelectedIds.forEach((id: string): void => {
      removeSelectedIds[id] = undefined;
    });
    selectedIds = Object.assign(
      {},
      selectedIds,
      removeSelectedIds
    );
  }

  return { pagesIds, selectedIds };
}

let cachedUIsObjects: UIsObjects | undefined = cache.getUIsObjects();

export function reducer(
  state: ObjectsState = {
    uisObjects: cachedUIsObjects || {},
    uisState: {},
    contexts: []
  },
  action: Action | LogOutAction | ObjectFormAction | DeleteObjectsAction | PushRelationContextAction | ChangeRelationContextAction
): ObjectsState {
  switch (action.type) {
    case ActionTypes.SubscribeObjects:
      return Object.assign(
        {},
        state,
        {
          uisObjects: Object.assign(
            {},
            state.uisObjects,
            {
              [action.payload.ownProps.uniqueID]: Object.assign(
                {},
                state.uisObjects[action.payload.ownProps.uniqueID] || INITIAL_UI_OBJECTS,
                {
                  className: action.payload.ownProps.className,
                  objectsFetched: false
                }
              )
            }
          )
        }
      );
    case ActionTypes.ChangeObjects:
    case ActionTypes.ChangePageObjects: {
      const pageIndex: number = action.type === ActionTypes.ChangePageObjects ? action.payload.pageIndex : 0;
      const objectsIds: string[] = action.payload.objects.map((objectEntity: ObjectEntity): string => {
        return objectEntity.id;
      })

      const uiObjects: UIObjects = state.uisObjects[action.payload.uniqueID] || INITIAL_UI_OBJECTS;
      const uiState: UIState = state.uisState[action.payload.uniqueID] || INITIAL_UI_STATE;

      const objectsById: { [id: string]: ObjectEntity | undefined } = {};
      const pagesIds: string[][] = [];

      for (let i: number = 0; i < action.payload.totalPages; i++) {
        if (i === pageIndex) {
          pagesIds.push(objectsIds);
        } else if (i < uiObjects.pagesIds.length) {
          pagesIds.push(uiObjects.pagesIds[i].filter((id: string): boolean => {
            return !objectsIds.includes(id);
          }));
          if (pagesIds[i].length === uiObjects.pagesIds[i].length) {
            pagesIds[i] = uiObjects.pagesIds[i];
          }
        } else {
          pagesIds.push([]);
        }
      }

      action.payload.objects.forEach((object: ObjectEntity): void => {
        objectsById[object.id] = object;
      });

      if (pageIndex < uiObjects.pagesIds.length) {
        uiObjects.pagesIds[pageIndex]
          .filter((id: string): boolean => {
            return !pagesIds[pageIndex].includes(id);
          })
          .forEach((id: string): void => {
            objectsById[id] = undefined;
          });
      }

      let selectedIds: { [id: string]: boolean } = uiState.selectedIds;
      const hiddenSelectedIds: string[] = Object.keys(selectedIds).filter((id: string): boolean => { return !pagesIds[uiState.pageIndex].includes(id); });
      if (hiddenSelectedIds.length) {
        const removeSelectedIds: { [id: string]: undefined } = {};
        hiddenSelectedIds.forEach((id: string): void => {
          removeSelectedIds[id] = undefined;
        });
        selectedIds = Object.assign(
          {},
          selectedIds,
          removeSelectedIds
        );
      }

      return Object.assign(
        {},
        state,
        {
          uisObjects: Object.assign(
            {},
            state.uisObjects,
            {
              [action.payload.uniqueID]: Object.assign(
                {},
                uiObjects,
                {
                  pagesIds,
                  objectsById: Object.assign(
                    {},
                    uiObjects.objectsById,
                    objectsById
                  )
                }
              )
            }
          ),
          uisState: Object.assign(
            {},
            state.uisState,
            {
              [action.payload.uniqueID]: Object.assign(
                {},
                uiState,
                {
                  selectedIds,
                  objectsFetched: true
                }
              )
            }
          )
        }
      );
    }

    case ActionTypes.ChangeObject: {
      const uiObjects: UIObjects = state.uisObjects[action.payload.uniqueID] || INITIAL_UI_OBJECTS;
      const uiState: UIState = state.uisState[action.payload.uniqueID] || INITIAL_UI_STATE;
      const { pagesIds, selectedIds }: { pagesIds: string[][], selectedIds: { [id:string]: boolean }} = reducerHelper(
        uiObjects,
        uiState,
        action.payload.last,
        action.payload.event === Event.Deleted
      );

      switch (action.payload.event) {
        case Event.Created:
          return Object.assign(
            {},
            state,
            {
              uisObjects: Object.assign(
                {},
                state.uisObjects,
                {
                  [action.payload.uniqueID]: Object.assign(
                    {},
                    uiObjects,
                    {
                      pagesIds,
                      objectsById: Object.assign(
                        {},
                        uiObjects.objectsById,
                        {
                          [action.payload.last.id]: action.payload.last
                        }
                      )
                    }
                  )
                }
              ),
              uisState: Object.assign(
                {},
                state.uisState,
                {
                  [action.payload.uniqueID]: Object.assign(
                    {},
                    uiState,
                    {
                      selectedIds
                    }
                  )
                }
              )
            }
          );

        case Event.Updated:
          return Object.assign(
            {},
            state,
            {
              uisObjects: Object.assign(
                {},
                state.uisObjects,
                {
                  [action.payload.uniqueID]: Object.assign(
                    {},
                    uiObjects,
                    {
                      pagesIds,
                      objectsById: Object.assign(
                        {},
                        uiObjects.objectsById,
                        {
                          [action.payload.last.id]: action.payload.last
                        }
                      )
                    }
                  )
                }
              ),
              uisState: Object.assign(
                {},
                state.uisState,
                {
                  [action.payload.uniqueID]: Object.assign(
                    {},
                    uiState,
                    {
                      selectedIds
                    }
                  )
                }
              )
            }
          );

        case Event.Deleted:
          return Object.assign(
            {},
            state,
            {
              uisObjects: Object.assign(
                {},
                state.uisObjects,
                {
                  [action.payload.uniqueID]: Object.assign(
                    {},
                    uiObjects,
                    {
                      pagesIds,
                      objectsById: Object.assign(
                        {},
                        uiObjects.objectsById,
                        {
                          [action.payload.last.id]: undefined
                        }
                      )
                    }
                  )
                }
              ),
              uisState: Object.assign(
                {},
                state.uisState,
                {
                  [action.payload.uniqueID]: Object.assign(
                    {},
                    uiState,
                    {
                      selectedIds
                    }
                  )
                }
              )
            }
          );
      }
      break;
    }

    case ActionTypes.ChangeSelectedId: {
      const uiState: UIState = state.uisState[action.payload.uniqueID] || INITIAL_UI_STATE;
      return Object.assign(
        {},
        state,
        {
          uisState: Object.assign(
            {},
            state.uisState,
            {
              [action.payload.uniqueID]: Object.assign(
                {},
                uiState,
                {
                  selectedIds: Object.assign(
                    {},
                    uiState.selectedIds,
                    {
                      [action.payload.objectId]: action.payload.isSelected
                    }
                  )
                }
              )
            }
          )
        }
      );
    }

    case ActionTypes.ResetSelectedIds:
      return Object.assign(
        {},
        state,
        {
          uisState: Object.assign(
            {},
            state.uisState,
            {
              [action.payload.uniqueID]: Object.assign(
                {},
                state.uisState[action.payload.uniqueID] || INITIAL_UI_STATE,
                {
                  selectedIds: []
                }
              )
            }
          )
        }
      );

    case ActionTypes.ChangePageIndex:
      return Object.assign(
        {},
        state,
        {
          uisState: Object.assign(
            {},
            state.uisState,
            {
              [action.payload.uniqueID]: Object.assign(
                {},
                state.uisState[action.payload.uniqueID] || INITIAL_UI_STATE,
                {
                  pageIndex: action.payload.pageIndex,
                  objectsFetched: false
                }
              )
            }
          )
        }
      );

    case ActionTypes.ChangeSortModalVisibility:
      return Object.assign(
        {},
        state,
        {
          uisState: Object.assign(
            {},
            state.uisState,
            {
              [action.payload.uniqueID]: Object.assign(
                {},
                state.uisState[action.payload.uniqueID] || INITIAL_UI_STATE,
                {
                  isSortModalVisible: action.payload.isSortModalVisible
                }
              )
            }
          )
        }
      );

    case ActionTypes.ChangeObjectSort: {
      const uiObjects: UIObjects = state.uisObjects[action.payload.uniqueID] || INITIAL_UI_OBJECTS;
      if (uiObjects.keepSorted || action.payload.keepSorted) {
        return Object.assign(
          {},
          state,
          {
            uisObjects: Object.assign(
              {},
              state.uisObjects,
              {
                [action.payload.uniqueID]: Object.assign(
                  {},
                  uiObjects,
                  {
                    objectSort: action.payload.objectSort,
                    keepSorted: action.payload.keepSorted,
                    pagesIds: [[]],
                    objectsById: {}
                  }
                )
              }
            ),
            uisState: Object.assign(
              {},
              state.uisState,
              {
                [action.payload.uniqueID]: Object.assign(
                  {},
                  state.uisState[action.payload.uniqueID] || INITIAL_UI_STATE,
                  {
                    pageIndex: 0,
                    selectedIds: {},
                    objectsFetched: false
                  }
                )
              }
            )
          }
        );
      } else {
        return Object.assign(
          {},
          state,
          {
            uisObjects: Object.assign(
              {},
              state.uisObjects,
              {
                [action.payload.uniqueID]: Object.assign(
                  {},
                  uiObjects,
                  {
                    objectSort: action.payload.objectSort,
                    keepSorted: action.payload.keepSorted
                  }
                )
              }
            )
          }
        );
      }
    }

    case ActionTypes.ChangeFilterModalVisibility:
      return Object.assign(
        {},
        state,
        {
          uisState: Object.assign(
            {},
            state.uisState,
            {
              [action.payload.uniqueID]: Object.assign(
                {},
                state.uisState[action.payload.uniqueID] || INITIAL_UI_STATE,
                {
                  isFilterModalVisible: action.payload.isFilterModalVisible
                }
              )
            }
          )
        }
      );

    case ActionTypes.ChangeObjectFilter: {
      const uiObjects: UIObjects = state.uisObjects[action.payload.uniqueID] || INITIAL_UI_OBJECTS;
      if (uiObjects.keepFiltered || action.payload.keepFiltered) {
        return Object.assign(
          {},
          state,
          {
            uisObjects: Object.assign(
              {},
              state.uisObjects,
              {
                [action.payload.uniqueID]: Object.assign(
                  {},
                  uiObjects,
                  {
                    objectFilter: action.payload.objectFilter,
                    keepFiltered: action.payload.keepFiltered,
                    pagesIds: [[]],
                    objectsById: {}
                  }
                )
              }
            ),
            uisState: Object.assign(
              {},
              state.uisState,
              {
                [action.payload.uniqueID]: Object.assign(
                  {},
                  state.uisState[action.payload.uniqueID] || INITIAL_UI_STATE,
                  {
                    pageIndex: 0,
                    selectedIds: {},
                    objectsFetched: false
                  }
                )
              }
            )
          }
        );
      } else {
        return Object.assign(
          {},
          state,
          {
            uisObjects: Object.assign(
              {},
              state.uisObjects,
              {
                [action.payload.uniqueID]: Object.assign(
                  {},
                  uiObjects,
                  {
                    objectFilter: action.payload.objectFilter,
                    keepFiltered: action.payload.keepFiltered
                  }
                )
              }
            )
          }
        );
      }
    }

    case ActionTypes.ChangeInputSelectAll: {
      const uisState: UIsState = state.uisState;
      const uniqueID: string = action.payload.uniqueID;
      const uiState: UIState = uisState[uniqueID] || INITIAL_UI_STATE;
      const uiObject: UIObjects = state.uisObjects[uniqueID];

      const selectedIds: {
        [id: string]: boolean
      } = {};

      const currentPageObjectIds = uiObject.pagesIds[uiState.pageIndex];
      Object.keys(uiObject.objectsById).forEach(objectId => {
        // If the object is on the current page, select accordingly to the action,
        // otherwise, deselect
        selectedIds[objectId] = currentPageObjectIds.includes(objectId)
          ? action.payload.isAllSelected
          : false;
      });

      return Object.assign(
        {},
        state,
        {
          uisState: Object.assign(
            {},
            uisState,
            {
              [uniqueID]: Object.assign(
                {},
                uiState,
                {
                  selectedIds
                }
              )
            }
          )
        }
      );
    }

    case ObjectFormActionTypes.ConfirmLoad:
    case ObjectFormActionTypes.Confirm: {
      let uisObjects: UIsObjects = state.uisObjects;
      let uisState: UIsState = state.uisState;

      Object.keys(state.uisObjects).forEach((uniqueID: string): void => {
        if (action.payload.className === uisObjects[uniqueID].className) {
          const uiObjects: UIObjects = uisObjects[uniqueID];
          const uiState: UIState = uisState[uniqueID] || INITIAL_UI_STATE;

          const checkedFilter: boolean = checkFilter(action.payload.objectEntity, uiObjects.objectFilter, uiObjects.keepFiltered);

          const { pagesIds, selectedIds }: { pagesIds: string[][], selectedIds: { [id:string]: boolean }} = reducerHelper(
            uiObjects,
            uiState,
            action.payload.objectEntity,
            !checkedFilter
          );

          uisObjects = Object.assign(
            {},
            uisObjects,
            {
              [uniqueID]: Object.assign(
                {},
                uiObjects,
                {
                  pagesIds,
                  objectsById: Object.assign(
                    {},
                    uiObjects.objectsById,
                    {
                      [action.payload.objectEntity.id]: checkedFilter ? action.payload.objectEntity : undefined
                    }
                  )
                }
              )
            }
          );

          uisState = Object.assign(
            {},
            uisState,
            {
              [uniqueID]: Object.assign(
                {},
                uiState,
                {
                  selectedIds
                }
              )
            }
          );
        }
      });

      return Object.assign(
        {},
        state,
        {
          uisObjects,
          uisState
        }
      );
    }

    case DeleteObjectsActionTypes.Confirm: {
      let uisObjects: UIsObjects = state.uisObjects;
      let uisState: UIsState = state.uisState;

      Object.keys(state.uisObjects).forEach((uniqueID: string): void => {
        if (action.payload.className === uisObjects[uniqueID].className) {
          action.payload.objectIds
            .filter((objectId: string): boolean => {
              return uisObjects[uniqueID].objectsById[objectId] !== undefined;
            })
            .map((objectId: string): ObjectEntity => {
              return uisObjects[uniqueID].objectsById[objectId];
            })
            .forEach((objectEntity: ObjectEntity): void => {
              const uiObjects: UIObjects = uisObjects[uniqueID];
              const uiState: UIState = uisState[uniqueID] || INITIAL_UI_STATE;

              const { pagesIds, selectedIds }: { pagesIds: string[][], selectedIds: { [id:string]: boolean }} = reducerHelper(
                uiObjects,
                uiState,
                objectEntity,
                true
              );

              uisObjects = Object.assign(
                {},
                uisObjects,
                {
                  [uniqueID]: Object.assign(
                    {},
                    uiObjects,
                    {
                      pagesIds,
                      objectsById: Object.assign(
                        {},
                        uiObjects.objectsById,
                        {
                          [objectEntity.id]: undefined
                        }
                      )
                    }
                  )
                }
              );

              uisState = Object.assign(
                {},
                uisState,
                {
                  [uniqueID]: Object.assign(
                    {},
                    uiState,
                    {
                      selectedIds
                    }
                  )
                }
              );
            });
        }
      });

      return Object.assign(
        {},
        state,
        {
          uisObjects,
          uisState
        }
      );
    }

    case ActionTypes.ChangeImagesExhibitionMode: {
      let uisState: UIsState = state.uisState;
      const uniqueID: string = action.payload.uniqueID;
      const uiState: UIState = uisState[uniqueID] || INITIAL_UI_STATE;

      uisState = Object.assign(
        {},
        uisState,
        {
          [uniqueID]: Object.assign(
            {},
            uiState,
            {
              showImages: !uiState.showImages
            }
          )
        }
      )

      return Object.assign(
        {},
        state,
        {
          uisState
        }
      );
    }

    case ActionTypes.ChangeFullTextInputValue: {
      return Object.assign(
        {},
        state,
        {
          uisState: Object.assign(
            {},
            state.uisState,
            {
              [action.payload.uniqueID]: Object.assign(
                {},
                state.uisState[action.payload.uniqueID] || INITIAL_UI_STATE,
                {
                  fullTextSearchInputValue: action.payload.fullTextSearchInputValue
                }
              )
            }
          )
        }
      );
    }

    case ActionTypes.SubmitFullTextSearch: {
      const uiObjects: UIObjects = state.uisObjects[action.payload.uniqueID] || INITIAL_UI_OBJECTS;
      const uisState: UIsState = state.uisState;
      const uniqueID: string = action.payload.uniqueID;
      const uiState: UIState = uisState[uniqueID] || INITIAL_UI_STATE;
      return Object.assign(
        {},
        state,
        {
          uisObjects: Object.assign(
            {},
            state.uisObjects,
            {
              [uniqueID]: Object.assign(
                {},
                uiObjects,
                {
                  fullTextSearchQueryValue: action.payload.fullTextSearchQueryValue,
                  pagesIds: [[]],
                  objectsById: {}
                }
              )
            }
          ),
          uisState: Object.assign(
            {},
            state.uisState,
            {
              [uniqueID]: Object.assign(
                {},
                uiState,
                {
                  pageIndex: 0,
                  selectedIds: {},
                  objectsFetched: false
                }
              )
            }
          )
        }
      );
    }

    case LogOutActionTypes.Confirm:
    case LogOutActionTypes.Fail:
      return {
        uisObjects: {},
        uisState: {},
        contexts: []
      };
  }

  return state;
};

export function subscriber(store: Store): void {
  const state: RootState = store.getState() as RootState;
  const uisObjects: UIsObjects = state.objects.uisObjects;
  if (uisObjects !== cachedUIsObjects) {
    cache.setUIsObjects(uisObjects);
    cachedUIsObjects = uisObjects;
  }
};
