import React from 'react';

export enum IconSize {
  XSMALL = 'xs',
  SMALL = 'sm',
  LARGE = 'lg',
  X2 = '2x',
  X3 = '3x',
  X4 = '4x',
  X5 = '5x'
}

type IconProps = {
  icon: string,
  size?: IconSize,
  className?: string,
  isMaterial?: boolean,
  onClick?: (event: any) => void
}

const Icon = (props: IconProps) => {
  const { icon, size, className, isMaterial = true, onClick } = props;
  const iconLib = isMaterial ? 'zmdi' : 'fas';
  const sizeClass = size ? isMaterial ? ` zmdi-hc-${size}` : ` fa-${size}` : '';
  const additionalClassName = className ? ` ${className}` : '';
  return (
    <i className={`${iconLib} ${icon}${sizeClass}${additionalClassName}`} onClick={onClick}></i>
  );
};

export default Icon;
