import React, { InputHTMLAttributes, RefObject } from 'react';
import Hover from './Hover';
import Icon from './Icon';

export const IMAGE_EXTENSIONS: string[] = [
  'jpg',
  'gif',
  'png',
  'bmp',
  'svg',
  'jpeg',
  'tiff',
  'ico'
];

type Props = {
  name: string,
  fieldTitle: string,
  value: File | { name: string, url: string } | null,
  isInvalid?: boolean,
  onChange: (value: File | null) => void
};

export function checkImagesExtensions(name: string): boolean {
  return (name && IMAGE_EXTENSIONS.findIndex((imageExtension: string): boolean => name.endsWith(imageExtension)) >= 0) as boolean
}

export default function View(
  {
    name,
    fieldTitle,
    value,
    isInvalid,
    onChange
  }: Props
): JSX.Element {
  const id: string = `${name}FileInput`;
  const inputProperties: InputHTMLAttributes<HTMLInputElement> = {
    type: 'file',
    className: 'custom-file-input',
    id,
    name: id,
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files && event.target.files.length > 0) {
        onChange(event.target.files[0]);
      } else {
        onChange(null);
      }
    }

  };
  const fileName: string = value ? value.name : '';
  const inputRef: any= React.createRef();

  const url: string = value ? (value as { name: string, url: string }).url : '';
  const lowerUrl: string = url && url.toLowerCase();

  const isImage: boolean = checkImagesExtensions(lowerUrl);
  return (
    <>
      <div className="input-group">
        {
          isImage ?
          <img src={url} className="img-fluid" alt={url} /> :
          <></>
        }
        <div className="custom-file">
          <input
            { ...inputProperties}
            key={`${id}InputKey-${fileName}`}
            ref={inputRef}
            className={'d-none'}
          />
          {value && !(value instanceof File) ?
            <div className="selected-items-box flex-grow-1 alert alert-light border border-right-0 p-1 pt-2 pl-2 mb-0">
              <a href={value.url} target="_blank">
                {fileName.slice(fileName.indexOf('_') + 1)}
              </a>
            </div>
          :
            <>
              <div className="selected-items-box flex-grow-1 alert alert-light border border-right-0 p-1 pt-2 pl-2 mb-0">
                {fileName || 'Choose file'}
              </div>
              <div className="input-group-append button-input-form">
                <button
                  className="btn input-group-text"
                  type="button"
                  id={`${id}ReplaceButton`}
                  onClick={() => {
                    inputRef.current.click();
                  }}
                >
                  <Icon icon="zmdi-search-in-file" className="icon primary" />
                  Browse
                </button>
              </div>
            </>
          }
        </div>
        { value &&
          <div className="input-group-append button-input-form">
            {!(value instanceof File) &&
              <button
                className="btn input-group-text"
                type="button"
                id={`${id}ReplaceButton`}
                onClick={() => {
                  inputRef.current.click();
                }}
              >
                <Icon icon="zmdi-search-in-file" className="icon primary" />
                Replace
              </button>
            }
            <button
              className="btn input-group-text"
              type="button"
              id={`${id}ClearButton`}
              onClick={() => onChange(null)}
            >
              <Icon icon="fa-trash" isMaterial={false} className="icon danger" />
              Clear
            </button>
          </div>
        }
      </div>
      { isInvalid ?
        <div style={{ display: 'block' }} className="invalid-feedback">
          Please choose {fieldTitle}.
        </div>
      :
        null
      }
    </>
  );
}