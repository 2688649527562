import { Fields, ObjectEntity, ObjectFilter, ObjectFilterItem } from "./entities";
import { CustomField, ObjectClassField } from "core";
import { format } from 'date-fns';

export function formatDate(input: Date, mask?: string): string {
  if (input) {
    if (typeof input === 'string') {
      input = new Date(input);
    }
    if (mask) {
      return format(input, mask, { awareOfUnicodeTokens: true });
    }
    return input.toLocaleString(
      'en-US',
      {
        timeZone: 'UTC',
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
      }
    ).replace(',', '');
  } else {
    return '';
  }
}

export function generateSortFieldsFunction(fields: Fields): (a: string, b: string) => number {
  return (a: string, b: string): number => {
    let aRelevance: number = 0;
    let bRelevance: number = 0;
    let aTitle: string = a;
    let bTitle: string = b;
    if (fields[a].customField) {
      aRelevance = (fields[a].customField as CustomField).relevance;
      aTitle = (fields[a].customField as CustomField).title;
    } else if (fields[a].objectClassField) {
      aTitle = (fields[a].objectClassField as ObjectClassField).name;
    }
    if (fields[b].customField) {
      bRelevance = (fields[b].customField as CustomField).relevance;
      bTitle = (fields[b].customField as CustomField).title;
    } else if (fields[b].objectClassField) {
      bTitle = (fields[b].objectClassField as ObjectClassField).name;
    }
    if (aRelevance > bRelevance) {
      return -1;
    } else if (aRelevance < bRelevance) {
      return 1;
    } else {
      if (aTitle > bTitle) {
        return 1;
      } else if (aTitle < bTitle) {
        return -1;
      } else {
        return 0;
      }
    }
  };
}

export function checkFilter(objectEntity: ObjectEntity, filter: ObjectFilter, keepFiltered: boolean): boolean {
  if (keepFiltered) {
    for (let i: number = 0; i < filter.length; i++) {
      const filterItem: ObjectFilterItem = filter[i];
      const value: any = objectEntity[filterItem.fieldName];
      const values : any[] = filterItem.values
      switch (filterItem.operator) {
        case 'EXISTS':
          if (value === null || value === undefined) {
            return false;
          }
        break;

        case 'DOES_NOT_EXIST':
          if (value !== null && value !== undefined) {
            return false;
          }
        break;

        case 'EQUALS_TO':
          if (!values.includes(value)) {
            return false;
          }
        break;

        case 'DOES_NOT_EQUAL_TO':
          if (values.includes(value)) {
            return false;
          }
        break;

        case 'CONTAINED_IN':
          if (!values.includes(value)) {
            return false;
          }
        break;

        case 'NOT_CONTAINED_IN':
          if (values.includes(value)) {
            return false;
          }
        break;

        case 'STARTS_WITH':
          if (!(value as string).startsWith(values[0])) {
            return false;
          }
        break;

        case 'ENDS_WITH':
          if (!(value as string).endsWith(values[0])) {
            return false;
          }
        break;

        case 'CONTAINS':
          if (!(value as string).includes(values[0])) {
            return false;
          }
        break;

        case 'CONTAINS_ALL':
          for (let j: number = 0; j < values.length; j++) {
            if (!(value as string).includes(values[j])) {
              return false;
            }
          }
        break;

        case 'GREATER_THAN':
          if (value <= values[0]) {
            return false;
          }
        break;

        case 'GREATER_THAN_OR_EQUAL_TO':
          if (value < values[0]) {
            return false;
          }
        break;

        case 'LESS_THAN':
          if (value >= values[0]) {
            return false;
          }
        break;

        case 'LESS_THAN_OR_EQUAL_TO':
          if (value > values[0]) {
            return false;
          }
        break;
      }
    }
  }

  return true;
}
