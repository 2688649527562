import React, { ErrorInfo, PureComponent } from 'react';
import * as Sentry from '@sentry/browser';
import { version } from '../package.json';

// Should have been called before using it here
// ideally before even rendering your React app
const env = (window as any).ENV;
const isHomolog = env === 'homolog';
if (env === 'production' || isHomolog) {
  Sentry.init({
    dsn: "https://d064abe6b68241dbb0c290934e24a879@sentry.io/1403372",
    debug: isHomolog,
    environment: isHomolog ? 'homolog' : 'production',
    release: version
  });
}

class ErrorBoundary extends PureComponent {
  state = { error: null };

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({ error });
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, (errorInfo as any)[key]);
      });
      Sentry.captureException(error);
    });
  }

  render() {
    if (this.state.error) {
      // Render fallback UI
      Sentry.showReportDialog();
      return null;
    } else {
      // When there's not an error, render children untouched
      return this.props.children;
    }
  }
}

export default ErrorBoundary;
