import React, { PureComponent } from 'react';
import { Store } from 'redux';
import { connect } from 'react-redux';
import { Link, Route, Switch, withRouter, RouteComponentProps, Redirect } from 'react-router-dom';
import { Event, MenuItem, ObjectClass, ObjectClassField, FieldType, RESERVED_CLASSES } from 'core';
import {
  Action as LogOutAction,
  ActionTypes as LogOutActionTypes
} from './LogOut';
import Home from './Home';
import NotFound from './NotFound';
import LoggedUser from './LoggedUser';
import Objects, { ObjectsContext } from './Objects';
import ObjectForm from './ObjectForm';
import DeleteObjects from './DeleteObjects';
import * as cache from '../cache';
import * as api from '../api';
import { History } from 'history';
import withAnalytics, { AnalyticsProps } from '../hoc/withAnalytics';
import { isSmallDevice } from '../utils/browser'
import Icon, { IconSize } from './Icon';
import { CSSTransition } from 'react-transition-group';
import Notification from './Notification';
import { ShowNetworkErrorNotification } from '../App'
import Loader from './Loader';

const ADMIN_APP_SETTINGS_CLASSES : string[] = ['B4aSetting', 'B4aMenuItem', 'B4aCustomField'];

type OwnState = {
  items: MenuItem[]
};

type State = OwnState & {
  appName: string,
  sessionToken?: string,
  objectClasses: ObjectClass[],
  currentPath: string,
  logo: string,
  isSupportButtonHidden?: string,
  isHomeHidden?: string
};

type Events = {
  onComponentDidMount: () => void,
  onComponentWillUnmount: () => void
};

type Props = State & Events & RouteComponentProps & AnalyticsProps;

class View extends PureComponent<Props> {
  state = { collapsed: isSmallDevice() }

  componentDidMount(): void {
    this.onRouteChanged(this.props.location.pathname);
    this.props.onComponentDidMount();
  }

  componentWillUnmount(): void {
    this.props.onComponentWillUnmount();
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged(this.props.location.pathname);
    }
  }

  trackClassRoute(className: string, location: string) {
    const { trackEvent } = this.props;
    if (/([_a-zA-Z0-9]+)\/Add\/?$/g.test(location)) {
      trackEvent(`At ${className} Add Page`);
    } else if (/([_a-zA-Z0-9]+)\/Delete\/?$/g.test(location)) {
      trackEvent(`At ${className} Delete Page`);
    } else if (/([_a-zA-Z0-9]+)\/([_a-zA-Z0-9]+)\/?$/g.test(location)) {
      trackEvent(`At ${className} Edit Page`);
    } else if (/([_a-zA-Z0-9]+)\/?$/g.test(location)) {
      trackEvent(`At ${className} View Page`);
    }
  }

  onRouteChanged(location: string) {
    const { trackEvent } = this.props;
    if (location === '/') {
      trackEvent('At Home Page');
    } else if (location.startsWith('/objects/')) {
      const classPath = location.replace('/objects/', '');
      if (classPath.startsWith('B4aCustomField')) {
        this.trackClassRoute('CustomField', classPath);
      } else if (classPath.startsWith('B4aMenuItem')) {
        this.trackClassRoute('MenuItem', classPath);
      } else if (classPath.startsWith('B4aSetting')) {
        this.trackClassRoute('Setting', classPath);
      } else if (classPath.startsWith('_User')) {
        this.trackClassRoute('User', classPath);
      } else {
        this.trackClassRoute('Class', classPath);
      }
    }
  }

  toggleSidebar = () => {
    this.setState({ collapsed: !this.state.collapsed });
  }

  renderObjects = (routeProps: { match: { params: { className: string, objectId: string, referenceClass: string, referenceFieldName: string } }, location: { state?: { refererTitle: string }} }): JSX.Element => {
    const { className, objectId, referenceClass: referenceClassName, referenceFieldName } = routeProps.match.params;
    const refererTitle: string = routeProps.location && routeProps.location.state && routeProps.location.state.refererTitle || '';

    const objectClass: ObjectClass | undefined = this.props.objectClasses.find((objectClass: ObjectClass): boolean => {
      return objectClass.name === className;
    });
    const menuItem: MenuItem | undefined = this.props.items.find((item: MenuItem): boolean => {
      return item.objectClassName === className;
    });
    const isHidden = menuItem && menuItem.isHidden
    if (objectClass && !isHidden) {
      let contexts : ObjectsContext[] = [];
      if (objectId && referenceClassName && referenceFieldName) {
        const referencedClass: ObjectClass | undefined = this.props.objectClasses.find((objectClass: ObjectClass): boolean => objectClass.name === referenceClassName);
        if (referencedClass) {
          const field: ObjectClassField | undefined = referencedClass.fields.find(field => field.name === referenceFieldName && field.type === FieldType.Pointer && field.targetClass === className);
          if (field && field.targetClass) {
            contexts = [{
              uniqueID: `${className}/${objectId}/references/${referenceClassName}/${referenceFieldName}`,
              className: referenceClassName,
              fieldName: referenceFieldName,
              objectId,
              objectIds: [],
              parentClassName: className,
              relations: [],
              refererTitle
            }]
          }
        }
      } else if (objectId && referenceFieldName) {
        const field: ObjectClassField | undefined = objectClass.fields.find(field => field.name === referenceFieldName);
        if (field && field.targetClass) {
          contexts = [{
            uniqueID: `${className}/${objectId}/relations/${referenceFieldName}`,
            className: field.targetClass,
            fieldName: referenceFieldName,
            objectId,
            objectIds: [],
            parentClassName: className,
            relations: [{
              key: referenceFieldName,
              className,
              objectId
            }],
            refererTitle
          }]
        }
      }
      return (
        <Objects
          uniqueID={className}
          className={className}
          title={menuItem ?
            menuItem.title
            :
            className.startsWith('_') ? className.slice(1) : className
          }
          contexts={contexts} />
      );
    } else {
      return (
        <NotFound />
      );
    }
  }

  render(): JSX.Element {
    const items: MenuItem[] = this.props.items
      .filter((item: MenuItem): boolean => !item.isHidden)
      .concat(this.props.objectClasses
        .filter((objectClass: ObjectClass): boolean => {
          return this.props.items.findIndex((item: MenuItem): boolean => {
            return item.objectClassName === objectClass.name
          }) < 0 && !RESERVED_CLASSES.includes(objectClass.name);
        })
        .map((objectClass: ObjectClass): MenuItem => {
          return {
            id: objectClass.name,
            title: objectClass.name.startsWith('_') ? objectClass.name.slice(1) : objectClass.name,
            objectClassName: objectClass.name,
            relevance: 0
          };
        })
      ).sort((a: MenuItem, b: MenuItem): number => {
        if (a.relevance > b.relevance) {
          return -1;
        } else if (a.relevance < b.relevance) {
          return 1;
        } else {
          if (a.title > b.title) {
            return 1;
          } else if (a.title < b.title) {
            return -1;
          } else {
            return 0;
          }
        }
      });

    const currentPath: string = this.props.currentPath;
    const appContent: MenuItem[] = items.filter((item: MenuItem): boolean => (!RESERVED_CLASSES.includes(item.objectClassName)
        && !ADMIN_APP_SETTINGS_CLASSES.includes(item.objectClassName)));
    const adminAppSettings: MenuItem[] = items.filter((item: MenuItem): boolean => ADMIN_APP_SETTINGS_CLASSES.includes(item.objectClassName));

    // Variable used to don't show the classes section in the sidebar when one of the sections is empty
    const showMenuClassesSectionsTitle: boolean = appContent.length > 0 && adminAppSettings.length > 0;
    const isSupportButtonHidden: boolean = typeof this.props.isSupportButtonHidden === 'string' && this.props.isSupportButtonHidden.toLowerCase() === 'true';
    const isHomeHidden: boolean = typeof this.props.isHomeHidden === 'string' && this.props.isHomeHidden.toLowerCase() === 'true';

    function NavItem(props: { title: string, path: string }): JSX.Element {
      const to: string = `${props.path}/`;
      const isActive: boolean = currentPath === props.path || currentPath === to || (to !== '/' && currentPath.indexOf(to) !== -1);
      return (
        <li className={`nav-item${isActive ? ' active' : ''}`}>
          <Link to={to} className="nav-link">
            {props.title}
            {isActive &&
              <span className="sr-only">(current)</span>
            }
          </Link>
        </li>
      );
    }

    return (
      <>
        <div className='wrapper'>
          <nav id='sidebar' className={this.state.collapsed ? 'collapsed': ''}>
            <div className='sidebar-header'>
              <div className={this.props.logo ? 'sidebar-btn-container-logo' : 'sidebar-btn-container'}>
                <button id='sidebar-btn-collapse' className='btn btn-primary' onClick={this.toggleSidebar}>
                  <Icon icon='zmdi-chevron-left' size={IconSize.X2} />
                  <Icon icon='zmdi-chevron-left' size={IconSize.X2} />
                </button>
              </div>
              { this.props.logo
                ? (
                  <div className="container-logo">
                    <img className="image-logo" src={this.props.logo } />
                  </div>
                )
                : (
                  <>
                    <span><b>Admin App</b></span>
                    <h3 className='overflow-ellipsis'>{this.props.appName}</h3>
                  </>
                )
              }
            </div>
            <div className="sidebar-content">
              {
                appContent.length ?
                <ul className="sidebar-list">
                  {showMenuClassesSectionsTitle && <li className="sidebar-subtitle">App Content</li>}
                  { !isHomeHidden && <NavItem title="Home" path="" />}
                  {appContent.map((item: MenuItem): JSX.Element => {
                    return (
                      <NavItem key={item.id} title={item.title} path={`/objects/${item.objectClassName}`} />
                    );
                  })}
                </ul> :
                <></>
              }
              {
                adminAppSettings.length ?
                <ul className="sidebar-list">
                  {showMenuClassesSectionsTitle && <span className="sidebar-subtitle">Admin App Settings</span>}
                  {adminAppSettings.map((item: MenuItem): JSX.Element => {
                    return (
                        <NavItem key={item.id} title={item.title} path={`/objects/${item.objectClassName}`} />
                    );
                  })}
                </ul> :
                <></>
              }
            </div>
            {
              !isSupportButtonHidden ?
              <div className="sidebar-footer">
                <button className='btn btn-primary btn-sm' style={{width: '75px'}} onClick={() => (window as any).zE && (window as any).zE.activate()}>
                  Support
                </button>
              </div> :
              <></>
            }
          </nav>

          <CSSTransition
            in={!this.state.collapsed && isSmallDevice()}
            timeout={300}
            classNames="sidebar-overlay-fade"
            unmountOnExit>
            <div className='sidebar-overlay' onClick={this.toggleSidebar}></div>
          </CSSTransition>

          <div id='content'>
            <div className={`sidebar-collapse-button-container float-left${!this.state.collapsed ? ' hide': ''}`}>
              <button type="button" id="sidebarCollapse" className="btn btn-primary" onClick={this.toggleSidebar}>
                <i className='zmdi zmdi-menu zmdi-hc-lg'></i>
              </button>
            </div>
            <LoggedUser />
            <Notification />
            <Switch>
              <Route exact path="/" render={(): JSX.Element => {
                return !isHomeHidden ?
                  <Home/>
                  :
                  (
                    this.props.objectClasses && this.props.objectClasses.length > 0 && items && items.length > 0 ?
                      <Redirect to={`/objects/${items[0].objectClassName}`} /> :
                      <Loader loaded={false} />
                  )
                }} />
              <Route exact path="/objects/:className" children={this.renderObjects} />
              <Route exact path="/objects/:className/:objectId/references/:referenceClass/:referenceFieldName" children={this.renderObjects} />
              <Route exact path="/objects/:className/:objectId/relations/:referenceFieldName" children={this.renderObjects} />
              <Route exact path="/objects/:className/Add" children={(routeProps: { match: { params: { className: string } }, history: History }): JSX.Element => {
                const className: string = routeProps.match.params.className;
                const objectClass: ObjectClass | undefined = this.props.objectClasses.find((objectClass: ObjectClass): boolean => {
                  return objectClass.name === className;
                });
                const menuItem: MenuItem | undefined = this.props.items.find((item: MenuItem): boolean => {
                  return item.objectClassName === className;
                });
                const isHidden = menuItem && menuItem.isHidden
                if (objectClass && !isHidden) {
                  const title = menuItem
                      ? menuItem.title
                      : className.startsWith('_') ? className.slice(1) : className
                  let subtitle
                  if (menuItem && menuItem.addFormTitle) {
                    subtitle = menuItem.addFormTitle
                  } else {
                    subtitle = `Add a ${title} Object`
                  }
                  return (
                    <ObjectForm
                      className={className}
                      title={title}
                      subtitle={subtitle}
                      redirect={(to: string): void => { routeProps.history.push(to); }} />
                  );
                } else {
                  return (
                    <NotFound />
                  );
                }
              }} />
              <Route exact path="/objects/:className/Delete" children={(routeProps: { match: { params: { className: string } }, location: { state: { objectIds: string[] | undefined } }, history: History }): JSX.Element => {
                const className: string = routeProps.match.params.className;
                const objectIds: string[] | undefined = routeProps.location.state ? routeProps.location.state.objectIds : undefined;
                const objectClass: ObjectClass | undefined = this.props.objectClasses.find((objectClass: ObjectClass): boolean => {
                  return objectClass.name === className;
                });
                const menuItem: MenuItem | undefined = this.props.items.find((item: MenuItem): boolean => {
                  return item.objectClassName === className;
                });
                const isHidden = menuItem && menuItem.isHidden
                if (objectClass && objectIds && !isHidden) {
                  return (
                    <DeleteObjects
                      className={className}
                      title={menuItem ?
                        menuItem.title
                        :
                        className.startsWith('_') ? className.slice(1) : className
                      }
                      objectIds={objectIds}
                      redirect={(to: string): void => { routeProps.history.push(to); }} />
                  );
                } else {
                  return (
                    <NotFound />
                  );
                }
              }} />
              <Route exact path="/objects/:className/:objectId" children={(routeProps: { match: { params: { className: string, objectId: string } }, history: History }): JSX.Element => {
                const className: string = routeProps.match.params.className;
                const objectId: string = routeProps.match.params.objectId;
                const objectClass: ObjectClass | undefined = this.props.objectClasses.find((objectClass: ObjectClass): boolean => {
                  return objectClass.name === className;
                });
                const menuItem: MenuItem | undefined = this.props.items.find((item: MenuItem): boolean => {
                  return item.objectClassName === className;
                });
                const isHidden = menuItem && menuItem.isHidden
                if (objectClass && !isHidden) {
                  const title = menuItem
                      ? menuItem.title
                      : className.startsWith('_') ? className.slice(1) : className
                  let subtitle
                  if (menuItem && menuItem.editFormTitle) {
                    subtitle = menuItem.editFormTitle
                  } else {
                    subtitle = `Edit a ${title} Object`
                  }
                  return (
                    <ObjectForm
                      className={className}
                      title={title}
                      subtitle={subtitle}
                      objectId={objectId}
                      redirect={(to: string): void => { routeProps.history.push(to); }} />
                  );
                } else {
                  return (
                    <NotFound />
                  );
                }
              }} />
              <Route component={NotFound} />
            </Switch>
          </div>
        </div>
      </>
    );
  }
}

type RootState = {
  app: {
    settings: { appName: string, logo: string, isSupportButtonHidden: string, isHomeHidden: string },
    loggedUser?: { sessionToken: string },
    objectClasses: ObjectClass[]
  },
  menu: {
    items: MenuItem[]
  }
};

function mapState(rootState: RootState, ownProps?: RouteComponentProps): State {
  return {
    items: rootState.menu.items,
    appName: rootState.app.settings.appName,
    sessionToken: rootState.app.loggedUser ? rootState.app.loggedUser.sessionToken : undefined,
    objectClasses: rootState.app.objectClasses,
    currentPath: ownProps ? ownProps.location.pathname : '/',
    logo: rootState.app.settings.logo,
    isSupportButtonHidden: rootState.app.settings.isSupportButtonHidden,
    isHomeHidden: rootState.app.settings.isHomeHidden
  };
}

export enum ActionTypes {
  SubscribeItems = 'MENU.SUBSCRIBE_ITEMS',
  ChangeItems = 'MENU.CHANGE_ITEMS',
  ChangeItem = 'MENU.CHANGE_ITEM',
  FailItems = 'MENU.FAIL_ITEMS',
  UnsubscribeItems = 'MENU.UNSUBSCRIBE_ITEMS'
};

type SubscribeItemsAction = {
  type: ActionTypes.SubscribeItems
};

type ChangeItemsAction = {
  type: ActionTypes.ChangeItems,
  payload: {
    items: MenuItem[]
  }
};

type ChangeItemAction = {
  type: ActionTypes.ChangeItem,
  payload: {
    item: MenuItem,
    event: Event
  }
};

type FailItemsAction = {
  type: ActionTypes.FailItems,
  payload: {
    errorMessage: string
  }
};

type UnsubscribeItemsAction = {
  type: ActionTypes.UnsubscribeItems
};

export type Action =
  SubscribeItemsAction |
  ChangeItemsAction |
  ChangeItemAction |
  FailItemsAction |
  UnsubscribeItemsAction;

type Dispatch = any;

type GetState = () => RootState;

type Thunk = (dispatch: Dispatch, getState: GetState) => Promise<Action>;

let cancelItemsAPISubscription: (() => void) | undefined = undefined;

function subscribeItems(): Thunk {
  return async (dispatch: Dispatch, getState: GetState): Promise<Action> => {
    dispatch({
      type: ActionTypes.SubscribeItems
    });

    const state: State = mapState(getState());

    let items: MenuItem[] = [];

    if (!state.sessionToken) {
      return dispatch(changeItems(items));
    }

    try {
      items = await api.menuItems(state.sessionToken);
    } catch (e) {
      if (e instanceof api.NetworkError) {
        console.error('Network error when subscribing to Menu Items. Trying again in 5 sec...');
        dispatch(ShowNetworkErrorNotification());
        await (new Promise((resolve: () => void): void => {
          setTimeout(() => {
            resolve();
          }, 5000);
        }));
        return dispatch(subscribeItems());
      }

      return dispatch(failItems(e.message));
    }

    if (cancelItemsAPISubscription) {
      cancelItemsAPISubscription();
    }

    cancelItemsAPISubscription = api.menuItemChanged(
      state.sessionToken,
      (menuItemChanged: { menuItem: MenuItem, event: Event }): void => {
        dispatch(changeItem(menuItemChanged.menuItem, menuItemChanged.event));
      },
      (error: Error): void => {
        dispatch(failItems(error.message));
      },
      (): void => {
        dispatch(subscribeItems());
      }
    );
    return dispatch(changeItems(items));
  };
}

function changeItems(items: MenuItem[]): ChangeItemsAction {
  return {
    type: ActionTypes.ChangeItems,
    payload: {
      items
    }
  };
}

function changeItem(item: MenuItem, event: Event): ChangeItemAction {
  return {
    type: ActionTypes.ChangeItem,
    payload: {
      item,
      event
    }
  };
}

function failItems(errorMessage: string): FailItemsAction {
  return {
    type: ActionTypes.FailItems,
    payload: {
      errorMessage
    }
  };
}

function unsubscribeItems(): UnsubscribeItemsAction {
  if (cancelItemsAPISubscription) {
    cancelItemsAPISubscription();
    cancelItemsAPISubscription = undefined;
  }
  return {
    type: ActionTypes.UnsubscribeItems
  };
}

function mapEvents(dispatch: Dispatch): Events {
  return {
    onComponentDidMount(): void {
      dispatch(subscribeItems());
    },
    onComponentWillUnmount(): void {
      dispatch(unsubscribeItems());
    }
  };
}

export default withRouter(withAnalytics(connect(mapState, mapEvents)(View)));

let cachedItems: MenuItem[] | undefined = cache.getMenuItems();

export function reducer(
  state: OwnState = {
    items: cachedItems || []
  },
  action: Action | LogOutAction
): OwnState {
  switch (action.type) {
    case ActionTypes.ChangeItems:
      return Object.assign(
        {},
        state,
        {
          items: action.payload.items
        }
      );

    case ActionTypes.ChangeItem:
      switch (action.payload.event) {
        case Event.Created:
          return Object.assign(
            {},
            state,
            {
              items: [
                ...state.items,
                action.payload.item
              ]
            }
          );

        case Event.Updated:
          const updatedItemIndex: number = state.items.findIndex((item: MenuItem): boolean => {
            return item.id === action.payload.item.id
          });
          return Object.assign(
            {},
            state,
            {
              items: [
                ...state.items.slice(0, updatedItemIndex),
                Object.assign(
                  {},
                  state.items[updatedItemIndex],
                  action.payload.item
                ),
                ...state.items.slice(updatedItemIndex + 1)
              ]
            }
          );

        case Event.Deleted:
          const deletedItemIndex: number = state.items.findIndex((item: MenuItem): boolean => {
            return item.id === action.payload.item.id
          });
          return Object.assign(
            {},
            state,
            {
              items: [
                ...state.items.slice(0, deletedItemIndex),
                ...state.items.slice(deletedItemIndex + 1)
              ]
            }
          );
      }
      break;

    case LogOutActionTypes.Confirm:
    case LogOutActionTypes.Fail:
      return {
        items: []
      };
  }

  return state;
};

export function subscriber(store: Store): void {
  const state: RootState = store.getState() as RootState;
  const items: MenuItem[] = state.menu.items;
  if (items !== cachedItems) {
    cache.setMenuItems(items);
    cachedItems = items;
  }
};
