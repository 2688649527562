import React, { useState } from 'react';
import TextInput from './TextInput';
import Icon, { IconSize } from './Icon';

type Props = {
  value?: string,
  placeholder?: string,
  onSearch(): void,
  onChange(value: string): void
};

export default ({ value, placeholder = 'Search', onSearch, onChange }: Props) => {
  return (
    <div className="input-group col-6 full-text-input-group">
      <TextInput
        className="search-box search-input"
        value={value}
        onKeyDown={(key: any): void => {
          if (key === 'Enter') {
            onSearch();
          }
        }}
        onChange={(value: any): void => {
          onChange(value);
        }}
        placeholder={placeholder} />
      <div className="input-group-append search-box">
        <button className="btn input-group-text" type="button" id="button-addon2" onClick={() => onSearch()}>
          <Icon className='button-icon primary' icon='zmdi-search' size={IconSize.LARGE} />
          <span className='button-text'>Search</span>
        </button>
      </div>
    </div>
  );
};
