import React, { SyntheticEvent } from 'react';
import ReactDatePicker from 'react-datepicker';
import InputValidationText from './InputValidationText';
import MaskedInput from 'react-text-mask';

type DateInputProps = {
  fieldTitle: string,
  isRequired?: boolean,
  isInvalid?: boolean,
  value: string|number|Date,
  onChange(date: Date | null): void,
  readOnly?: boolean,
  mask?: string // @see formats available at https://date-fns.org/v2.0.0-alpha.27/docs/format
}

const DateInput = (props: DateInputProps) => {
  const { fieldTitle, isRequired = false, isInvalid = false, value, onChange, readOnly = false, mask } = props;
  let dateValue: Date | null = null;
  if (value) {
    dateValue = value instanceof Date ? value : new Date(value);
  }
  // Workaround in order to use the "strictParsing" attribute, which is still not present
  // on the component typescript definition.
  // This should be removed when a new version of the ReactDatePicker types are released.
  const CustomReactDatePicker : any = ReactDatePicker;

  const maskedInputRegexMask = mask && mask.split('').map(char => {
    // This only supports simple date formats, where each character represents a digit.
    if(/\w/g.test(char)) {
      return /\d/;
    }
    return char;
  }) || [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, ':', /\d/, /\d/, ':', /\d/, /\d/];
  return (
    <>
      <CustomReactDatePicker
        required={isRequired}
        className={`form-control${isInvalid ? ' is-invalid' : ''}`}
        placeholderText={`Click to select the ${fieldTitle}`}
        selected={
          dateValue ? new Date(dateValue.getTime() + dateValue.getTimezoneOffset() * 60 * 1000) : null
        }
        disabledKeyboardNavigation
        onChange={(date: Date) => {
          onChange(date ? new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000) : null);
        }}
        onChangeRaw={(event: SyntheticEvent<HTMLInputElement>) => {
          const timestamp: number = Date.parse(`${(event.target as HTMLInputElement).value} GMT-0`);
          if (!Number.isNaN(timestamp)) {
            onChange(new Date(timestamp));
          }
        }}
        strictParsing
        showTimeSelect
        timeIntervals={1}
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        dropdownMode='select'
        dateFormat={mask || 'MM/dd/yyyy HH:mm:ss'}
        timeFormat='HH:mm'
        todayButton={'Today'}
        readOnly={readOnly}
        popperProps={{ positionFixed: true }}
        customInput={
          <MaskedInput mask={maskedInputRegexMask} />
        }
        />
      { isInvalid ? <InputValidationText fieldName={fieldTitle} /> : null }
    </>
  );
}

export default DateInput;
