import React from 'react';
import InputValidationText from './InputValidationText';

const MAX_COORDINATE_VALUE = {
  latitude: 90.0,
  longitude: 180.0
}

export type GeoPoint = {
  latitude: number, // North-south portion of the coordinate, in range [-90, 90]
  longitude: number // East-west portion of the coordinate, in range [-180, 180]
}

// This type is used to avoid the error when the input field in empty.
type GeoPointInputValue = GeoPoint | {
  latitude: string,
  longitude: string
}

type Coordinate = 'latitude' | 'longitude'

type Props = {
  value?: GeoPoint,
  readOnly: boolean,
  isInvalid: boolean,
  fieldTitle: string,
  onChange(value: GeoPoint | null): void,
};

type State = {
  currentValue: GeoPointInputValue
}

class GeoPointInput extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    const { value = {
      latitude: '',
      longitude: ''
    }} = props

    this.state = {
      currentValue: value
    }

    this.handleOnChangeEvent = this.handleOnChangeEvent.bind(this)
  }

  // Validate the coordinate following Parse.GeoPoint type, the value should not equal or exceed the extreme ends of the ranges.
  validateCoordinate(coordinateValue: number, coordinateType: Coordinate): number {
    if (coordinateValue > (MAX_COORDINATE_VALUE[coordinateType] as number)) return MAX_COORDINATE_VALUE[coordinateType];
    if (coordinateValue < -(MAX_COORDINATE_VALUE[coordinateType] as number)) return -MAX_COORDINATE_VALUE[coordinateType];
    return coordinateValue;
  };

  async handleOnChangeEvent(e: any, coordinateType: Coordinate): Promise<void> {
    const { currentValue } = this.state;
    // Update the currentValue with the new value
    currentValue[coordinateType] = e.target.value !== '' ? this.validateCoordinate(Number.parseFloat(e.target.value), coordinateType) : ''
    await this.setState({ currentValue })

    if (currentValue.latitude === '' && currentValue.longitude === '') return this.props.onChange(null);
    return this.props.onChange(currentValue as GeoPoint);
  }

  render() {
    const { isInvalid, fieldTitle, readOnly } = this.props;

    return (
      <>
        <div className="row" style={{ margin: 0 }}>
          <div className="geo-query-form">
            <input
              className="form-control"
              placeholder="Latitude"
              value={this.state.currentValue.latitude}
              type='number'
              readOnly={readOnly}
              min={-90}
              max={90}
              onChange={e => this.handleOnChangeEvent(e, 'latitude')} />
          </div>
          <div className="geo-query-form">
            <input
              className="form-control"
              placeholder="Longitude"
              value={this.state.currentValue.longitude}
              type='number'
              readOnly={readOnly}
              min={-180}
              max={180}
              onChange={e => this.handleOnChangeEvent(e, 'longitude')} />
          </div>
        </div>
        {
          isInvalid && fieldTitle ? <InputValidationText fieldName={fieldTitle} /> : null
        }
      </>
    )
  }
};

export default GeoPointInput;
