import React, { RefObject, MouseEvent } from 'react';

type ObjectsModalProps = {
  title: string,
  onCloseButtonClick(): void
  onExternalClick(): void,
  children: JSX.Element | JSX.Element[],
  className?: string
}

const ObjectsModal = (props: ObjectsModalProps) => {
  const { title, children, onCloseButtonClick, onExternalClick, className } = props;

  const modalReference: RefObject<HTMLDivElement> = React.createRef();
  const handleExternalClick = (e: MouseEvent<HTMLDivElement>) => {
    const { target } = e;
    if (modalReference.current) {
      let node: Node | null = target as HTMLDivElement;
      while (node) {
        if (node === modalReference.current) {
          return;
        }
        node = node.parentNode;
      }
      // If click was not inside the modal reference, closes the modal
      onExternalClick();
    }
  };
  return (
    <>
      <div className="modal-backdrop fade show" />
      <div className={`${className? ` ${className}`: ''} modal position-fixed d-block`} role="dialog" style={{ overflowX: 'hidden', overflowY: 'auto' }} onClick={handleExternalClick}>
        <div ref={modalReference} className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content text-left">
            <div className="modal-header">
              <h5 className="modal-title">{title}</h5>
              <button type="button" className="close" aria-label="Close" onClick={onCloseButtonClick}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            {children}
          </div>
        </div>
      </div>
    </>
  );
};

export default ObjectsModal;
