"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var Event;

(function (Event) {
  Event["Created"] = "CREATED";
  Event["Updated"] = "UPDATED";
  Event["Deleted"] = "DELETED";
})(Event = exports.Event || (exports.Event = {}));

;
var FieldType;

(function (FieldType) {
  FieldType["String"] = "STRING";
  FieldType["Number"] = "NUMBER";
  FieldType["Bool"] = "BOOL";
  FieldType["Date"] = "DATE";
  FieldType["Object"] = "OBJECT";
  FieldType["File"] = "FILE";
  FieldType["GeoPoint"] = "GEOPOINT";
  FieldType["Pointer"] = "POINTER";
  FieldType["Polygon"] = "POLYGON";
  FieldType["Array"] = "ARRAY";
  FieldType["Relation"] = "RELATION";
})(FieldType = exports.FieldType || (exports.FieldType = {}));

var FieldSubType;

(function (FieldSubType) {
  FieldSubType["Password"] = "PASSWORD";
  FieldSubType["Select"] = "SELECT";
})(FieldSubType = exports.FieldSubType || (exports.FieldSubType = {}));
/**
 * These are the classes that should not be handled by the Admin application.
 */


exports.RESERVED_CLASSES = ['User', '_Role', 'Role', '_Session', 'Session', '_Installation', 'Installation'];