import {
  combineReducers,
  applyMiddleware,
  createStore,
  Store
} from 'redux';
import thunk from 'redux-thunk';
import {
  reducer as app,
  subscriber as appSubscriber
} from './App';
import { reducer as logIn } from './components/LogIn';
import {
  reducer as menu,
  subscriber as menuSubscriber
} from './components/Menu';
import {
  reducer as objects,
  subscriber as objectsSubscriber
} from './components/Objects';
import { reducer as objectForm } from './components/ObjectForm';
import { reducer as deleteObjects } from './components/DeleteObjects';
import { reducer as analytics } from './components/AnalyticsProvider';

const store: Store = createStore(
  combineReducers({
    app,
    logIn,
    menu,
    objects,
    objectForm,
    deleteObjects,
    analytics
  }),
  applyMiddleware(thunk)
);

store.subscribe((): void => {
  appSubscriber(store);
  menuSubscriber(store);
  objectsSubscriber(store);
});

export default store;