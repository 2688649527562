import React, { ChangeEvent } from 'react';

type CheckboxProps = {
  id: string,
  name?: string,
  checked: boolean,
  onChange(event: ChangeEvent<HTMLInputElement>): void
}

const Checkbox = (props: CheckboxProps) => {
  const { id, name, checked, onChange } = props;
  return (
    <div className="form-check">
      <input id={id} name={name} className="form-control form-check-input" type="checkbox" checked={checked} onChange={onChange} />
      <label htmlFor={id}></label>
    </div>
  );
};

export default Checkbox;
