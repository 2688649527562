import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import 'bootstrap';
import '@fortawesome/fontawesome-free/js/solid.js';
import '@fortawesome/fontawesome-free/js/fontawesome.js';
import store from './store';
import App from './App';
import * as serviceWorker from './serviceWorker';
import AnalyticsProvider from './components/AnalyticsProvider';
import ErrorBoundary from './ErrorBoundary';
import {
  clearCustomFields,
  clearMenuItems,
  clearObjectClasses
} from './cache';

//b4a-styles
import './b4a-styles/scss/main.scss';

ReactDOM.render(
  <ErrorBoundary>
    <Provider store={store}>
      <AnalyticsProvider>
        <App />
      </AnalyticsProvider>
    </Provider>
  </ErrorBoundary>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register({
  onUpdate: (registration: ServiceWorkerRegistration) => {
    console.log("Cleaning caches...");
    clearCustomFields();
    clearMenuItems();
    clearObjectClasses();
    console.log("Cleaning caches DONE");

    // Gets the service worker that is waiting for the right time to activate,
    // and forces skipping the waiting.
    // We need to do this to make sure the user always uses the latest version
    // of the PWA.
    // Once this skipWaiting message gets processed, the controller should be
    // changed, and the current page, reloaded
    const waitingWorker: ServiceWorker | null = registration.waiting;
    if (waitingWorker) {
      waitingWorker.postMessage('skipWaiting');
    }
  }
});

// Whenever the controller service worker is changed (i.e. any other service
// worker gets registered, installed and activated), reloads the page
let refreshing: boolean;
navigator.serviceWorker.addEventListener('controllerchange',
  function() {
    // The "refreshing" flag is needed to avoid the infinite loop
    // when using the Chrome's update service worker on reload feature
    if (refreshing) return;
    refreshing = true;
    window.location.reload();
  }
);

const loadingSpinner = document.querySelector('#root ~ .loading-spinner');
if (loadingSpinner) {
  loadingSpinner.remove();
}
