import React from 'react';

type InputValidationTextProps = {
  fieldName: string
};

const InputValidationText = (props: InputValidationTextProps) => (
  <div style={{ display: 'block' }} className="invalid-feedback">
    Please enter {props.fieldName}.
  </div>
);

export default InputValidationText;
