import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { LoggedUser } from '../entities';
import LogOut from './LogOut';
import * as api from '../api';
import { ShowNetworkErrorNotification } from '../App'

type State = {
  loggedUser: LoggedUser,
  isFeedbackHidden: string
};

type Events = {
  onComponentDidMount: () => void,
  onComponentWillUnmount: () => void
};

type Props = State & Events;

class View extends PureComponent<Props> {
  componentDidMount(): void {
    this.props.onComponentDidMount();
  }

  componentWillUnmount(): void {
    this.props.onComponentWillUnmount();
  }

  render(): JSX.Element {
    return (
      <ul className="logged-user navbar-nav btn-user btn-light">
        <li className="nav-item dropdown">
          <a href="#" className="nav-link logout-icon" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i className="fa fa-user dropdown-toggle" id="navbarDropdown" />
          </a>
          <LogOut username={this.props.loggedUser.username} isFeedbackHidden={this.props.isFeedbackHidden === 'true'}/>
        </li>
      </ul>
    );
  }
}

type RootState = {
  app: {
    settings: { isFeedbackHidden: string },
    loggedUser?: LoggedUser
  }
};

function mapState(rootState: RootState): State {
  return {
    loggedUser: (rootState.app.loggedUser as LoggedUser),
    isFeedbackHidden: rootState.app.settings.isFeedbackHidden
  };
}

export enum ActionTypes {
  Subscribe = 'LOGGED_USER.SUBSCRIBE',
  Change = 'LOGGED_USER.CHANGE',
  Fail = 'LOGGED_USER.FAIL',
  Unsubscribe = 'LOGGED_USER.UNSUBSCRIBE'
};

type SubscribeAction = {
  type: ActionTypes.Subscribe
};

type ChangeAction = {
  type: ActionTypes.Change,
  payload: {
    username: string
  }
};

type FailAction = {
  type: ActionTypes.Fail,
  payload: {
    errorMessage: string
  }
}

type UnsubscribeAction = {
  type: ActionTypes.Unsubscribe
};

export type Action =
  SubscribeAction |
  ChangeAction |
  FailAction |
  UnsubscribeAction;

type Dispatch = any;

type GetState = () => RootState;

type Thunk = (dispatch: Dispatch, getState: GetState) => Promise<Action>

let cancelAPISubscription: (() => void) | undefined = undefined;

function subscribe(): Thunk {
  return async (dispatch: Dispatch, getState: GetState): Promise<Action> => {
    dispatch({
      type: ActionTypes.Subscribe
    });

    const state: State = mapState(getState());

    let username: string;
    try {
      username = await api.loggedUser(state.loggedUser.sessionToken);
    } catch (e) {
      if (e instanceof api.NetworkError) {
        console.error('Network error when subscribing to Logged User. Trying again in 5 sec...');
        dispatch(ShowNetworkErrorNotification());
        await (new Promise((resolve: () => void): void => {
          setTimeout(() => {
            resolve();
          }, 5000);
        }));
        return dispatch(subscribe());
      }

      return dispatch(fail(e.message));
    }

    if (cancelAPISubscription) {
      cancelAPISubscription();
    }

    cancelAPISubscription = api.loggedUserChanged(
      state.loggedUser.sessionToken,
      (username: string): void => {
        dispatch(change(username));
      },
      (error: Error): void => {
        dispatch(fail(error.message));
      },
      (): void => {
        dispatch(subscribe());
      }
    );

    return dispatch(change(username));
  };
}

function change(username: string): ChangeAction {
  return {
    type: ActionTypes.Change,
    payload: {
      username
    }
  };
}

function fail(errorMessage: string): FailAction {
  return {
    type: ActionTypes.Fail,
    payload: {
      errorMessage
    }
  };
}

function unsubscribe(): UnsubscribeAction {
  if (cancelAPISubscription) {
    cancelAPISubscription();
    cancelAPISubscription = undefined;
  }
  return {
    type: ActionTypes.Unsubscribe
  };
}

function mapEvents(dispatch: Dispatch): Events {
  return {
    onComponentDidMount(): void {
      dispatch(subscribe());
    },
    onComponentWillUnmount(): void {
      dispatch(unsubscribe());
    }
  };
}

export default connect(mapState, mapEvents)(View);
