import React, { PureComponent, FormEvent } from 'react';
import { connect } from 'react-redux';
import Title from './Title';
import { CustomField, ObjectClass, ObjectClassField, FieldType, FieldSubType } from 'core';
import { CustomFields, ObjectEntity, Fields } from '../entities';
import * as api from '../api';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
  Action as LogOutAction,
  ActionTypes as LogOutActionTypes
} from './LogOut';
import "react-datepicker/dist/react-datepicker.css";
import JSONEditor from './JSONEditor';
import { generateSortFieldsFunction } from '../util';
import BooleanInput from './BooleanInput';
import SelectInput from './SelectInput';
import DateInput from './DateInput';
import FileInput from './FileInput';
import withAnalytics, { AnalyticsProps } from '../hoc/withAnalytics';
import { notify, NotificationColorTheme } from './Notification';
import { ShowNetworkErrorNotification } from '../App'
import GeoPointInput from './GeoPointInput';
import PolygonInput from './PolygonInput';
import MultipleSelectInput from './MultipleSelectInput';

type ObjectValidation = {
  [fieldName: string]: {
    isValid: boolean,
    showValidation: boolean
  }
};

type OwnState = {
  objectEntity: ObjectEntity,
  objectValidation: ObjectValidation,
  isSaving: boolean,
  errorMessage: string,
  isLoading: boolean,
  isLoaded: boolean
};

type State = OwnState & {
  sessionToken?: string,
  className: string,
  objectId?: string,
  title: string,
  subtitle?: string,
  objectClasses: ObjectClass[],
  objectClass: ObjectClass,
  customFields: {
    [objectClassFieldName: string]: CustomField
  }
};

type Events = {
  onComponentDidMount: (props: Props) => void,
  onComponentDidUpdate: (prevProps: Props, props: Props) => void,
  onComponentWillUnmount: () => void,
  onInputChange: (event: { name: string, value: any, isRequired: boolean}) => void,
  onFormSubmit: (event: FormEvent) => void
};

type Props = State & Events & AnalyticsProps & RouteComponentProps;

const READ_ONLY_FIELDS: string[] = [ 'id', 'objectId', 'createdAt', 'updatedAt' ];

function getFields(objectClass: ObjectClass, customFields: { [objectClassFieldName: string]: CustomField }): Fields {
  const fields: Fields = {};
  for (const objectClassFieldName in customFields) {
    if (objectClass.fields.filter(({ name }) => name === objectClassFieldName).length > 0) {
      fields[objectClassFieldName] = {
        customField: customFields[objectClassFieldName]
      };
    }
  }
  objectClass.fields.forEach((objectClassField: ObjectClassField): void => {
    fields[objectClassField.name] = Object.assign(
      {},
      fields[objectClassField.name],
      {
        objectClassField
      }
    );
  });
  return fields;
}

class View extends PureComponent<Props> {
  componentDidMount(): void {
    this.props.onComponentDidMount(this.props);
  }

  componentDidUpdate(prevProps: Props) {
    this.props.onComponentDidUpdate(prevProps, this.props);
  }

  componentWillUnmount(): void {
    this.props.onComponentWillUnmount();
  }

  evaluateExpression(expression: string, defaultValue: any): any {
    try {
      return Function('objectClasses', 'objectEntity', `return ${expression}`)(this.props.objectClasses, this.props.objectEntity);
    } catch (e) {
      console.log(`Error while evaluating expression: ${e}`);
      return defaultValue;
    }
  }

  renderInput(dataType: string, subType: FieldSubType | undefined, objectClassFieldName: string, fieldTitle: string, isRequired: boolean, options: string | undefined): JSX.Element {
    const isReadOnly = READ_ONLY_FIELDS.indexOf(objectClassFieldName) !== -1;
    const validation = this.props.objectValidation && this.props.objectValidation[objectClassFieldName];
    const isInvalid = isRequired && validation && validation.showValidation && !validation.isValid;
    const value = this.props.objectEntity[objectClassFieldName];
    if (dataType === FieldType.Date) {
      const customField: CustomField = this.props.customFields && this.props.customFields[objectClassFieldName];
      const inputMask = customField && customField.inputMask;
      return <DateInput
        isRequired={isRequired}
        fieldTitle={fieldTitle}
        isInvalid={isInvalid}
        value={value}
        onChange={(date: Date) => {
          this.props.onInputChange({
            name: objectClassFieldName,
            value: date,
            isRequired
          });
        }}
        readOnly={isReadOnly}
        mask={inputMask} />
    } else if (dataType === FieldType.Object || dataType == FieldType.Array) {
      return <JSONEditor
        value={value ? value : dataType === FieldType.Object ? {} : []}
        onChange={value => {
          this.props.onInputChange({
            name: objectClassFieldName,
            value,
            isRequired
          });
        }}
        readOnly={isReadOnly}
        isInvalid={isInvalid}
        fieldTitle={fieldTitle} />
    } else if (dataType === FieldType.Bool) {
      const fieldValue = value === null || value === undefined
        ? undefined
        : value === true;
      return (
        <BooleanInput
          value={fieldValue}
          name={objectClassFieldName}
          fieldTitle={fieldTitle}
          onChange={(value: any) => {
            // Value can be true, false or undefined
            this.props.onInputChange({
              name: objectClassFieldName,
              value,
              isRequired
          })}}
          isInvalid={isInvalid}
          tristate={true}
          />
      );
    } else if (dataType === FieldType.File) {
      return (
        <FileInput
          name={objectClassFieldName}
          fieldTitle={fieldTitle}
          value={value}
          isInvalid={isInvalid}
          onChange={(value: File | null) => {
            this.props.onInputChange({
              name: objectClassFieldName,
              value,
              isRequired
          })}}
        />
      );
    } else if (dataType === FieldType.Relation) {
      const { values = [], add: toBeAdded = [], remove: toBeRemoved = [] } = value || {};
      return (
        <MultipleSelectInput fieldTitle={fieldTitle} value={values} placeholder='Add the ID of the item you want to relate with' isInvalid={isInvalid} onAdd={(addedValue) => {
          if (toBeRemoved.includes(addedValue)) {
            toBeRemoved.splice(toBeRemoved.indexOf(addedValue), 1);
          } else if (!toBeAdded.includes(addedValue)) {
            toBeAdded.push(addedValue);
          }
          values.unshift(addedValue);

          this.props.onInputChange({
            name: objectClassFieldName,
            value: { values, add: toBeAdded, remove: toBeRemoved },
            isRequired
          });
        }}
        onRemove={(removedValue) => {
          if (toBeAdded.includes(removedValue)) {
            toBeAdded.splice(toBeAdded.indexOf(removedValue), 1);
          } else if (!toBeRemoved.includes(removedValue)) {
            toBeRemoved.push(removedValue);
          }
          values.splice(values.indexOf(removedValue), 1);

          this.props.onInputChange({
            name: objectClassFieldName,
            value: { values, add: toBeAdded, remove: toBeRemoved },
            isRequired
          });
        }} />
      );
    } else if (subType === FieldSubType.Select) {
      const optionsArray: string[] = [];
      if (options) {
        const optionsEvalArray: string[] = this.evaluateExpression(options, []);
        let isValidArray = optionsEvalArray instanceof Array;
        if (isValidArray) {
          optionsEvalArray.forEach((item: string): void => {
            isValidArray = isValidArray && typeof item === 'string';
          });
        }
        if (isValidArray) {
          optionsEvalArray.forEach((option: string): void => {
            if (option && option.length && !optionsArray.includes(option)) {
              optionsArray.push(option);
            }
          });
        } else {
          console.log('options should evaluate to an array of strings.')
        }
      }
      return (<SelectInput
        fieldTitle={fieldTitle}
        value={value === null || value === undefined ? '' : value}
        onChange={(option: { value: string | number, label: any }) => {
          this.props.onInputChange({
            name: objectClassFieldName,
            value: option.value,
            isRequired
        })}}
        options={optionsArray}
        isInvalid={isInvalid}
        nullOption={true} />
      );
    } else if (dataType === FieldType.GeoPoint) {
      return <GeoPointInput
        readOnly={isReadOnly}
        isInvalid={isInvalid}
        fieldTitle={fieldTitle}
        value={value}
        onChange={value => {
          this.props.onInputChange({
            name: objectClassFieldName,
            value: value,
            isRequired
        })}} />
    } else if (dataType === FieldType.Polygon) {
      return <PolygonInput
        value={value && value.coordinates}
        onChange={value => {
          this.props.onInputChange({
            name: objectClassFieldName,
            value: (value ? value : (value === '' ? '' : undefined)),
            isRequired
          });
        }}
        readOnly={isReadOnly}
        isInvalid={isInvalid}
        fieldTitle={fieldTitle} />
    }

    let inputType: string;
    switch(dataType) {
      case FieldType.Number:
        inputType = "number";
        break;
      default:
        inputType = "text";
    }

    if (subType && subType === FieldSubType.Password) {
      inputType = "password";
    }

    let inputProps = {
      type: inputType,
      id: objectClassFieldName,
      name: objectClassFieldName,
      required: isRequired,
      placeholder: `Enter ${fieldTitle}`,
      className: `form-control${isInvalid ? ' is-invalid' : ''}`,
      value: value === null || value === undefined ? '' : value,
      onChange: (e: any) => {
        const { name, value } = e.target;
        this.props.onInputChange({
          name,
          value: dataType === FieldType.Number && value !== undefined && value !== null && value !== '' ? Number.parseFloat(value) : value,
          isRequired
      })}
    };
    if (isReadOnly) {
      inputProps = Object.assign(inputProps, { readonly: 'readonly' });
    }
    return (
      <>
        <input {...inputProps} />
        <div className="invalid-feedback">
          Please enter {fieldTitle}.
        </div>
      </>
    );
  }

  renderErrorMessage(): JSX.Element {
    if (!this.props.errorMessage) {
      return <></>;
    }
    return (
      <div className="row row mt-3 mb-1">
        <div className="col-12">
          <div className="alert alert-danger" role="alert">
            {this.props.errorMessage}
          </div>
        </div>
      </div>
    );
  }

  handleFormSubmit(event: FormEvent): void {
    const { objectId, className, trackEvent } = this.props;
    if (objectId) {
      trackEvent('Object Edited', { class: className });
    } else {
      trackEvent('Object Created', { class: className });
    }
    this.props.onFormSubmit(event);
  }

  render(): JSX.Element {
    const fields: Fields = getFields(this.props.objectClass, this.props.customFields);
    return (
      <section className="content-container">
        <Title title={this.props.title} />
        <div className="form-container">
          <div className="class-table">
            <div className="admin-form-groups-container">
              <h5 className="mt-3 mb-3">{this.props.subtitle}</h5>
              <hr />
              <form
                noValidate
                className="mt-3 needs-validation"
                onSubmit={this.handleFormSubmit.bind(this)}>

                  <fieldset className='form-body'>
                    {this.props.objectId === undefined || this.props.isLoaded ?
                      (Object.keys(fields)
                        .filter((objectClassFieldName: string): boolean => {
                          let isFormHidden: boolean = false;
                          if (fields[objectClassFieldName].customField && (fields[objectClassFieldName].customField as CustomField).isFormHidden) {
                            const isFormHiddenEval: boolean = this.evaluateExpression((fields[objectClassFieldName].customField as CustomField).isFormHidden as string, false);
                            if (typeof isFormHiddenEval === 'boolean') {
                              isFormHidden = isFormHiddenEval;
                            } else {
                              console.log('isFormHidden should evaluate to a boolean');
                            }
                          }
                          return objectClassFieldName !== 'id' &&
                            READ_ONLY_FIELDS.indexOf(objectClassFieldName) === -1 &&
                            !isFormHidden;
                        })
                        .sort(generateSortFieldsFunction(fields))
                        .map((objectClassFieldName: string, index: number): JSX.Element => {
                          const fieldTitle: string = fields[objectClassFieldName].customField ? (fields[objectClassFieldName].customField as CustomField).title : (fields[objectClassFieldName].objectClassField as ObjectClassField).name;
                          const dataType: string = fields[objectClassFieldName].objectClassField ? (fields[objectClassFieldName].objectClassField as ObjectClassField).type : '';
                          const subType: FieldSubType | undefined = fields[objectClassFieldName].customField ? (fields[objectClassFieldName].customField as CustomField).subType : undefined;
                          const options: string | undefined = fields[objectClassFieldName].customField ? (fields[objectClassFieldName].customField as CustomField).options : undefined;
                          const separator = index < Object.keys(fields).length - 1
                            ? <hr />
                            : null;
                          return (
                            <React.Fragment key={objectClassFieldName}>
                              <div className="form-group row">
                                <label htmlFor={objectClassFieldName} className={`col-sm-3 col-form-label ${dataType === FieldType.File && 'files-label'}`}>{fieldTitle}{dataType === FieldType.Date ? ' (UTC)' : ''}{fields[objectClassFieldName].customField && (fields[objectClassFieldName].customField as CustomField).isRequired ? '*' : ''}</label>
                                <div className="col-sm-9">
                                  {this.renderInput(dataType, subType, objectClassFieldName, fieldTitle, fields[objectClassFieldName].customField !== undefined && (fields[objectClassFieldName].customField as CustomField).isRequired === true, options)}
                                </div>
                              </div>
                              {separator}
                            </React.Fragment>
                          );
                        })
                    )
                  :
                    (<div className="alert alert-secondary">
                      <i className="fa fa-spinner fa-spin mr-2"></i>
                      Loading...
                    </div>)
                  }

                </fieldset>

                <div className="row mt-3 mb-1">
                    <div className="col-12">
                      <button
                        type="submit"
                        className="btn btn-success float-right"
                        disabled={this.props.isSaving || (this.props.objectId !== undefined && !this.props.isLoaded)}>
                        <i className={this.props.isSaving ? 'fa fa-spinner fa-spin mr-1' : 'zmdi zmdi-check zmdi-hc-lg mr-1'}></i>
                        {this.props.isSaving ? 'Saving' : 'Save'}
                      </button>
                      {!this.props.isSaving &&
                        <a href='#' onClick={this.props.history.goBack} className="btn btn-primary float-right mr-2">
                          <i className="zmdi zmdi-close zmdi-hc-lg mr-1"></i>
                          Cancel
                        </a>
                      }
                    </div>
                  </div>
                  {this.renderErrorMessage()}
              </form>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

type OwnProps = {
  className: string,
  title: string,
  objectId?: string,
  redirect: (to: string) => void
};

type RootState = {
  app: {
    loggedUser?: { sessionToken: string },
    objectClasses: ObjectClass[],
    customFields: CustomFields
  },
  objectForm: OwnState
};

function mapState(rootState: RootState, ownProps: OwnProps): State {
  return {
    sessionToken: rootState.app.loggedUser ? rootState.app.loggedUser.sessionToken : undefined,
    className: ownProps.className,
    objectId: ownProps.objectId,
    title: ownProps.title,
    objectClasses: rootState.app.objectClasses,
    objectClass: rootState.app.objectClasses.find((objectClass: ObjectClass): boolean => { return objectClass.name === ownProps.className; }) || { name: ownProps.className, fields: [], referencedBy: [] },
    customFields: rootState.app.customFields.byObjectClassName[ownProps.className] || {},
    objectEntity: rootState.objectForm.objectEntity,
    objectValidation: rootState.objectForm.objectValidation,
    isSaving: rootState.objectForm.isSaving,
    errorMessage: rootState.objectForm.errorMessage,
    isLoading: rootState.objectForm.isLoading,
    isLoaded: rootState.objectForm.isLoaded
  };
}

export enum ActionTypes {
  ChangeField = 'OBJECT_FORM.CHANGE_FIELD',
  Save = 'OBJECT_FORM.SAVE',
  Confirm = 'OBJECT_FORM.CONFIRM',
  Fail = 'OBJECT_FORM.FAIL',
  Reset = 'OBJECT_FORM.RESET',
  Load = 'OBJECT_FORM.LOAD',
  ConfirmLoad = 'OBJECT_FORM.CONFIRM_LOAD',
  FailLoad = 'OBJECT_FORM.FAIL_LOAD',
};

type ChangeFieldAction = {
  type: ActionTypes.ChangeField,
  payload: {
    name: string,
    value: string,
    isValid: boolean,
    showValidation: boolean
  }
};

type SaveAction = {
  type: ActionTypes.Save,
  payload: {
    className: string,
    objectId?: string
  }
}

type ConfirmAction = {
  type: ActionTypes.Confirm,
  payload: {
    className: string,
    objectEntity: ObjectEntity
  }
}

type FailAction = {
  type: ActionTypes.Fail,
  payload: {
    errorMessage: string
  }
}

type ResetAction = {
  type: ActionTypes.Reset
}

type LoadAction = {
  type: ActionTypes.Load,
  payload: {
    className: string,
    objectId: string
  }
}

type ConfirmLoadAction = {
  type: ActionTypes.ConfirmLoad,
  payload: {
    className: string,
    objectEntity: ObjectEntity
  }
}

type FailLoadAction = {
  type: ActionTypes.FailLoad,
  payload: {
    errorMessage: string
  }
}

export type Action =
  ChangeFieldAction |
  SaveAction |
  ConfirmAction |
  FailAction |
  ResetAction |
  LoadAction |
  ConfirmLoadAction |
  FailLoadAction;

type Dispatch = any;

type GetState = () => RootState;

type Thunk = (dispatch: Dispatch, getState: GetState) => Promise<Action>;

function changeField(name: string, value: any, isRequired: boolean): ChangeFieldAction {
  // Needed when the value is composed, and thus we need to define which attribute from this
  // composed content is going to be evaluated.
  const evaluatedValue = value && value.values ? value.values : value;
  return {
    type: ActionTypes.ChangeField,
    payload: {
      name,
      value,
      isValid: !isRequired || (evaluatedValue !== null && evaluatedValue !== undefined && evaluatedValue !== '' && (!Array.isArray(evaluatedValue) || evaluatedValue.length > 0)),
      showValidation: true
    }
  };
}

function save(ownProps: OwnProps): Thunk {
  return async (dispatch: Dispatch, getState: GetState): Promise<Action> => {
    dispatch({
      type: ActionTypes.Save
    });

    const { className, redirect, objectId, title } = ownProps
    const state: State = mapState(getState(), { className, title, objectId, redirect });

    if (!state.sessionToken) {
      return dispatch(fail('User is not logged in.'));
    }

    let isValid = true;

    const fields: Fields = getFields(state.objectClass, state.customFields);
    Object.keys(fields).forEach((fieldName: string): void => {
      const changeFieldAction: ChangeFieldAction = changeField(fieldName, state.objectEntity[fieldName], fields[fieldName].customField !== undefined && (fields[fieldName].customField as CustomField).isRequired);
      dispatch(changeFieldAction);
      isValid = isValid && changeFieldAction.payload.isValid;
    });

    if (!isValid) {
      return dispatch(fail('Please fill all required* fields.'));
    }

    let objectEntity: ObjectEntity;
    let isEditOperation: boolean;
    try {
      if (state.objectId) {
        isEditOperation = true;
        objectEntity = await api.saveObject(state.sessionToken, className, state.objectId, state.objectEntity, fields);
      } else {
        isEditOperation = false;
        objectEntity = await api.addObject(state.sessionToken, className, state.objectEntity, fields);
      }
    } catch (e) {
      return dispatch(fail(e.message))
    }

    redirect(`/objects/${className}`);

    const isUser: boolean = className === '_User'
    isEditOperation ?
      notify('fa-pen', `${isUser ? 'User' : "Object"} edited with success`) :
      notify('fa-plus', `${isUser ? 'User' : "Object"} created with success`, undefined, NotificationColorTheme.SUCCESS);

    return dispatch(confirm(className, objectEntity));
  };
}

function confirm(className: string, objectEntity: ObjectEntity): ConfirmAction {
  return {
    type: ActionTypes.Confirm,
    payload: {
      className,
      objectEntity
    }
  };
}

function fail(errorMessage: string): FailAction {
  return {
    type: ActionTypes.Fail,
    payload: {
      errorMessage
    }
  };
}

function reset(): ResetAction {
  return {
    type: ActionTypes.Reset
  };
}

function load(className: string, objectId: string): Thunk {
  return async (dispatch: Dispatch, getState: GetState): Promise<Action> => {
    dispatch({
      type: ActionTypes.Load,
      payload: {
        className,
        objectId
      }
    });

    const state: State = mapState(getState(), { className, title: className, objectId, redirect: (): void => {} });
    const fields: Fields = getFields(state.objectClass, state.customFields);

    let objectEntity: ObjectEntity = {};

    if (!state.sessionToken) {
      return dispatch(failLoad('Invalid session token.'));
    }

    try {
      objectEntity = await api.getObject(state.sessionToken, className, fields, objectId);
    } catch (e) {
      if (e instanceof api.NetworkError) {
        dispatch(failLoad(`Network error when loading ${className} Object. Trying again in 5 sec...`));
        dispatch(ShowNetworkErrorNotification());
        await (new Promise((resolve: () => void): void => {
          setTimeout(() => {
            resolve();
          }, 5000);
        }));
        return dispatch(load(className, objectId));
      }

      return dispatch(failLoad(e.message));
    }

    return dispatch(confirmLoad(className, objectEntity));
  };
}

function confirmLoad(className: string, objectEntity: ObjectEntity): ConfirmLoadAction {
  return {
    type: ActionTypes.ConfirmLoad,
    payload: {
      className,
      objectEntity
    }
  };
}

function failLoad(errorMessage: string): FailLoadAction {
  return {
    type: ActionTypes.FailLoad,
    payload: {
      errorMessage
    }
  };
}

function mapEvents(dispatch: Dispatch, ownProps: OwnProps): Events {
  function setDefaultValues(props: Props): void {
    const fields: Fields = getFields(props.objectClass, props.customFields);
    Object.keys(fields).forEach((fieldName: string): void => {
      if (fields[fieldName].customField && (fields[fieldName].customField as CustomField).defaultValue !== null && (fields[fieldName].customField as CustomField).defaultValue !== undefined && (fields[fieldName].customField as CustomField).defaultValue !== '' && (props.objectEntity[fieldName] === null || props.objectEntity[fieldName] === undefined || props.objectEntity[fieldName] === '')) {
        let defaultValue: any = (fields[fieldName].customField as CustomField).defaultValue;
        if (fields[fieldName].objectClassField) {
          switch ((fields[fieldName].objectClassField as ObjectClassField).type) {
            case FieldType.Number:
              defaultValue = Number.parseFloat(defaultValue);
              break;

            case FieldType.Bool:
              defaultValue = defaultValue === 'true';
          }
        }

        if (fields[fieldName].objectClassField && (fields[fieldName].objectClassField as ObjectClassField).type === FieldType.Number) {
          defaultValue = Number.parseFloat(defaultValue);
        }
        dispatch(changeField(fieldName, defaultValue, (fields[fieldName].customField as CustomField).isRequired === true));
      }
    });
  }

  return {
    onComponentDidMount(props: Props): void {
      dispatch(reset());
      if (ownProps.objectId) {
        dispatch(load(ownProps.className, ownProps.objectId));
      } else {
        setDefaultValues(props);
      }
    },
    onComponentDidUpdate(prevProps: Props, props: Props): void {
      if (prevProps.className !== ownProps.className || prevProps.objectId !== ownProps.objectId ) {
        dispatch(reset());
        if (ownProps.objectId) {
          dispatch(load(ownProps.className, ownProps.objectId));
        } else {
          setDefaultValues(props);
        }
      }
    },
    onComponentWillUnmount(): void {
      dispatch(reset());
    },
    onInputChange: (event: { name: string, value: any, isRequired: boolean }): void => {
      dispatch(changeField(event.name, event.value, event.isRequired));
    },
    onFormSubmit: (event: FormEvent): void => {
      event.preventDefault();

      dispatch(save(ownProps));
    }
  };
}

export default withRouter(withAnalytics(connect(mapState, mapEvents)(View)));

const INITIAL_STATE: OwnState = {
  objectEntity: {},
  objectValidation: {},
  isSaving: false,
  errorMessage: '',
  isLoading: false,
  isLoaded: false
};

export function reducer(state: OwnState = INITIAL_STATE, action: Action | LogOutAction): OwnState {
  switch (action.type) {
    case ActionTypes.ChangeField:
      return Object.assign(
        {},
        state,
        {
          objectEntity: Object.assign(
            {},
            state.objectEntity,
            {
              [action.payload.name]: action.payload.value
            }
          ),
          objectValidation: Object.assign(
            {},
            state.objectValidation,
            {
              [action.payload.name]: {
                isValid: action.payload.isValid,
                showValidation: action.payload.showValidation
              }
            }
          )
        }
      );

    case ActionTypes.Save:
      return Object.assign(
        {},
        state,
        {
          isSaving: true,
          errorMessage: ''
        }
      );

    case ActionTypes.Confirm:
      return INITIAL_STATE;

    case ActionTypes.Fail:
      return Object.assign(
        {},
        state,
        {
          isSaving: false,
          errorMessage: action.payload.errorMessage
        }
      );

    case ActionTypes.Reset:
      return INITIAL_STATE;

    case ActionTypes.Load:
      return Object.assign(
        {},
        state,
        {
          isLoading: true
        }
      );

    case ActionTypes.ConfirmLoad:
      return Object.assign(
        {},
        state,
        {
          objectEntity: action.payload.objectEntity,
          isLoading: false,
          isLoaded: true,
          errorMessage: ''
        }
      );

    case ActionTypes.FailLoad:
      return Object.assign(
        {},
        state,
        {
          errorMessage: action.payload.errorMessage,
          isLoading: false
        }
      );

    case LogOutActionTypes.Confirm:
    case LogOutActionTypes.Fail:
      return INITIAL_STATE;
  }

  return state;
};
