import React, { CSSProperties } from 'react';
import InputValidationText from './InputValidationText';
import Select from 'react-select';
import { ValueType } from 'react-select/lib/types';
import { Fields } from '../entities';
import { StylesConfig } from 'react-select/lib/styles';
import Icon, { IconSize } from './Icon';

type SelectInputProps = {
  fieldTitle?: string,
  options: Array<any>,
  value?: any,
  onChange: Function,
  isInvalid?: boolean,
  labels?: { [value: string]: string } | Function,
  placeholder?: string,
  className?: string,
  controlShouldRenderValue?: boolean,
  nullOption?: boolean,
  fields?: Fields,
  renderAsButton?: boolean
};

const getFieldLabel = (labels: { [value: string]: string } | Function | undefined, option: any, fields: Fields | undefined) => {
  if (labels) {
    if (typeof labels === 'function') {
      return labels(option, fields);
    }
    return labels[option as string];
  }
  return option.toString();
};

const SelectInput = (props: SelectInputProps) => {
  const { options, value, onChange, isInvalid, labels, placeholder = 'Select...', className, controlShouldRenderValue = true, fields, nullOption: addNullOption = false, fieldTitle: fieldName, renderAsButton = false } = props;

  const selectOptions = options.map(option => {
    return {
      value: option,
      label: getFieldLabel(labels, option, fields)
    }
  });
  const nullOption = {
    value: null,
    label: ''
  };
  if (addNullOption) {
    selectOptions.unshift(nullOption);
  }

  const selectCustomStyle : StylesConfig = {
    option: (styles: CSSProperties): CSSProperties => ({
      ...styles,
      minHeight: '40'
    }),
    menuList: (styles: CSSProperties): CSSProperties => ({
      ...styles,
      wordBreak: 'keep-all'
    })
  };

  // If we are rendering this select as a button, we need to
  // customize the placeholder and control (main component container)
  // style.
  if (renderAsButton) {
    selectCustomStyle.placeholder = (): CSSProperties => ({
      color: 'var(--b4a-brand-color)',
      fontWeight: 'bold'
    });
    selectCustomStyle.control = () => ({
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      border: 'unset',
      cursor: 'pointer'
    });
  }

  return (
    <>
      <Select
        className={className}
        styles={selectCustomStyle}
        components={renderAsButton ? {
          IndicatorsContainer: () => <Icon className='select-input-indicator-icon' icon='zmdi-caret-down' /> // Replaces the IndicatorsContainer with an Icon
        } : undefined}
        placeholder={placeholder}
        menuPosition='fixed'
        options={selectOptions}
        value={value ? { value, label: getFieldLabel(labels, value, fields) } : undefined}
        onChange={(val: ValueType<{ value: any, label: string }>): void => onChange(val || nullOption)}
        controlShouldRenderValue={controlShouldRenderValue}
        isSearchable={!renderAsButton}
        isClearable={addNullOption && !renderAsButton}
        escapeClearsValue={true}
        backspaceRemovesValue={true}
        isOptionSelected={renderAsButton && ((option: { value: any; label: string; }, options: { value: any; label: string; }[]) => false) || undefined}
        theme={theme => ({
          ...theme,
          colors: {
          ...theme.colors,
            primary75: 'var(--b4a-brand-color, #208aec)',
            primary50: 'var(--b4a-brand-color, #208aec)',
            primary25: 'var(--b4a-brand-color, #208aec)',
            primary: 'var(--b4a-brand-color, #208aec)'
          }
        })} />
      {
        isInvalid && fieldName ? <InputValidationText fieldName={fieldName} /> : null
      }
    </>
  );
};

export default SelectInput;
