export function getViewport() {
  if (!('innerWidth' in window)) {
    const element = document.documentElement || document.body;
    return {
      width: element.clientWidth,
      height: element.clientHeight
    };
  }
  return {
    width: window.innerWidth,
    height: window.innerHeight
  }
};

export function isSmallDevice() {
  return getViewport().width < 576;
}
